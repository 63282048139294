import { GetterTree } from "vuex"
import { State } from "./state"

import { RootState } from "@/store";

import { Status } from "@/models/Personal/PersonalModels"
import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels"


export type Getters = {
  postfachPosteingang(state: State): Array<PostfachNachricht>,
  postfachGesendet(state: State): Array<PostfachNachricht>,
  postfachLastUpdate(state: State): Date,
  postfachUpdateInterval(state: State): number
}

export const getters: GetterTree<State, RootState> & Getters = {
  postfachPosteingang(state) {
    return state.postfachPosteingang;
  },
  postfachGesendet(state) {
    return state.postfachGesendet;
  },

  postfachLastUpdate(state) {
    return state.postfachLastUpdate;
  },
  postfachUpdateInterval(state) {
    return state.postfachUpdateInterval;
  },
}