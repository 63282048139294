import { MutationTree } from "vuex"
import { State } from "./state"
import { Status } from "@/models/Personal/PersonalModels"
import { ArchivAuth, Folder, SetFolderDocs, Dokumentart, ArchivComponente, FolderDocsSearchParams } from "@/models/Archiv/ArchivModels"

export enum MutationTypes {
  ArchivLoginSuccess = "ARCHIVLOGINSUCCESS",
  ArchivRefreshLoginSuccess = "ARCHIVREFRESHLOGINSUCCESS",
  ClearArchivState = "CLEARARCHIVSTATE",
  GetMitarbeiterFolderSuccess = "GETMITARBEITERFOLDERSUCCESS",
  GetFolderDocsSuccess = "GETFOLDERDOCSSUCCESS",
  AddFolderDocs = "ADDFOLDERDOCS",
  DokumentartenSuccess = "DOKUMENTARTENSUCCESS",
  ComponentenSuccess = "COMPONENTENSUCCESS",
  ArchivClearComponentenCache = "ARCHIVCLEARECOMPONENTENCACHE",
  AddOrUpdateFolderData = "ADDORUPDATEFOLDERDATA"
}

export type Mutations = {
  [MutationTypes.ArchivLoginSuccess](state: State, archivUser: ArchivAuth): void
  [MutationTypes.ArchivRefreshLoginSuccess](state: State, archivUser: ArchivAuth): void
  [MutationTypes.ClearArchivState](state: State): void
  [MutationTypes.GetMitarbeiterFolderSuccess](state: State, folder: Array<Folder>): void
  [MutationTypes.GetFolderDocsSuccess](state: State, data: SetFolderDocs): void
  [MutationTypes.AddFolderDocs](state: State, data: SetFolderDocs): void
  [MutationTypes.DokumentartenSuccess](state: State, data: Array<Dokumentart>): void
  [MutationTypes.ComponentenSuccess](state: State, data: ArchivComponente): void
  [MutationTypes.ArchivClearComponentenCache](state: State): void
  [MutationTypes.AddOrUpdateFolderData](state: State, data: FolderDocsSearchParams): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ArchivLoginSuccess](state, archivUser) {
    state.archivUser = archivUser;
  },

  [MutationTypes.ArchivRefreshLoginSuccess](state, archivUser) {
    state.archivUser = archivUser;
  },

  [MutationTypes.GetMitarbeiterFolderSuccess](state, folder) {
    state.folderLastUpdate = new Date();
    state.folder = folder;
  },

  [MutationTypes.GetFolderDocsSuccess](state, data) {
    const folderIndex = state.folder.findIndex(f => f.id == data.folderid);
    state.folder[folderIndex].dokumente = data.dokumente;

    sessionStorage.setItem('folder', JSON.stringify(state.folder));
  },

  [MutationTypes.AddFolderDocs](state, data) {
    const folderIndex = state.folder.findIndex(f => f.id == data.folderid);

    for (var doc of data.dokumente) {
      const docIndex = state.folder[folderIndex].dokumente.findIndex(d => d.id == doc.id)
      if (docIndex == -1) {
        state.folder[folderIndex].dokumente.push(doc);
      }
      else {
        state.folder[folderIndex].dokumente.splice(docIndex, 1, doc)
      }
    }

    sessionStorage.setItem('folder', JSON.stringify(state.folder));
  },

  [MutationTypes.DokumentartenSuccess](state, data) {
    state.dokumentartenLastUpdate = new Date();
    state.dokumentarten = data;
  },

  [MutationTypes.ComponentenSuccess](state, data) {
    state.componente = data;
    // for (const dokumentart of state.dokumentarten) {
    //   for (const dokument of dokumentart.dokumente) {
    //     if (dokument.docid == data[0].docid) {
    //       dokument.componenten = data;
    //     }
    //   }
    // }
  },

  [MutationTypes.ArchivClearComponentenCache](state) {
    state.componente = new ArchivComponente();
  },
  
  [MutationTypes.ClearArchivState](state) {
    state.archivUser = new ArchivAuth();
    state.dokumentarten = new Array<Dokumentart>();
    state.componente = new ArchivComponente();
    state.folder = new Array<Folder>();
    state.folderLastUpdate = new Date(1);
    state.dokumentartenLastUpdate = new Date(1);
  },

  [MutationTypes.AddOrUpdateFolderData](state, data) {
    const folderIndex = state.folderData.findIndex((f: FolderDocsSearchParams) => f.folderid == data.folderid)
    if (folderIndex != -1) {
      state.folderData.splice(folderIndex, 1, data)
    }
    else {
      state.folderData.push(data)
    }

    sessionStorage.setItem('folderData', JSON.stringify(state.folderData));
  }
}