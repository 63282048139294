<template>
    <!-- Name und Adresse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" data-bs-toggle="modal" data-bs-target="#nachrichtAnHRModal">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Nachricht an die Personalabteilung</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <!-- Name und Adresse Edit Modal -->
  <div
    class="modal fade"
    id="nachrichtAnHRModal"
    tabindex="-1"
    aria-labelledby="nachrichtAnHRModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="NachrichtAnHRModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="nachrichtAnHRModalLabel">
            Nachricht an die Personalabteilung
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" v-if="loading">
          <LoadingSpinner />
        </div>

        <div class="modal-body" v-if="!loading">
          <div class="mb-2">
            <label class="form-label">Betreff</label>
            <input
              type="text"
              class="form-control"
              v-model="betreff"
              />
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">Nachricht an die Personalabteilung</label>
            <textarea class="form-control" id="message" rows="4" v-model="message"></textarea>
          </div>

          <hr>

          <p>
            <strong>E-Mail Adresse für die Bestätigung der Änderungen und ggf. Rückfragen der Personalabteilung.</strong>
          </p>

          <div class="mb-3">
            <label class="form-label">E-Mail Adresse für Bestätigung</label>
            <input
              type="text"
              class="form-control"
              v-model="responseEmailAdresse"
            />
          </div>

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            <pre>{{errorMessage}}</pre>
          </div>

        </div>  

        <div class="modal-footer" v-if="!loading">

          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal()"
            >
            Abbrechen
          </button>
          
          <button class="btn btn-primary" @click="saveChanges()">Senden</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as VorgangActionTypes } from "@/store/modules/Vorgang/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { NachrichtAnHR } from "@/models/Vorgange/VorgangeModels";

export default defineComponent ({
  name: "NachrichtAnHR",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.vorgangLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => store.getters.personaldaten);

    const state = reactive({
      betreff: "",
      message: "",
      responseEmailAdresse: personaldaten.value.email,
      postfachartid: 1
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.betreff = "",
      state.message = "",
      state.responseEmailAdresse = personaldaten.value.email,
      state.postfachartid = 1
      errorMessage.value = "";
    }

    const NachrichtAnHRModalRef = ref();
    const closeModal = () => {
      resetForm();
      Modal.getInstance(NachrichtAnHRModalRef.value)?.hide();
    }
    const showModal = () => {
    if (NachrichtAnHRModalRef.value) {
      new Modal(NachrichtAnHRModalRef.value).show();
    }
  }

    return {
      store,
      loading,
      status,
      personaldaten,
      ...toRefs(state),
      errorMessage,
      NachrichtAnHRModalRef,
      closeModal,
      showModal
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      const data: NachrichtAnHR = {
        betreff: this.betreff,
        message: this.message,
        responseEmailAdresse: this.responseEmailAdresse,
        postfachartid: 1
      }


      this.store.dispatch(VorgangActionTypes.SendNachrichtAnHR, data)
        .then(() => {
          
          if (this.status.vorgangErrorMsg != "") {
            this.errorMessage = this.status.vorgangErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    }
  }

})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>