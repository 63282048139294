<template>
  <div v-if="isVisible" class="confirm-notification-backdrop">
    <div class="card confirm-notification">
      <div class="card-header d-flex justify-content-between align-items-center" :class="notificationStyle">
        <strong>{{ notificationTitel }}</strong>

        <button type="button" class="btn-close" @click="closeNotification()"></button>
      </div>

      <div class="card-body">
        <pre class="confirm-notification-message">{{ notificationMessage }}</pre>
      </div>

      <div class="card-footer d-flex justify-content-end">
        <button type="button" class="btn btn-secondary" @click="closeNotification()">Ok</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  const isVisible = ref(false);

  const notificationStyle = ref('bg-danger text-white');
  const notificationTitel = ref('');
  const notificationMessage = ref('');

  function showNotification(message: string, titel: string = 'Mitteilung', style = 'bg-danger text-white') {
    notificationStyle.value = style;
    notificationTitel.value = titel;
    notificationMessage.value = message;

    isVisible.value = true;
  }

  function closeNotification() {
    isVisible.value = false;
    notificationTitel.value = '';
    notificationMessage.value = '';
  }

  defineExpose({
    showNotification,
  });
</script>

<style scoped>
  .confirm-notification-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1999;
  }

  .confirm-notification {
    z-index: 2000; /* z-index Modal: 1055; */
  }

  .confirm-notification-message {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
    line-height: 1.5em;
  }
</style>
