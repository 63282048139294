<template>
  <div class="row mb-3 mx-1 py-1 shadow-sm postfachMessage" :class="{'unread' : nachricht.status == 1 || nachricht.status == 0}" data-bs-toggle="modal" :data-bs-target="'#PostfachNachrichtModal-' + nachricht.id">
    <div class="col-1 my-auto">
      <font-awesome-icon v-if="nachricht.status == 1 || nachricht.status == 0" icon="envelope" class="text-start fa-lg" />
      <font-awesome-icon v-else-if="nachricht.status == 2" icon="envelope-open" class="text-start fa-lg" />
      <font-awesome-icon v-else icon="circle-question" class="text-start fa-lg" />
    </div>
    <div class="col-8 my-auto">
      <p class="p-0 m-0 mb-1">{{nachricht?.titel}}</p>
      <p class="p-0 m-0 small text-muted fw-normal">{{nachricht?.absender}}</p>
    </div>
    <div class="col-3 my-auto">
      <p class="p-0 m-0">{{adateConverter}}</p>
    </div>
  </div>

  <!-- Nachricht Modal -->
  <div
    class="modal fade"
    :id="'PostfachNachrichtModal-' + nachricht.id"
    tabindex="-1"
    :aria-labelledby="'PostfachNachrichtModal-' + nachricht.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'PostfachNachrichtModalLabel-' + nachricht.id">
            {{nachricht.titel}}
          </h5>
          <button
            v-if="nachricht.status != 1  && nachricht.status != 0"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <pre>{{nachricht.text}}</pre>
        </div>

        <div class="modal-footer">
          <button
            v-if="nachricht.status == 1  || nachricht.status == 0"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="setGelesenStatus()"
          >
            <span v-if="language == 'en_GB'">Message has been read</span>
            <span v-else>Nachricht gelesen</span>
          </button>
          <button
            v-else-if="nachricht.status == 2"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            <span v-if="language == 'en_GB'">close</span>
            <span v-else>schließen</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";

import { useStore } from '@/store'
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels";

export default defineComponent({
  name: "PostfachNachricht",
  
  props: {
    nachricht: {
      type: Object as PropType<PostfachNachricht>,
      required: true
    }
  },

  methods: {
    setGelesenStatus () {
      this.store.dispatch(NachrichtenActionTypes.SetPostfachNachrichtGelesen, this.nachricht.id)
    }
  },

  setup(props) {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const adateConverter = computed(() => {
      const adate = new Date(props.nachricht.adate);

      return ("0" + adate.getDate()).slice(-2) + "." + ("0" + (adate.getMonth() + 1)).slice(-2) + "." + adate.getFullYear() + " " + ("0" + (adate.getHours())).slice(-2) + ":" + ("0" + (adate.getMinutes())).slice(-2);
    })

    return {
      store,
      adateConverter,
      language
    }
  }
})
</script>

<style scoped>

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.postfachMessage:hover {
  color: #d99728;
  cursor: pointer;
}

.unread {
  background: #f9d5201c;
  font-weight: bold;
}
.accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
}
</style>