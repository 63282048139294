<template>
  <div class="d-flex mb-2 justify-content-end">
    <button class="btn btn-outline-light" @click="clearCanvas()"><font-awesome-icon icon="trash" /></button>
  </div>
  <!-- <canvas ref="paintCanvas" width="466" height="150"></canvas> -->
  <canvas ref="paintCanvas" width="466" height="100"></canvas>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';

  const emit = defineEmits(["insertSignaturImg"])

  // Define interface for mouse or touch position
  interface Position {
    x: number;
    y: number;
  }

  let drawing = false;
  let lastPos: Position = { x: 0, y: 0 };
  let currentPos: Position = { x: 0, y: 0 };


  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;

  // Get the position of a mouse relative to the canvas
  function getMousePos(mouseEvent: MouseEvent) {
    lastPos = currentPos;

    const rect = canvas.getBoundingClientRect();
    currentPos = {
      x: mouseEvent.clientX - rect.left,
      y: mouseEvent.clientY - rect.top
    }
  }

  // Get the position of a touch relative to the canvas
  function getTouchPos(touchEvent: TouchEvent) {
    lastPos = currentPos;

    const rect = canvas.getBoundingClientRect();
    currentPos = {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top
    };
  }

  function startDrawing() {
    drawing = true;
  }

  function stopDrawing() {
    drawing = false;
    ctx.beginPath();
    // points.length = 0; // Clear points array
  }

  function draw() {
    if (!drawing) return;

    // Set the initial stroke style and width
    // ctx.lineCap = 'round';
    // ctx.strokeStyle = 'darkblue'; // Default color
    // ctx.lineWidth = 2; // Increase the line width
    // ctx.globalAlpha = 1.0;

    
    // ctx.lineWidth = 2;
    // ctx.lineCap = 'round';
    // ctx.strokeStyle = '#555'; // Grayish color for a pencil look
    // ctx.globalAlpha = 1; // Lighter opacity for a pencil effect
    
    // Calculate the distance between the previous point and the current point
    let distX = currentPos.x - lastPos.x;
    let distY = currentPos.y - lastPos.y;
    let distance = Math.sqrt(distX * distX + distY * distY);

    // Set the minimum number of steps for interpolation based on the distance
    let steps = Math.ceil(distance / .2); // Adjust the '2' for more or fewer steps

    // Interpolate between points
    for (let i = 0; i <= steps; i++) {
        let x = lastPos.x + (distX / steps) * i;
        let y = lastPos.y + (distY / steps) * i;

        ctx.lineWidth = 3;
        ctx.lineCap = 'round';
        ctx.strokeStyle = 'darkblue'; // Semi-transparent marker
        // ctx.globalAlpha = 1; // Semi-transparent effect for markers

        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x, y); // Draw a point at each interpolated position
        ctx.stroke();
        ctx.closePath();
    }
    // ctx.moveTo(pos.x, pos.y);


    // points.push(pos);
    // ctx.beginPath();
    // ctx.moveTo(points[0].x, points[0].y);

    // for (let i = 1; i < points.length - 1; i++) {
    //   const xc = (points[i].x + points[i + 1].x) / 2;
    //   const yc = (points[i].y + points[i + 1].y) / 2;
    //   ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
    // }

    // ctx.lineTo(points[points.length - 1].x, points[points.length - 1].y);
    // ctx.stroke();
  }

  function clearCanvas() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  async function extractDrawingWithoutBackground() {
    if (canvas) {

      // canvas.toBlob((blob) => {
      //   emit("insertSignaturImg", blob)
      // })

      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d') as CanvasRenderingContext2D;

      // Set the dimensions of the temporary canvas to 1/3 of the original size
      tempCanvas.width = canvas.width / 2;
      tempCanvas.height = canvas.height / 2;

      // Scale the drawing to fit the new dimensions
      tempCtx.scale(1/2 , 1/2);
      tempCtx.drawImage(canvas, 0, 0);

      // Convert the scaled drawing to a blob
      tempCanvas.toBlob((blob) => {
        if (blob) {
          emit("insertSignaturImg", blob);
        }
      });

    }
  }

  const paintCanvas = ref<HTMLCanvasElement | null>(null)

  // Main function to set up the canvas and events
  function setupCanvas() {
    canvas = paintCanvas.value as HTMLCanvasElement;
    ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (canvas.parentElement && canvas.parentElement.clientWidth > 0) {
      canvas.width = canvas.parentElement.clientWidth - 32 || 466;
    }

    drawing = false;
    lastPos = { x: 0, y: 0 };
    currentPos = { x: 0, y: 0 };

    canvas.addEventListener('mousedown', (e: MouseEvent) => {
      getMousePos(e)
      startDrawing();
    }, { passive: true });

    canvas.addEventListener('mouseup', () => {
      stopDrawing();
    }, { passive: true });

    canvas.addEventListener('mousemove', (e: MouseEvent) => {
      getMousePos(e)
      draw();
    }, { passive: true });

    canvas.addEventListener('touchstart', (e: TouchEvent) => {
      getTouchPos(e)
      startDrawing();
      e.preventDefault();
    }, { passive: true });

    canvas.addEventListener('touchend', () => {
      stopDrawing();
    }, { passive: true });

    canvas.addEventListener('touchmove', (e: TouchEvent) => {
      getTouchPos(e)
      draw();
      e.preventDefault();
    }, { passive: true });
  }

  onMounted(() => {
    setupCanvas()
  })


  defineExpose({
    clearCanvas,
    extractDrawingWithoutBackground
  });
</script>

<style scoped>
  canvas {
    background-color: white;
  }
</style>

