import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import {store} from './store'
import { registerSW } from 'virtual:pwa-register';

import { library } from "@fortawesome/fontawesome-svg-core";

import { faWindows } from '@fortawesome/free-brands-svg-icons'

import {
  faArrowDownLong,
  faArrowUpLong,
  faBars,
  faFile,
  faUser,
  faFolderOpen,
  faFileDownload,
  faClock,
  faUsersLine,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faGear,
  faFloppyDisk,
  faUserTie,
  faFilePdf,
  faHouseUser,
  faHand,
  faEnvelope,
  faEnvelopeOpen,
  faClipboardList,
  faPaperPlane,
  faIdCard,
  faReply,
  faStopwatch,
  faEnvelopeCircleCheck,
  faCheck,
  faCheckDouble,
  faCircleQuestion,
  faNewspaper,
  faCircleInfo,
  faFolderTree,
  faRotate,
  faPenToSquare,
  faChevronUp,
  faTimes,
  faPrint,
  faMagnifyingGlassPlus,
  faPlus,
  faMinus,
  faEye,
  faTrash,
  faPen,
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";



library.add(
  faArrowDownLong,
  faArrowUpLong,
  faBars,
  faUser, 
  faUsersLine,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeCircleCheck,
  faEye,
  faFolderOpen,
  faFileDownload,
  faFile,
  faFilePdf,
  faClock,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faGear,
  faFloppyDisk,
  faUserTie,
  faHouseUser,
  faHand,
  faClipboardList,
  faPaperPlane,
  faIdCard,
  faReply,
  faStopwatch,
  faCheck,
  faCheckDouble,
  faCircleQuestion,
  faNewspaper,
  faCircleInfo,
  faFolderTree,
  faRotate,
  faWindows,
  faPenToSquare,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faTrash,
  faPen,
  faPrint,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faPlus,
  faMinus
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

registerSW({ immediate: true });

const app = createApp(App);
// app.config.globalProperties.$msalInstance = {};
// app.config.globalProperties.$emitter = new Emitter();

app.use(store)
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

import "bootstrap"