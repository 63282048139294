import { DocFile } from "@/models/Vorgange/VorgangeModels"

import { MutationTree } from "vuex"
import { State } from "./state"

export enum MutationTypes {
  SetAntragForm = "SETANTRAGFORM",
  RemoveAntragForm = "REMOVEFORM",
}

export type Mutations = {
  [MutationTypes.SetAntragForm](state: State, doc: DocFile | null): void
  [MutationTypes.RemoveAntragForm](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetAntragForm](state, doc) {
    state.antragForm = doc;
  },

  [MutationTypes.RemoveAntragForm](state) {
    state.antragForm = null;
  }
}