import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"

import { Personaldaten, Archivdata, Status, User } from "@/models/Personal/PersonalModels"
import { ArchivComponenteFile } from "@/models/Archiv/ArchivModels"
import { Configuration } from "@azure/msal-browser"

export type Getters = {
  user(state: State): User,
  azureUsername(state: State): string
  personaldaten(state: State): Personaldaten,
  archivdaten(state: State): Archivdata | null,
  profilbild(state: State): ArchivComponenteFile,
  status(state: State): Status,
  personaldatenLastUpdate(state: State): Date,
  personaldatenUpdateInterval(state: State): number,
  msalConfig(state: State): Configuration | null,
  zipDownload(state: State): Blob,
}

export const getters: GetterTree<State, RootState> & Getters = {
  user(state) {
    return state.user;
  },
  azureUsername(state) {
    return state.azureUsername;
  },
  personaldaten(state) {
    return state.personaldaten;
  },
  archivdaten(state) {
    return state.archivdaten;
  },
  profilbild(state) {
    return state.profilbild;
  },
  
  status(state) {
    return state.status;
  },
  personaldatenLastUpdate(state) {
    return state.personaldatenLastUpdate;
  },
  personaldatenUpdateInterval(state) {
    return state.personaldatenUpdateInterval;
  },
  msalConfig(state) {
    return state.msalConfig
  },
  zipDownload(state) {
    return state.zipDownload
  }
}