import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { Actions as PersonalActions, ActionTypes as PersonalActionTypes} from "../Personal/actions"
import { RootState } from '@/store';
import { State } from "./state";

import ApiService from "@/services/ApiService";
import { Einstellung } from "@/models/Common/CommonModels";


// import { ArchivComponenteFile} from "@/models/models";

export enum ActionTypes {
  SetPrevUrl = "SETPREVURL",
  SetTerminalVersion = "SETTERMINALVERSION",
  SetLanguage = "SETLANGUAGE",
  GetNewsEinstellung = "GETNEWSEINSTELLUNG"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.SetPrevUrl](context: ActionArguments, prevUrl: string): void,
  [ActionTypes.SetTerminalVersion](context: ActionArguments, terminalVersion : boolean): void
  [ActionTypes.SetLanguage](context: ActionArguments, language: string): void,
  [ActionTypes.GetNewsEinstellung](context: ActionArguments): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.SetPrevUrl]({commit}, prevUrl: string) {
    commit(MutationTypes.SetPrevUrl, prevUrl);
    sessionStorage.setItem('prevUrl', JSON.stringify(prevUrl));

    // if (prevUrl.includes("ases.phoenixgroup.eu") || prevUrl.includes("localhost")) {
    // if (prevUrl.includes("ases.phoenixgroup.eu")) {
    //   commit(MutationTypes.SetTerminalVersion, true)
    //   sessionStorage.setItem('terminalVersion', JSON.stringify(true));
    // }
    // else {
    //   commit(MutationTypes.SetTerminalVersion, false)
    //   sessionStorage.setItem('terminalVersion', JSON.stringify(false));
    // }
  },

  [ActionTypes.SetTerminalVersion]({commit}, terminalVersion: boolean) {
    commit(MutationTypes.SetTerminalVersion, terminalVersion)
    sessionStorage.setItem('terminalVersion', JSON.stringify(terminalVersion));
  },

  [ActionTypes.SetLanguage]({commit}, language: string) {
    commit(MutationTypes.SetLanguage, language);
    localStorage.setItem('language', JSON.stringify(language));
  },

  async [ActionTypes.GetNewsEinstellung]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;

    status.personalEinstellungLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.getNewsEinstellung()
      .then(res => {
        status.personalEinstellungLoading = false;
        dispatch(PersonalActionTypes.SetStatus, status);

        const newsEinstellung: Einstellung = res.data;
        commit(MutationTypes.NewsEinstellung, newsEinstellung);
        sessionStorage.setItem('newsEinstellung', JSON.stringify(newsEinstellung));
      })
      .catch(error => {
        console.log(error);
        // console.log(error.response.data.message);
        status.personalEinstellungLoading = false;
        // status.personalEinstellungErrorMsg = error.response.data.detail;
        status.personalEinstellungErrorMsg = "Es ist ein Fehler aufgetreten";
        dispatch(PersonalActionTypes.SetStatus, status);
        sessionStorage.removeItem('newsEinstellung');
      })
  }
}