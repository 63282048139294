<template>
  <!-- Bankverbindung ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>

  <BankverbindungEditModal ref="bankverbindungModalRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BankverbindungEditModal from "../Modals/BankverbindungEditModal.vue";

export default defineComponent ({
  name: "BankverbindungEditProfil",
  components: {
    BankverbindungEditModal
  },

  setup () {
    const bankverbindungModalRef = ref();

    function showModal() {
      bankverbindungModalRef.value?.showModal()
    }

    return {
      bankverbindungModalRef,
      showModal
    }
  },

})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>