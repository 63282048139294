<template>
    <!-- Name und Adresse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Changerequest for bank details</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <BankverbindungEditModalEN ref="bankverbindungModalENRef" />

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BankverbindungEditModalEN from "../Modals/BankverbindungEditModal_EN.vue";

export default defineComponent ({
  name: "BankverbindungEditVorgang",
  components: {
    BankverbindungEditModalEN
  },

  setup () {
    const bankverbindungModalENRef = ref();

    function showModal() {
      bankverbindungModalENRef.value?.showModal()
    }

    return {
      bankverbindungModalENRef,
      showModal
    }
  },
})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>