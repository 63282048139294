<template>
  <div class="mb-1 border-start border-3 border-primary">
    <div class="px-1 py-0">
      <strong>
        {{ nachricht.titel }}
      </strong>
      <p>
        {{ nachrichtTextAusschnitt }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels";

export default defineComponent({
  name: "SidebarNachricht",
  
  props: {
    nachricht: {
      type: Object as PropType<PostfachNachricht>,
      default: new PostfachNachricht()
    }
  },

  setup(props) {

    const nachrichtTextAusschnitt = computed(() => {
      const text = props.nachricht?.text;

      if (text == null || text == "") {
        return "";
      }

      return text.substring(0, 50) + "..."
    })

    return {
      nachrichtTextAusschnitt
    }
  }
})
</script>

<style scoped>
</style>