import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"

import { DocFile } from "@/models/Vorgange/VorgangeModels"


export type Getters = {
  antragForm(state: State): DocFile | null,
}

export const getters: GetterTree<State, RootState> & Getters = {
  antragForm(state) {
    return state.antragForm;
  },
}