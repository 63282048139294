export class PostfachNachrichtUpdateStatus {
  id: number;
  status: number;

  constructor (id: number, status: number) {
    this.id = id;
    this.status = status;
  }
}

export class PostfachNachricht {
  id: number;
  titel: string;
  text: string;
  absender: string;
  empfaenger: string;
  status: number;
  postfachartid: number;
  adate: string;


  constructor() {
    this.id = 0;
    this.titel = "";
    this.text = "";
    this.absender = "";
    this.empfaenger = "";
    this.status = 0;
    this.postfachartid = 0;
    this.adate = "";

  }
}