<template>
  <div class="mb-4">
    <h1 v-if="language == 'en_GB'" class="d-inline fs-4">Sent Messages</h1>
    <h1 v-else class="d-inline fs-4">Gesendete Nachrichten</h1>
  </div>

  <div id="postfachAccordion">
    <PostfachGesendetNachricht v-for="nachricht in gesendet" :key="nachricht.id" :nachricht="nachricht"/>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useStore } from '@/store'
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import PostfachGesendetNachricht from "@/components/Postfach/PostfachGesendetNachricht.vue";

export default defineComponent({
  name: "PostfachGesendet",
  components: {
    PostfachGesendetNachricht
  },

  setup () {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const gesendet = computed(() => store.getters.postfachGesendet)
    const postfachLastUpdate = computed(() => store.getters.postfachLastUpdate)
    const postfachLastUpdateInterval = computed(() => store.getters.postfachUpdateInterval)

    return {
      store,
      language,
      gesendet,
      postfachLastUpdate,
      postfachLastUpdateInterval,
    }
  },

  mounted () {
    const now = new Date();

    if (now.getTime() -  new Date(this.postfachLastUpdate).getTime() > this.postfachLastUpdateInterval) {
      this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
    }
  }
});
</script>

<style scoped>
.unread {
  background: #f9d5201c;
  font-weight: bold;
}

#postfachAccordion {
  border-radius: 5px;
}
.accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
}
</style>