<template>
<!-- Kontaktinformationen Edit Modal -->
<div
  class="modal fade"
  id="kontaktdatenEditModal"
  tabindex="-1"
  aria-labelledby="kontaktdatenEditModal"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  ref="kontaktdatenEditModalRef"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="kontaktdatenEditModalLabel">
          Edit Contact Information
        </h5>
        <button
          type="button"
          class="btn-close"
          @click="closeModal()"
        ></button>
      </div>

      <div class="modal-body" v-if="loading">
        <LoadingSpinner />
      </div>

      <div class="modal-body" v-if="!loading">
        <div class="mb-5 row">
          <div class="col">
            <label class="form-label" for="tel">Phone</label>
            <input
            id="tel"
            type="text"
            class="form-control"
            v-model="tel"
            />
          </div>
          
          <div class="col">
            <label class="form-label" for="mobil">Mobile</label>
            <input
            id="mobil"
            type="text"
            class="form-control"
            v-model="mobil"
            />
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">E-Mail (private)</label>
          <input
            type="text"
            class="form-control"
            v-model="email"
          />
        </div>

        <div class="row mb-5">
          <div class="col">
            <label class="form-label" for="email-anhang-passwort">Password for secure document transmission by email</label>
            <div class="input-group">
              <input
              type="password"
              class="form-control"
              id="email-anhang-passwort"
              autocomplete="none"
              v-model="cryptuserpassword"
              />
              <button class="btn btn-outline-secondary" type="button" @click="togglePasswordVisibility('email-anhang-passwort')">
                <font-awesome-icon icon="eye" />
              </button>
            </div>
          </div>

          <div class="col">
            <label class="form-label" for="email-anhang-passwort-2">Retype Password</label>
            <div class="input-group">
              <input
                type="password"
                class="form-control"
                id="email-anhang-passwort-2"
                autocomplete="none"
                v-model="cryptuserpassword_2"
              />
              <button class="btn btn-outline-secondary" type="button" @click="togglePasswordVisibility('email-anhang-passwort-2')">
                <font-awesome-icon icon="eye" />
              </button>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <label class="form-label">Speakap E-Mail</label>
          <input
            type="text"
            class="form-control"
            v-model="speakapemail"
          />
        </div>

        <div class="row mb-3">
          <div class="col">
            <label class="form-label" for="notfallname">Emergency contact</label>
            <input
              type="text"
              class="form-control"
              id="notfallname"
              maxlength="80"
              v-model="notfallkontakt"
            />
          </div>
        </div>

        <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" id="notfallkontaktDatenschutzInput" v-model="notfallkontaktDatenschutz">
          <label class="form-check-label fw-bold" for="notfallkontaktDatenschutzInput">
            Emergency contact agrees to the storage of the data
          </label>
        </div>

        <hr>

        <p>
          <strong>Email address used to confirm the changes and questions from the HR department.</strong>
        </p>

        <div class="mb-3">
          <label class="form-label">Response E-Mail Address</label>
          <input
            type="text"
            class="form-control"
            v-model="responseEmailAdresse"
          />
        </div>

        <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
          <pre>{{errorMessage}}</pre>
        </div>

      </div>  

      <div class="modal-footer" v-if="!loading">
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeModal()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="saveChanges()">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { KontaktinformationenEditDataRequest } from "@/models/Personal/PersonalModels";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.personalLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => store.getters.personaldaten);

    let cryptuserpassword_2 = ref("")

    const state = reactive({
      email: personaldaten.value.email,
      cryptuserpassword: "",
      notfallkontakt: personaldaten.value.notfallname,
      notfallkontaktDatenschutz: false,
      tel: personaldaten.value.tel,
      mobil: personaldaten.value.mobil,
      speakapemail: personaldaten.value.chatadresse,
      responseEmailAdresse: personaldaten.value.email,
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.email = personaldaten.value.email,
      state.cryptuserpassword = "",
      state.notfallkontakt = personaldaten.value.notfallname,
      state.notfallkontaktDatenschutz = false;
      state.tel = personaldaten.value.tel,
      state.mobil = personaldaten.value.mobil,
      state.speakapemail = personaldaten.value.chatadresse
      cryptuserpassword_2.value = "";
      errorMessage.value = "";
      state.responseEmailAdresse = personaldaten.value.email;
    }

    const kontaktdatenEditModalRef = ref();
    const closeModal = () => {
      resetForm();
      Modal.getInstance(kontaktdatenEditModalRef.value)?.hide();
    }
    const showModal = () => {
      if (kontaktdatenEditModalRef.value) {
        new Modal(kontaktdatenEditModalRef.value).show();
      }
    }

    return {
      store,
      loading,
      status,
      ...toRefs(state),
      cryptuserpassword_2,
      errorMessage,
      kontaktdatenEditModalRef,
      closeModal,
      showModal
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      if (this.cryptuserpassword != this.cryptuserpassword_2) {
        this.errorMessage = "Invalid password.\r\nPlease enter the same password in both fields!";
      }
      else if (this.notfallkontakt != "" && !this.notfallkontaktDatenschutz) {
        this.errorMessage = "Please confirm that the emergency contact\r\nagrees to the storage of the data.";
      }

      else {
        const kontaktdatenEditDataRequest: KontaktinformationenEditDataRequest = {
          email: this.email,
          cryptuserpassword: this.cryptuserpassword,
          notfallname: this.notfallkontakt,
          tel: this.tel,
          mobil: this.mobil,
          speakapemail: this.speakapemail,
          responseEmailAdresse: this.responseEmailAdresse
        }

        this.store.dispatch(PersonalActionTypes.UpdatePersonaldatenKontaktinformationen, kontaktdatenEditDataRequest)
          .then(() => {
            
            if (this.status.personalErrorMsg != "") {
              this.errorMessage = this.status.personalErrorMsg;
            }
            else {
              this.closeModal();
              this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
            }
          });
      }
    },

    togglePasswordVisibility (id: string) {
      const input = document.getElementById(id) as HTMLInputElement;
      input.type = input.type === "password" ? "text" : "password";
    },

    checkInputFirst(e: KeyboardEvent) {
      let notAllowedChars = [';', ','];
      if (notAllowedChars.includes(e.key)) {
        e.preventDefault();
      }
    },

    setInputEventListener () {
      const notfallkontakt = document.getElementById("notfallname") as HTMLElement | null
      notfallkontakt?.addEventListener("keydown", this.checkInputFirst);
      
      const tel = document.getElementById("tel") as HTMLElement | null
      tel?.addEventListener("keydown", this.checkInputFirst);
      
      const mobil = document.getElementById("mobil") as HTMLElement | null
      mobil?.addEventListener("keydown", this.checkInputFirst);
    }
  },

  mounted () {
    this.setInputEventListener();
  }

})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>