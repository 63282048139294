<template>
  <div class="d-flex justify-content-between" id="main-content">
    <Sidebar v-if="!isPublicPage && loggedIn" />
    
    <div class="flex-fill flex-column">
      <Navbar v-if="!isPublicPage && loggedIn" />
      <AutoLogout v-if="!isPublicPage && loggedIn" />
      
      <LoadingSpinner v-if="!isPublicPage && (loginLoading || logoutLoading)" :text="'... Daten werden geladen ...'"/>
      <div v-else class="p-3">
        <router-view />
      </div>
    </div>
    
    <Sidebar_rechts v-if="!isPublicPage && loggedIn" />
  </div>
</template>

<script lang="ts">
import Sidebar from "@/components/Sidebar.vue";
import Sidebar_rechts from "@/components/Sidebar_rechts.vue";
import Navbar from "@/components/Navbar.vue";
import AutoLogout from "@/components/AutoLogout.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from "@/store";
import { useRoute } from 'vue-router';
// import { ActionTypes } from "@/store/modules/actions";
import { ActionTypes } from "@/store/modules/Common/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

import { defineComponent, computed } from "vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();

    const loginLoading = computed(() => store.getters.status.personalLoginLoading);
    const logoutLoading = computed(() => store.getters.status.personalLogoutLoading);
    const loggedIn = computed(() => store.getters.user.token != "")
    // const loggedIn = computed(() => store.getters.status.personalLoggedIn)
    const msalConfig = computed(() => store.getters.msalConfig)

    const origin = window.location.origin;
    const prevUrl = document.referrer;
    const publicPages = ["Login", "Logout"]
    const isPublicPage = computed(() => route.name != null && route.name != "" && publicPages.includes(route.name.toString()))

    const terminal = window.hasOwnProperty("siteKiosk")
    store.dispatch(ActionTypes.SetTerminalVersion, terminal)
    console.log("Starte App...");
    console.log("Aufrufende URL: " + prevUrl);
    console.log("SiteKiosk: " + terminal);
    console.log("Terminal: " +  terminal);

    if (!prevUrl.includes(origin)) {
      store.dispatch(ActionTypes.SetPrevUrl, prevUrl);
    }


    if (window.screen.availWidth < 1350) {
      const state = store.getters.status;
      state.sidebarRechtsCollapsed = true;
      store.dispatch(PersonalActionTypes.SetStatus, state)
    }
    
    if (window.screen.availWidth < 1100) {
      const state = store.getters.status;
      state.sidebarCollapsed = true;
      store.dispatch(PersonalActionTypes.SetStatus, state)
    }

    return {
      store,
      loggedIn,
      loginLoading,
      logoutLoading,
      msalConfig,
      isPublicPage
    };
  },

  components: {
    Sidebar,
    Sidebar_rechts,
    Navbar,
    AutoLogout,
    LoadingSpinner,
  },

  // computed: {
  //   currentRouteName(): string {
  //     return this.$route.name as string;
  //   },
  // },

  beforeCreate () {
    if (this.msalConfig == null) {
      this.store.dispatch(PersonalActionTypes.GetAzureLoginConfig, undefined);
    }
  },
});
</script>

<style lang="scss">
@import "./assets/sass/main.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

body, html {
  margin: 0;
  height: 100%;
  width: 100%;
  display: block;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100%;
  // min-height: 100vh;
  // display: flex;
}

// #main-content {
//   min-height: 100vh;
// }
</style>
