import { Personaldaten, Status, User, Archivdata } from "@/models/Personal/PersonalModels"
import { ArchivComponenteFile, } from "@/models/Archiv/ArchivModels"
import { Configuration } from "@azure/msal-browser"


export type State = {
  user: User,
  azureUsername: string
  personaldaten: Personaldaten,
  archivdaten: Archivdata | null,
  profilbild: ArchivComponenteFile,
  personaldatenLastUpdate: Date,
  personaldatenUpdateInterval: number,
  status: Status,
  msalConfig: Configuration | null,
  identityToken: String,
  zipDownload: Blob
}

export const state: State = {
  user: sessionStorage.getItem("user") != null ? JSON.parse(sessionStorage.user) : new User(),
  azureUsername: sessionStorage.getItem("azureusername") != null ? JSON.parse(sessionStorage.azureusername) : "",
  personaldaten: sessionStorage.getItem("personaldaten") != null && sessionStorage.getItem("personaldaten") !== undefined ? JSON.parse(sessionStorage.personaldaten) : new Personaldaten(),
  archivdaten: null,
  profilbild: sessionStorage.getItem("profilbild") != null && sessionStorage.getItem("profilbild") !== undefined ? JSON.parse(sessionStorage.profilbild) : new ArchivComponenteFile(),
  status: sessionStorage.getItem("status") != null && sessionStorage !== undefined ? JSON.parse(sessionStorage.status) : new Status(),
  personaldatenLastUpdate: new Date(1),
  personaldatenUpdateInterval: 5 * 60 * 1000,
  msalConfig: localStorage.getItem("msalconfig") != null ? JSON.parse(localStorage.msalconfig) : null,
  identityToken: '',
  zipDownload: new Blob()
}
