<template>
  <!-- Kontaktdaten ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Change contact details</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="floppy-disk" />
      </button>
    </div>
  </div>

  <KontaktinformationenEditModalEN ref="kontaktinformationenModalENRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KontaktinformationenEditModalEN from "../Modals/KontaktinformationenEditModal_EN.vue";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    KontaktinformationenEditModalEN
  },

  setup () {
    const kontaktinformationenModalENRef = ref();

    function showModal() {
      kontaktinformationenModalENRef.value?.showModal()
    }

    return {
      kontaktinformationenModalENRef,
      showModal
    }
  },

})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>