import { GetterTree } from "vuex"
import { State } from "./state"

import { RootState } from "@/store";

import { Status } from "@/models/Personal/PersonalModels"
import { ArchivAuth, Dokumentart, ArchivComponente, Folder, FolderDocsSearchParams } from "@/models/Archiv/ArchivModels"

export type Getters = {
  archivUser(state: State): ArchivAuth,
  dokumentarten(state: State): Array<Dokumentart>,
  componente(state: State): ArchivComponente,
  folder(state: State): Array<Folder>,
  folderLastUpdate(state: State): Date,
  folderUpdateInterval(state: State): number,
  dokumentartenLastUpdate(state: State): Date,
  dokumentartenUpdateInterval(state: State): number
  folderData(state: State): Array<FolderDocsSearchParams>
}

export const getters: GetterTree<State, RootState> & Getters = {
  archivUser(state) {
    return state.archivUser
  },
  dokumentarten(state) {
    return state.dokumentarten;
  },
  componente(state) {
    return state.componente;
  },
  folder(state) {
    return state.folder;
  },
  folderLastUpdate(state) {
    return state.folderLastUpdate;
  },
  folderUpdateInterval(state) {
    return state.folderUpdateInterval;
  },
  dokumentartenLastUpdate(state) {
    return state.dokumentartenLastUpdate;
  },
  dokumentartenUpdateInterval(state) {
    return state.dokumentartenUpdateInterval;
  },

  folderData(state) {
    return state.folderData;
  }
}