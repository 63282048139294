import { GetterTree } from "vuex"
import { State } from "./state"

import { RootState } from "@/store";
import { Einstellung } from "@/models/Common/CommonModels";


export type Getters = {
  prevUrl(state: State): string,
  terminalVersion(state: State): boolean,
  language(state: State): string,
  newsEinstellung(state: State): Einstellung
}

export const getters: GetterTree<State, RootState> & Getters = {
  prevUrl(state) {
    return state.prevUrl;
  },
  terminalVersion(state) {
    return state.terminalVersion;
  },
  language(state) {
    return state.language;
  },
  newsEinstellung(state) {
    return state.newsEinstellung
  }
}