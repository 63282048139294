<template>
    <!-- Krankenkasse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Krankenkasse ändern</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <KrankenkasseEditModal ref="krankenkasseModalENRef" />

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KrankenkasseEditModal from "../Modals/KrankenkasseEditModal.vue";


export default defineComponent ({
  name: "KrankenkasseEditvorgang",
  components: {
    KrankenkasseEditModal
  },

  setup () {
    const krankenkasseModalENRef = ref();

    function showModal() {
      krankenkasseModalENRef.value?.showModal()
    }

    return {
      krankenkasseModalENRef,
      showModal
    }
  },

})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>