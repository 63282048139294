import { Status} from "@/models/Personal/PersonalModels"
import { ArchivAuth, Dokumentart, ArchivComponente, Folder, FolderDocsSearchParams } from "@/models/Archiv/ArchivModels"

export type State = {
  archivUser: ArchivAuth
  folderLastUpdate: Date,
  folderUpdateInterval: number,
  dokumentartenLastUpdate: Date,
  dokumentartenUpdateInterval: number,
  dokumentarten: Array<Dokumentart>,
  componente: ArchivComponente,
  folder: Array<Folder>,
  folderData: Array<FolderDocsSearchParams>
}

export const state: State = {
  archivUser: sessionStorage.getItem("archivUser") != null ? JSON.parse(sessionStorage.archivUser) : new ArchivAuth(),
  dokumentarten: sessionStorage.getItem("dokumentarten") != null ? JSON.parse(sessionStorage.dokumentarten) : new Array<Dokumentart>(),
  componente: new ArchivComponente(),
  folder: sessionStorage.getItem("folder") != null ? JSON.parse(sessionStorage.folder) : new Array<Folder>(),
  folderData: sessionStorage.getItem("folderData") != null ? JSON.parse(sessionStorage.folderData) : new Array<FolderDocsSearchParams>(),
  folderLastUpdate: new Date(1),
  folderUpdateInterval: 5 * 60 * 1000,
  dokumentartenLastUpdate: new Date(1),
  dokumentartenUpdateInterval: 5 * 60 * 1000,
}
