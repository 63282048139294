<template>
  <!-- Name und Adresse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Changerequest for new adress and name</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <NameUndAdresseEditModalEN ref="nameUndAdresseModalENRef" :index="index" />

</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import NameUndAdresseEditModalEN from "../Modals/NameUndAdresseEditModal_EN.vue";

export default defineComponent ({
name: "NameUndAdresseEditVorgangEN",
components: {
  NameUndAdresseEditModalEN
},

props: {
  index: {
    type: Number,
    default: 2
  }
},

setup () {
  const nameUndAdresseModalENRef = ref();

  function showModal() {
    nameUndAdresseModalENRef.value?.showModal()
  }

  return {
    nameUndAdresseModalENRef,
    showModal
  }
},


})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>