<template>
  <!-- Name und Adresse ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>
  
  <NameUndAdresseEditModal ref="nameUndAdresseModalRef" :index="index" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import NameUndAdresseEditModal from "../Modals/NameUndAdresseEditModal.vue";

export default defineComponent ({
  name: "NameUndAdresseEditProfil",
  components: {
    NameUndAdresseEditModal
  },

  props: {
    index: {
      type: Number,
      default: 1
    }
  },

  setup () {
    const nameUndAdresseModalRef = ref();

    function showModal() {
      nameUndAdresseModalRef.value?.showModal()
    }

    return {
      nameUndAdresseModalRef,
      showModal
    }
  },
})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>