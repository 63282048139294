<template>
  <!-- Krankenkasse ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>

  <KrankenkasseEditModalEN ref="krankenkasseModalENRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KrankenkasseEditModalEN from "../Modals/KrankenkasseEditModal_EN.vue";

export default defineComponent ({
  name: "KrankenkasseEditProfilEN",
  components: {
    KrankenkasseEditModalEN
  },

  setup () {
    const krankenkasseModalENRef = ref();

    function showModal() {
      krankenkasseModalENRef.value?.showModal()
    }

    return {
      krankenkasseModalENRef,
      showModal
    }
  },
})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>