<template>
  <!-- Kontaktdaten ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>

  <KontaktinformationenEditModalEN ref="kontaktinformationenModalENRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KontaktinformationenEditModalEN from "../Modals/KontaktinformationenEditModal_EN.vue";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    KontaktinformationenEditModalEN
  },

  setup () {
    const kontaktinformationenModalENRef = ref();

    function showModal() {
      kontaktinformationenModalENRef.value?.showModal()
    }

    return {
      kontaktinformationenModalENRef,
      showModal
    }
  },

})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>