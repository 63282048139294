// src/composables/usePdfViewer.ts
import { ref } from 'vue';

import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer.mjs';

import workerSrc from 'pdfjs-dist/build/pdf.worker.min.mjs?worker&url';
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

const ENABLE_XFA = false;
const CMAP_URL = new URL('/pdf_viewer/cmaps/', window.location.origin).toString();
const CMAP_PACKED = true;

export function usePdfViewer(containerId: string, openSignaturDialog: () => void) {
  let pdfDoc: any = undefined;
  let eventBus: any = undefined;
  let pdfViewer: any = undefined;

  const pdfUrl = ref('');
  const pdfActionsAvailable = ref(false);

  const formsData = ref<any>(null);
  const persdocid = ref('');

  const currentPage = ref(1);
  const totalPages = ref(0);
  const scale = ref<number | string>(0);
  const viewerId = ref('');

  let editor: any = null;
  const signaturDivId = ref<any>(null);

  async function loadPdf() {
    try {
      if (!pdfjsLib.getDocument || !pdfjsViewer.PDFViewer) {
        alert('Please build the pdfjs-dist library using `gulp dist-install`');
        return;
      }

      clearViewer();

      const container = document.getElementById('container-' + containerId) as HTMLDivElement;

      const viewerDiv = document.createElement('div');
      viewerDiv.id = 'viewer-' + viewerId.value;
      viewerDiv.classList.add('pdfViewer');
      container.appendChild(viewerDiv);

      eventBus = new pdfjsViewer.EventBus();

      // (Optionally) enable hyperlinks within PDF files.
      const pdfLinkService = new pdfjsViewer.PDFLinkService({ eventBus });

      // (Optionally) enable find controller.
      const pdfFindController = new pdfjsViewer.PDFFindController({
        eventBus,
        linkService: pdfLinkService,
      });

      pdfViewer = new pdfjsViewer.PDFViewer({
        container,
        eventBus,
        linkService: pdfLinkService,
        // downloadManager: pdfDownlaodManer,
        findController: pdfFindController,
        // scriptingManager: pdfScriptingManager,
      });
      pdfLinkService.setViewer(pdfViewer);

      // Loading document.
      const loadingTask = pdfjsLib.getDocument({
        url: pdfUrl.value,
        // url: DEFAULT_URL,
        cMapUrl: CMAP_URL,
        cMapPacked: CMAP_PACKED,
        enableXfa: ENABLE_XFA,
      });

      pdfDoc = await loadingTask.promise;

      // Document loaded, specifying document for the viewer and
      // the (optional) linkService.
      pdfViewer.setDocument(pdfDoc);
      pdfLinkService.setDocument(pdfDoc, null);

      setEventListeners();
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  }

  //#region EventListeners
  function setEventListeners() {
    eventBus.on('pagesinit', function () {
      totalPages.value = pdfViewer.pagesCount;

      /*
       * pdfViewer.currentScaleValue options:
       * "auto" - resizes the page to fit the container
       * "page-actual" - displays the page at actual size
       * "page-fit" - displays the page to fit the container
       * "page-width" - displays the page at the width of the container
       * "page-height" - displays the page at the height of the container
       * "0.5" - scales the page to 50% of the original size
       * "1" - scales the page to 100% of the original size
       */
      pdfViewer.currentScaleValue = scale.value == 0 ? 'page-fit' : scale.value;
      scale.value = pdfViewer.currentScaleValue;

      // Insert Data in Annotations
      insertFormAnnotationsData();

      // We can try searching for things.
      // if (SEARCH_FOR) {
      //   eventBus.dispatch("find", { type: "", query: SEARCH_FOR });
      // }
    });

    eventBus.on('pagechanging', function (evt: any) {
      currentPage.value = evt.pageNumber;
    });

    eventBus.on('annotationlayerrendered', function (evt: any) {
      addSignaturButtonToAnnotation();
    });
  }
  //#endregion

  //#region Annotations
  function setAnnotationEditorMode(mode: any) {
    pdfViewer.annotationEditorMode = { mode: mode };
  }

  async function removeEditor() {
    if (editor && editor.parent) {
      pdfViewer.annotationEditorMode = { mode: pdfjsLib.AnnotationEditorType.STAMP };

      editor.parent.remove(editor);
      editor = null;

      await new Promise((resolve) => setTimeout(resolve, 200));
      pdfViewer.annotationEditorMode = { mode: pdfjsLib.AnnotationEditorType.NONE };
    }
  }

  async function addStampAnnotation(imgSrc: any = '') {
    pdfViewer.annotationEditorMode = { mode: pdfjsLib.AnnotationEditorType.STAMP };
    const pageNum = pdfViewer.currentPageNumber;
    const pageEditorLayer = pdfViewer._pages[pageNum - 1].annotationEditorLayer.annotationEditorLayer;

    await new Promise((resolve) => setTimeout(resolve, 200));

    if (pageEditorLayer) {
      try {
        let x = 100;
        let y = 100;

        const parent = document.querySelector(`.page[data-page-number="${pageNum}"]`);
        const signaturDiv = document.getElementById('pdfjs_internal_id_' + signaturDivId.value);
        if (parent && signaturDiv) {
          const parentRect = parent.getBoundingClientRect();
          const rect = signaturDiv.getBoundingClientRect();
          x = rect.left - parentRect.left;
          y = rect.top - parentRect.top - 20;
        } else if (parent) {
          const parentRect = parent.getBoundingClientRect();
          const parentRectWidth = parentRect.width;
          const parentRectHeight = parentRect.height;

          x = Math.floor(parentRectWidth / 2);
          y = Math.floor(parentRect.top + 2 * (parentRectHeight / 3));
        }

        editor = await pageEditorLayer.createAndAddNewEditor({ offsetX: x, offsetY: y }, /* isCentered = */ false, { bitmapFile: imgSrc });

        if (editor != null) {
          const editToolbarDiv = editor.div.querySelector('.editToolbar');
          if (editToolbarDiv) {
            editToolbarDiv.innerHTML = '';
            const btnGroup = document.createElement('div');
            btnGroup.classList.add('btn-group');

            const button1 = document.createElement('button');
            button1.classList.add('btn', 'btn-success', 'signatur-accept');
            button1.addEventListener('click', () => {
              setAnnotationEditorMode(pdfjsLib.AnnotationEditorType.NONE);
            });

            const button2 = document.createElement('button');
            button2.classList.add('btn', 'btn-danger', 'signatur-delete');
            button2.addEventListener('click', () => {
              removeEditor();
            });

            btnGroup.appendChild(button1);
            btnGroup.appendChild(button2);

            editToolbarDiv.appendChild(btnGroup);
          }
        }
      } catch (error) {
        console.error('Error adding stamp annotation:', error);
      }
    } else {
      console.error('Annotation editor layer not found.');
    }
  }

  async function addTextAnnotation(text: string = '') {
    pdfViewer.annotationEditorMode = { mode: pdfjsLib.AnnotationEditorType.FREETEXT };

    const pageNum = pdfViewer.currentPageNumber;
    const pageEditorLayer = pdfViewer._pages[pageNum - 1].annotationEditorLayer.annotationEditorLayer;

    let textEditor = pageEditorLayer.createAndAddNewEditor({ offsetX: 0, offsetY: 0 }, /* isCentered = */ false);

    textEditor.setContentText = function (text: string) {
      textEditor.contentDiv.innerHTML = '<div>' + text + '</div>';
      // editor.contentDiv.innerHTML = defaultContent
    };
    textEditor.setContentText(text);

    pdfViewer.annotationEditorMode = { mode: pdfjsLib.AnnotationEditorType.NONE };

    // return editor
  }
  //#endregion

  //#region Insert FormAnnotations-Data
  async function insertFormAnnotationsData() {
    if (formsData.value != null) {
      for (let pageNum = 1; pageNum <= pdfViewer.pagesCount; pageNum++) {
        const page = await pdfDoc.getPage(pageNum);
        const annotationsData = await page.getAnnotations();
        if (annotationsData.length > 0 && formsData.value != null) {
          for (var ann of annotationsData) {
            switch (ann.fieldName.toUpperCase()) {
              case 'GUID':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.guid });
                break;
              case 'NAME':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.name });
                break;
              case 'VORNAME':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.vorname });
                break;
              case 'STRASSE':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.strasse });
                break;
              case 'PLZ':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.plz });
                break;
              case 'ORT':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.ort });
                break;
              case 'LAND':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.land });
                break;
              case 'MANDANT':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.mandant });
                break;
              case 'MAN':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.mandant });
                break;
              case 'ABRKREIS':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.abrkreis });
                break;
              case 'AK':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.abrkreis });
                break;
              case 'PERSNR':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.persnr });
                break;
              case 'PNR':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.persnr });
                break;
              case 'VERTNR':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.vertnr });
                break;
              case 'GEBDAT':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: transformDate(formsData.value.gebdat) });
                break;
              case 'DATUM':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: transformDate(new Date()) });
                break;
              case 'BARCODE':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, {
                  value: `###${formsData.value.mandant}/${formsData.value.abrkreis}/${formsData.value.persnr}/${persdocid.value}`,
                });
                break;
              case 'SIGNATUR':
                signaturDivId.value = ann.id;
                // pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, {value: `QWERT`})
                // pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, {value: `X`})
                // pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, {value: `${formsData.value.titel != null && formsData.value.titel != "" ? formsData.value.titel + " " : ""} ${formsData.value.vorname} ${formsData.value.name}`})
                break;
              case 'IBAN':
                pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: formsData.value.iban.replace(/(.{4})/g, '$1 ') });
                break;
            }

            // if (ann.required == true) {
            //   console.log(pdfViewer.pdfDocument.annotationStorage);
            //   console.log(pdfViewer.pdfDocument.annotationStorage.getValue(ann.id, ''));
            //   // var value = pdfViewer.pdfDocument.annotationStorage.getValue(ann.id);
            //   // console.log(value);
            // }
            // pdfViewer.pdfDocument.annotationStorage.setValue(ann.id, { value: 'X' });
          }
        }
      }
    }
  }

  async function checkRequiredAnnotationsSet(): Promise<Array<string>> {
    const notSetAnnotations: string[] = [];

    if (formsData.value != null) {
      for (let pageNum = 1; pageNum <= pdfViewer.pagesCount; pageNum++) {
        const page = await pdfDoc.getPage(pageNum);
        const annotationsData = await page.getAnnotations();
        if (annotationsData.length > 0 && formsData.value != null) {
          for (var ann of annotationsData) {
            if (ann.fieldType == 'Tx' && ann.required == true) {
              const value = pdfViewer.pdfDocument.annotationStorage.getValue(ann.id, '');
              if (value.value == '') {
                notSetAnnotations.push(`${ann.fieldName} (${ann.alternativeText})`);
              }
            }
          }
        }
      }
    }

    return notSetAnnotations;
  }

  function addSignaturButtonToAnnotation() {
    const sectionElement = document.querySelector(`section[data-annotation-id="${signaturDivId.value}"]`);

    const inputElement = sectionElement?.querySelector('input');
    if (inputElement != null) {
      inputElement.disabled = true;
    }

    const id = 'signautr-btn-' + signaturDivId.value;
    const btnExists = sectionElement?.querySelector(`#${id}`);

    if (btnExists == null) {
      const button1 = document.createElement('button');
      button1.id = id;
      button1.innerHTML = 'Bitte hier unterschreiben';
      button1.classList.add('btn', 'btn-primary', 'mt-3', 'signatur-btn');
      button1.addEventListener('click', () => {
        openSignaturDialog();
        // setAnnotationEditorMode(pdfjsLib.AnnotationEditorType.NONE);
      });

      sectionElement?.appendChild(button1);
    }
  }

  function transformDate(datum: Date): string {
    if (datum == null) {
      return '';
    }

    const date: Date = new Date(datum.toString());

    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
  }

  //#endregion

  //#region Save and Download PDF
  async function saveOrDownloadPDF() {
    let blob = new Blob();
    if (pdfDoc.annotationStorage.size == 0) {
      blob = await downloadPDF();
    } else {
      blob = await savePDF();
    }

    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }

  async function downloadPDF() {
    const data = await pdfViewer.pdfDocument.getData();
    const blob = new Blob([data], {
      type: 'application/pdf',
    });

    return blob;
  }

  async function savePDF() {
    const data = await pdfViewer.pdfDocument.saveDocument();
    const blob = new Blob([data], {
      type: 'application/pdf',
    });

    return blob;
  }
  //#endregion

  //#region Pages
  function setCurrentPage(pageNum: number | string) {
    const number = parseInt(pageNum.toString());
    if (number > 1 && number <= pdfViewer.pagesCount) {
      pdfViewer.currentPageNumber = number;
      currentPage.value = number;
    } else if (number <= 1) {
      pdfViewer.currentPageNumber = 1;
      currentPage.value = 1;
    } else if (number > pdfViewer.pagesCount) {
      pdfViewer.currentPageNumber = pdfViewer.pagesCount;
      currentPage.value = pdfViewer.pagesCount;
    } else {
      console.log('Ungültige Seitenzahl');
    }
  }

  async function nextPage() {
    pdfViewer.nextPage();
  }

  async function previousPage() {
    pdfViewer.previousPage();
  }
  //#endregion

  //#region Scale
  async function zoomIn() {
    pdfViewer.increaseScale();
    scale.value = `${Math.floor(pdfViewer.currentScaleValue * 100)}%`;
  }

  async function zoomOut() {
    pdfViewer.decreaseScale();
    scale.value = `${Math.floor(pdfViewer.currentScaleValue * 100)}%`;
  }
  //#endregion

  function clearViewer() {
    pdfActionsAvailable.value = false;
    scale.value = 0;
    const container = document.getElementById('container-' + containerId);
    if (container != null && container != undefined) {
      container.innerHTML = '';
    }
  }

  return {
    pdfUrl,
    pdfActionsAvailable,

    formsData,
    persdocid,

    currentPage,
    totalPages,
    scale,

    viewerId,

    loadPdf,
    saveOrDownloadPDF,
    // downloadPDF,
    // savePDF,

    setAnnotationEditorMode,
    removeEditor,
    addStampAnnotation,
    addTextAnnotation,

    checkRequiredAnnotationsSet,

    setCurrentPage,
    nextPage,
    previousPage,

    zoomIn,
    zoomOut,
    clearViewer,
  };
}
