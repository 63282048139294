<template>
  <div class="d-flex justify-content-between mb-4">
    <span v-if="foldertitel[2] == '.'" class="my-auto">
    <h1 class="fs-4">{{foldertitel.substring(4)}}</h1>
    </span>
    <span v-else class="my-auto">
      <h1 class="fs-4 my-auto">{{foldertitel}}</h1>
    </span>

    <form @submit.prevent="searchFolderDocuments()">
      <div class="input-group input-group-sm my-auto">
        <input v-if="language == 'en_GB'" v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Search...">
        <input v-else v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
        <span class="input-group-text">
          <button type="submit" class="btn btn-sm">
            <font-awesome-icon icon="magnifying-glass"/>
          </button>
        </span>
      </div>
    </form>

    <button class="btn btn-outline-white btn-sm" @click="updateList()">
      <font-awesome-icon icon="rotate"/>
    </button>
  </div>

  <div v-if="selectedDocs.length > 0" class="mb-3 bg-white py-2 px-1 shadow">
    <button v-if="!terminalversion" type="button" class="btn my-auto" @click="showGetDocsAsZipModal()"><font-awesome-icon icon="file-download" /> <span v-if="language == 'en_GB'">Download</span><span v-else>Herunterladen</span></button>
    <button type="button" class="btn my-auto" @click="showEmailModal()"><font-awesome-icon icon="envelope" /> <span v-if="language == 'en_GB'">Send</span><span v-else>Senden</span></button>
    <!-- <button v-if="folder?.druckenmitarbeiter == 1" type="button" class="btn my-auto" @click="printSelectedDocs()" disabled><font-awesome-icon icon="print" /> Drucken</button> -->
  </div>

  <div v-else class="mb-3 spacer-height"></div>

  <LoadingSpinner v-if="language == 'en_GB' && loading && (folder?.dokumente == null || folder.dokumente.length == 0)" :text="'... loading Documents ...'" />
  <LoadingSpinner v-else-if="language != 'en_GB' && loading && (folder?.dokumente == null || folder.dokumente.length == 0)" :text="'... Dokumente werden geladen ...'" />

  <div id="ordner-content" v-else>
    <div v-if="folder?.dokumente != null && folder.dokumente.length > 0">
      <div class="row mx-1 bg-white shadow-sm">
        <div class="col-1 d-flex align-items-center">
          <div class="form-check">
            <input class="form-check-input my-auto" type="checkbox" :value="true" id="dokumentSelect" v-model="selectAll" @change="selectAllDocs()" >
            <label class="form-check-label my-auto w-100" for="dokumentSelect">
              <!-- Default checkbox -->
            </label>
          </div>

          <font-awesome-icon icon="file" class="text-secondary ms-2"/>
        </div>

        <div class="col">
          <div class="row">
            <div class="col-9 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong v-if="language == 'en_GB'" class="me-1">Title</strong>
                <strong v-else class="me-1">Titel</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon"/>
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon"/>

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                      </div>
                      <div class="col">
                        A - Z
                      </div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                      </div>
                      <div class="col">
                        Z - A
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-3 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong v-if="language == 'en_GB'" class="me-1">Date</strong>
                <strong v-else class="me-1">Datum</strong>

                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'datumasc'" class="text-secondary table-sort-icon"/>
                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'datumdesc'" class="text-secondary table-sort-icon"/>

                <ul class="dropdown-menu m-0 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('datumasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'datumasc'" />
                      </div>
                      <div class="col">
                        <span v-if="language == 'en_GB'">Oldest first</span>
                        <span v-else>Älteste oben</span>
                      </div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('datumdesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'datumdesc'" />
                      </div>
                      <div class="col">
                        <span v-if="language == 'en_GB'">Newest first</span>
                        <span v-else>Neueste oben</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2 mx-1 py-1 shadow-sm" v-for="dokument in sorteddocs" :key="dokument.id">
        <div class="col-1 d-flex align-items-center">
          <div class="form-check">
            <input class="form-check-input my-auto" type="checkbox" :value="dokument" v-model="selectedDocs" :id="'dokumentSelect-' + dokument.id" @change="checkIfAllDocsSelected()">
            <label class="form-check-label my-auto w-100" :for="'dokumentSelect-' + dokument.id">
              <!-- Default checkbox -->
            </label>
          </div>

          <font-awesome-icon icon="file-pdf" class="ms-2 fa-lg"/>
        </div>

        <div class="col">
          <OrdnerDokument :dokument="dokument" :print-option="folder?.druckenmitarbeiter == 1 ? true : false" />
        </div>
      </div>

      <div v-if="folderData != null && folderData.allDocsLoaded && folderData.allDocsLoaded && displayPage > page" class="alert alert-info mb-3 text-center">Es wurden alle Dokumente geladen</div>

      <div class="d-flex justify-content-center">
        <LoadingSpinner v-if="loading && folder?.dokumente != null && folder.dokumente.length > 0" />

        <div v-else class="d-flex flex-column justify-content-center mt-4">

          <div class="flex-row">
            <button class="btn btn-outline-secondary me-4" :disabled="displayPage == 1" @click="goToPrevPage()"><font-awesome-icon icon="chevron-left" /></button>
            <strong>{{ displayPage }}</strong>
            <button class="btn btn-outline-secondary ms-4" :disabled="folderData != null && folderData.allDocsLoaded && displayPage > page" @click="goToNextPage()"><font-awesome-icon icon="chevron-right" /></button>
          </div>

          <!-- <div v-if="folderData != null && folderData.allDocsLoaded && folderData.allDocsLoaded && displayPage > page" class="alert alert-info mb-3">Es wurden alle Dokumente geladen</div> -->

          <!-- <button class="btn btn-outline-secondary" @click="loadNextPage()">Weiter Dokument laden</button> -->
        </div>
      </div>
    </div>


    <div v-else>
      <p>- Keine Dokumente gefunden -</p>
    </div>
  </div>

  <!-- Send PDF Email Modal -->
  <div
    class="modal fade p-0"
    id="sendMultipleDocsEmailModal"
    tabindex="-1"
    aria-labelledby="sendMultipleDocsEmailModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="sendMultipleDocsEmailModalRef"
    >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 v-if="language == 'en_GB'" class="modal-title my-auto" id="sendMultipleDocsEmailModalLabel">
              Send to private email
            </h5>

            <h5 v-else class="modal-title my-auto" id="sendMultipleDocsEmailModalLabel">
              An private E-Mail Adresse senden
            </h5>
          </div>
          <button
            type="button"
            class="btn-close"
            @click="closeEmailModal()"
          ></button>
        </div>

        <div class="modal-body" id="sendMultipleDocsEmailModalBody">
          <div v-if="language == 'en_GB'">
            <div v-if="emailErrorMsg != ''" class="alert alert-danger">
              {{ emailErrorMsg }}
              <br>
              Please try again later.
            </div>
            
            <div v-if="emailLoading">
              <LoadingSpinner />
            </div>
            
            <div v-else>
              <h5>Documents</h5>
              <ul class="list-group">
                <li v-for="doc in selectedDocs" :key="doc.id" class="list-group-item">{{ doc.titel }} ({{ doc.componente.filename }})</li>
              </ul>

              <hr class="my-3">

              <p><strong>Attention:</strong> The documents will be sent <span class="text-danger">unencrypted</span> to the following email address:</p>
              <div class="my-3">
                <label for="privateEmailInput" class="form-label">Private email address</label>
                <input type="email" class="form-control" id="privateEmailInput" v-model="privateEmail">
              </div>
            </div>
          </div>

          <div v-else>
            <div v-if="emailErrorMsg != ''" class="alert alert-danger">
              {{ emailErrorMsg }}
              <br>
              Bitte probieren Sie es später noch einmal.
            </div>
            
            <div v-if="emailLoading">
              <LoadingSpinner />
            </div>

            
            <div v-else>
              <h5>Dokumente</h5>

              <ul class="list-group">
                <li v-for="doc in selectedDocs" :key="doc.id" class="list-group-item">{{ doc.titel }} ({{ doc.componente.filename }})</li>
              </ul>

              <hr class="my-3">

              <p><strong>Achtung:</strong> Die Dokumente werden <span class="text-danger">unverschlüsselt</span> an folgende E-Mail Adresse versendet:</p>
              <div class="my-3">
                <label for="privateEmailInput" class="form-label">Private E-Mail Adresse</label>
                <input type="email" class="form-control" id="privateEmailInput" v-model="privateEmail">
              </div>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <div v-if="language == 'en_GB'">
            <button type="button" class="btn btn-secondary" @click="closeEmailModal()">Cancel</button>
            <button type="button" class="btn btn-primary ms-3" @click="sendSelectedDocs()">Send as E-Mail</button>
          </div>

          <div v-else>
            <button type="button" class="btn btn-secondary" @click="closeEmailModal()">Abbrechen</button>
            <button type="button" class="btn btn-primary ms-3" @click="sendSelectedDocs()">E-Mail senden</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- GetDocsAsZip Modal -->
  <div
    class="modal fade p-0"
    id="getDocsAsZipModal"
    tabindex="-1"
    aria-labelledby="getDocsAsZipModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="getDocsAsZipModalRef"
    >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 v-if="language == 'en_GB'" class="modal-title my-auto" id="getDocsAsZipModalLabel">
              Download documents
            </h5>

            <h5 v-else class="modal-title my-auto" id="getDocsAsZipModalLabel">
              Dokumente herunterladen
            </h5>
          </div>
          <button
            type="button"
            class="btn-close"
            @click="closeGetDocsAsZipModal()"
          ></button>
        </div>

        <div class="modal-body" id="getDocsAsZipModalBody">
          <div v-if="language == 'en_GB'">
            <div v-if="getDocsErrorMsg != ''" class="alert alert-danger">
              {{ getDocsErrorMsg }}
              <br>
              Please try again later.
            </div>
            
            <div v-if="getDocsLoading">
              <LoadingSpinner />
            </div>
            
            <div v-else>
              <h5>Documents</h5>
              <ul class="list-group">
                <li v-for="doc in selectedDocs" :key="doc.id" class="list-group-item">{{ doc.titel }} ({{ doc.componente.filename }})</li>
              </ul>
            </div>
          </div>

          <div v-else>
            <div v-if="getDocsErrorMsg != ''" class="alert alert-danger">
              {{ getDocsErrorMsg }}
              <br>
              Bitte probieren Sie es später noch einmal.
            </div>
            
            <div v-if="getDocsLoading">
              <LoadingSpinner />
            </div>

            
            <div v-else>
              <h5>Dokumente</h5>

              <ul class="list-group">
                <li v-for="doc in selectedDocs" :key="doc.id" class="list-group-item">{{ doc.titel }} ({{ doc.componente.filename }})</li>
              </ul>

              <hr class="my-3">

              <p><strong>Achtung:</strong> Die Dokumente werden <span class="text-danger">unverschlüsselt</span> an folgende E-Mail Adresse versendet:</p>
              <div class="my-3">
                <label for="privateEmailInput" class="form-label">Private E-Mail Adresse</label>
                <input type="email" class="form-control" id="privateEmailInput" v-model="privateEmail">
              </div>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <div v-if="language == 'en_GB'">
            <button type="button" class="btn btn-secondary" @click="closeGetDocsAsZipModal()">Cancel</button>
            <button type="button" class="btn btn-primary ms-3" @click="downloadSelectedDocs()">Download</button>
          </div>

          <div v-else>
            <button type="button" class="btn btn-secondary" @click="closeGetDocsAsZipModal()">Abbrechen</button>
            <button type="button" class="btn btn-primary ms-3" @click="downloadSelectedDocs()">Herunterlande</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from "vue";
import { useStore } from '@/store'
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { Modal } from 'bootstrap';

import { ArchivDokument, FolderDocsSearchParams, GetFolderDocsRequest } from "@/models/Archiv/ArchivModels";
import { MultipleDocsRequest, ArchivDocRequest } from "@/models/Personal/PersonalModels";


import OrdnerDokument from "@/components/Ordner/OrdnerDokument.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue"

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PAGESIZE: number = config.ordnerPageSize

export default defineComponent({
  name: "Ordner",
  components: {
    OrdnerDokument,
    LoadingSpinner
  },

  props: {
    folderid: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const oldFolderid = props.folderid;

    const displayPage = ref(1)

    const page = ref(1)
    const searchString = ref("")
    const filter = ref("")

    const personaldaten = computed(() => store.getters.personaldaten)
    const user = computed(() => store.getters.user);
    const archivUser = computed(() => store.getters.archivUser);
    const loading = computed(() => store.getters.status.archivFolderDocsLoading)
    const language = computed(() => store.getters.language);


    const folder = computed(() => store.getters.folder.find(f => f.id.toString() == props.folderid));
    const foldertitel = computed(() => {
      if (folder.value && language.value == "de_DE" && folder.value?.foldertitleDe != null && folder.value?.foldertitleDe != "") {
        return folder.value?.foldertitleDe
      }
      else if (folder.value && language.value == "en_GB" && folder.value?.foldertitleEn != null && folder.value?.foldertitleEn != "") {
        return folder.value?.foldertitleEn
      }
      else {
        return folder.value?.foldertitle || "Dokumente"
      }
    })

    const folderData = computed(() => {
      const data = store.getters.folderData.find((f: FolderDocsSearchParams) => f.folderid == parseInt(props.folderid))
      
      if (data != null) {
        page.value = data.page != null ? data.page : 1;
        searchString.value = data.searchString;
      }

      return data;
    })

    const sortByValue = ref("datumdesc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const sorteddocs = computed(() => {
      return folder.value?.dokumente
      .filter(doc => doc.titel.toLowerCase().includes(filter.value.toLowerCase()))
      .sort((a, b) => {
        if (sortByValue.value == "titeldesc" || sortByValue.value == "titelasc") {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titleComparison = -1 * titleComparison
          }
          
          if (titleComparison == 0) {
            var dateComparison = a.mdate.toString().localeCompare(b.mdate.toString())
            return dateComparison
          }
          
          return titleComparison
        }

        else if (sortByValue.value == "datumdesc" || sortByValue.value == "datumasc") {
          if (sortByValue.value == "datumasc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? -1 : 1
          }
          else if (sortByValue.value == "datumdesc" && a.ddate != null && b.ddate != null) {
            return a.ddate < b.ddate ? 1 : -1
          }
          else {
            return 0
          }
        }

        return 0
      })
      .slice((displayPage.value - 1) * PAGESIZE, ((displayPage.value - 1) * PAGESIZE) + PAGESIZE)
    })

    const terminalversion = computed(() => store.getters.terminalVersion)

    const selectedDocs = ref(new Array<ArchivDokument>())
    const selectAll = ref(false)

    function checkIfAllDocsSelected() {
      if (selectedDocs.value.length == folder.value?.dokumente.length) {
        selectAll.value = true
      }
      else {
        selectAll.value = false
      }
    }

    function selectAllDocs() {
      if (selectAll.value && folder.value != null && folder.value.dokumente != null) {
        selectedDocs.value = folder.value.dokumente
      }
      else {
        selectedDocs.value = new Array<ArchivDokument>();
      }

    }

    const privateEmail = ref(personaldaten.value.email)

    //#region Download as Zip
    const getDocsLoading = computed(() => store.getters.status.getDocsLoading)
    const getDocsErrorMsg = computed(() => store.getters.status.getDocsErrorMsg)
    const zipDownload = computed(() => store.getters.zipDownload)

    const getDocsAsZipModalRef = ref();
    function closeGetDocsAsZipModal() { Modal.getInstance(getDocsAsZipModalRef.value)?.hide(); }
    function showGetDocsAsZipModal() {
      if (getDocsAsZipModalRef.value) {
        new Modal(getDocsAsZipModalRef.value).show();
      }
    }

    function getCurrentDateAsString() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so we add 1
      const day = today.getDate().toString().padStart(2, '0');
      
      return `${year}${month}${day}`;
  }

    function downloadSelectedDocs() {
      const docs = new Array<ArchivDocRequest>();
      for (var doc of selectedDocs.value) {
        docs.push(new ArchivDocRequest(doc.componente.filename, doc.componente.compid, doc.docid, personaldaten.value.archivmandant, personaldaten.value.archivname))
      }
      const data = new MultipleDocsRequest(docs, store.getters.archivUser.token, privateEmail.value);
  
      store.dispatch(PersonalActionTypes.GetMultipleDocsAsZip, data)
      .then(() => {
        if (getDocsErrorMsg.value == "") {
          // Create a temporary URL object for the blob
          const url = window.URL.createObjectURL(zipDownload.value);

          let today = getCurrentDateAsString()
          let filename = "dokumente-" + today + ".zip"
          if (folder.value != null && language.value == "en_GB" && folder.value.foldertitleEn != "") {
            filename = folder.value?.foldertitleEn + "-" + today +  ".zip"
          } 
          else if (folder.value != null && folder.value.foldertitleDe != "") {
            filename = folder.value?.foldertitleDe + "-" + today +  ".zip"
          }

          // Create a temporary anchor element
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // specify the filename you want the user to download it as

          // Append the anchor to the document body
          document.body.appendChild(a);

          // Programmatically trigger a click event on the anchor
          a.click();

          // Cleanup: remove the anchor and revoke the URL object to release memory
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          selectedDocs.value = new Array<ArchivDokument>();
          selectAll.value = false;
          closeGetDocsAsZipModal();
        }
      })
    }
    //#endregion

    // #region SENDEMAIL
    const emailLoading = computed(() => store.getters.status.sendEmailLoading)
    const emailErrorMsg = computed(() => store.getters.status.sendEmailErrorMsg)

    const sendMultipleDocsEmailModalRef = ref();
    function closeEmailModal() { Modal.getInstance(sendMultipleDocsEmailModalRef.value)?.hide(); }
    function showEmailModal() {
      if (sendMultipleDocsEmailModalRef.value) {
        new Modal(sendMultipleDocsEmailModalRef.value).show();
      }
    }

    function sendSelectedDocs() {
      const docs = new Array<ArchivDocRequest>();
      for (var doc of selectedDocs.value) {
        docs.push(new ArchivDocRequest(doc.componente.filename, doc.componente.compid, doc.docid, personaldaten.value.archivmandant, personaldaten.value.archivname))
      }
      const data = new MultipleDocsRequest(docs, store.getters.archivUser.token, privateEmail.value);
  
      store.dispatch(PersonalActionTypes.SendMultipleDocsAsMail, data)
      .then(() => {
        if (emailErrorMsg.value == "") {
          selectedDocs.value = new Array<ArchivDokument>();
          selectAll.value = false;
          closeEmailModal();
        }
      })
    }
    //#endregion

    //#region Print Multiple Docs
    function printSelectedDocs() {
      console.log("printSelectedDocs");
    }
    //#endregion

    
    return {
      store,
      displayPage,
      page,
      filter,
      searchString,
      folderData,
      user,
      archivUser,
      loading,
      language,
      oldFolderid,
      folder,
      foldertitel,
      sorteddocs,
      // dokumentarten,
      // name,

      sortByValue,
      sortBy,

      terminalversion,
      selectedDocs,
      selectAll,
      checkIfAllDocsSelected,
      selectAllDocs,
      downloadSelectedDocs,

      privateEmail,

      getDocsLoading,
      getDocsErrorMsg,
      zipDownload,
      getDocsAsZipModalRef,
      closeGetDocsAsZipModal,
      showGetDocsAsZipModal,

      emailLoading,
      emailErrorMsg,
      sendMultipleDocsEmailModalRef,
      closeEmailModal,
      showEmailModal,
      sendSelectedDocs,

      printSelectedDocs,
    }
  },

  methods: {
    getFolderDocuments () {
      if (this.folder && (this.folder?.dokumente == null || this.folder?.dokumente?.length == 0) && !this.loading) {
        this.page = 1;
        this.displayPage = 1;
        this.searchString = "";

        const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), this.page, PAGESIZE)
        this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
      }
    },

    searchFolderDocuments () {
      if (this.folder) {
        if (this.searchString.trim() == "") {
          this.page = 1;
          this.displayPage = 1;
          const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), this.page, PAGESIZE)
          this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
        }
        else {
          const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), null, null)
          this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
        }
      }
    },

    loadNextPage() {
      if (this.folder && (this.page == null || (this.page == 1 && this.folder.dokumente.length > PAGESIZE))) {
        this.page = Math.trunc(this.folder.dokumente.length / PAGESIZE)
      }

      this.page++;

      if (this.folder) {
        const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), this.page, PAGESIZE)
        this.store.dispatch(ArchivActionTypes.LoadFolderDocsPage, request)
      }
    },

    goToPrevPage() {
      if (this.displayPage > 1) {
        this.selectedDocs = new Array<ArchivDokument>();
        this.selectAll = false
        this.displayPage--;
      }
    },

    goToNextPage() {
      if (!this.folderData?.allDocsLoaded && this.page == this.displayPage) {
        this.loadNextPage();
      }
      this.displayPage++;
      this.selectAll = false
      this.selectedDocs = new Array<ArchivDokument>();
    },
    
    updateList () {
      if (this.folder && !this.loading) {
        this.filter = "";
        this.selectedDocs = new Array<ArchivDokument>();
        this.selectAll = false
        this.displayPage = 1;

        // if (this.folderData != null) {
        //   this.page = this.folderData.page != null ? this.folderData.page : 1;
        //   this.searchString = this.folderData.searchString;
        // }

        const request = new GetFolderDocsRequest(this.archivUser.token, this.folder.id, this.searchString.trim(), 1, this.page * PAGESIZE)
        this.store.dispatch(ArchivActionTypes.GetFolderDocs, request)
      }
    },
  },

  mounted () {
    // this.name = decodeURIComponent(this.$route.params['foldertitel'] as string);

    if (this.folderData != null) {
      this.page = this.folderData.page != null ? this.folderData.page : 1;
      this.searchString = this.folderData.searchString;
    }

    this.getFolderDocuments();

    const searchInput = document.getElementById("searchInput") as HTMLInputElement
    searchInput?.addEventListener("search", () => {
      if (searchInput.value === '') {
        this.searchFolderDocuments();
      }
    })
  },
  
  beforeUpdate () {
    if (this.oldFolderid != this.folderid) {
      this.oldFolderid = this.folderid
      this.filter = "";
      this.page = 1;
      this.displayPage = 1;
      this.searchString = "";

      if (this.folderData != null) {
        this.page = this.folderData.page != null ? this.folderData.page : 1;
        this.searchString = this.folderData.searchString;
      }

      this.getFolderDocuments();
    }
  }
})
</script>

<style lang="scss">
.w-40 {
  width: 40% !important;
}

.spacer-height {
  height: 45px;
}

.rotate {
  -moz-transition: all .35s ease;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}
.rotate.down {
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>