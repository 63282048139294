<template>
  <div v-if="warningZone" class="alert alert-primary m-4" role="alert">
    Sind Sie noch da? Sie werden in Kürze automatisch abgemeldet...
  </div>

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { useRouter } from 'vue-router';


import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

export default defineComponent({
  name: "AutoLogut",

  setup () {
    const store = useStore();
    const router = useRouter();

    const warningTimer = ref<ReturnType<typeof setTimeout>>()
    const logoutTimer = ref<ReturnType<typeof setTimeout>>()

    return {
      store,
      router,
      warningTimer,
      logoutTimer
    }
  },

  data () {
    return {
      events:  ['click', 'mousemove', 'scroll', 'keypress', 'load'],

      // warningTimer: 0,
      // logoutTimer: 0,
      warningZone: false
    }
  },

  mounted () {
    if (this.store.getters.user.token != "") {
      this.events.forEach(event => {
        window.addEventListener(event, this.resetTimer);
      });
      
      this.setTimers();
    }
  },

  methods: {
    setTimers () {
      this.warningTimer = setTimeout(this.warningMessage, 4 * 60 * 1000);
      this.logoutTimer = setTimeout(this.logout, 5 * 60 * 1000);

      this.warningZone = false;
    },

    warningMessage () {
      this.warningZone = true;
    },

    logout() {
      this.store.dispatch(PersonalActionTypes.Logout, undefined)
      .then(() => {
        this.router.replace({name: "Logout"})
      })
    },

    resetTimer () {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);

      this.warningZone = false
      this.setTimers();
    }
  }
})
</script>

<style>

</style>