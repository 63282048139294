import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import NachrichtApiService from "@/services/NachrichtApiService";
import { Actions as PersonalActions, ActionTypes as PersonalActionTypes} from "../Personal/actions"

import { RootState } from '@/store';

import { State } from "./state";

import { PostfachNachricht, PostfachNachrichtUpdateStatus } from "@/models/Nachrichten/NachrichtenModels";

export enum ActionTypes {
  ClearNachrichtenState = "CLEARNACHRICHTENSTATE",
  GetPostfachNachrichten = "GETPOSTFACHNACHRICHTEN",
  SetPostfachNachrichtGelesen = "SETPOSTFACHNACHRICHTGELESEN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.ClearNachrichtenState](context: ActionArguments): void
  [ActionTypes.GetPostfachNachrichten](context: ActionArguments): Promise<void>
  [ActionTypes.SetPostfachNachrichtGelesen](context: ActionArguments, id: number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.ClearNachrichtenState]({commit}) {
    commit(MutationTypes.ClearNachrichtenState, undefined);
  },

  async [ActionTypes.GetPostfachNachrichten]({commit, dispatch, rootGetters}): Promise<void> {
    const status = rootGetters.status;

    status.nachrichtenLoading = true
    dispatch(PersonalActionTypes.SetStatus, status);

    return NachrichtApiService.getBenutzerNachrichten()
      .then(res => {
        status.nachrichtenLoading = false
        dispatch(PersonalActionTypes.SetStatus, status);

        const nachrichten: Array<PostfachNachricht> = res.data;
        const posteingang: Array<PostfachNachricht> = nachrichten.filter(p => p.postfachartid == 2).sort((a, b) => {
          if (a.adate < b.adate) return -1
          else if (a.adate > b.adate) return 1
          else return 0
        });

        const postfachGesendet: Array<PostfachNachricht> = nachrichten.filter(p => p.postfachartid == 1).sort((a, b) => {
          if (a.adate < b.adate) return -1
          else if (a.adate > b.adate) return 1
          else return 0
        });;

        if (posteingang !== undefined && posteingang.length > 0) {
          commit(MutationTypes.PostfachPosteingangNachrichtSuccess, posteingang);
          sessionStorage.setItem('postfachposteingang', JSON.stringify(posteingang));
        }
        if (postfachGesendet !== undefined && postfachGesendet.length > 0) {
          commit(MutationTypes.PostfachGesendetNachrichtSuccess, postfachGesendet);
          sessionStorage.setItem('postfachgesendet', JSON.stringify(postfachGesendet));
        }
        // if (posteingang.filter(p => p.status == 0).length > 0) {
        //   router.push("/postfach")
        // }
      })
      .catch(error => {
        status.nachrichtenLoading = false
        // status.nachrichtenErrorMsg = error.response.data.message
        status.nachrichtenErrorMsg = "Nachrichten konnten nicht geladen werden."
        dispatch(PersonalActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.SetPostfachNachrichtGelesen]({commit}, id: number) {
    const data = new PostfachNachrichtUpdateStatus(id, 2);

    commit(MutationTypes.SetPostfachNachrichtStatus, data)
    await NachrichtApiService.changePostfachNachrichtStatus(data);
  },
}