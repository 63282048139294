<template>
  <div class="mb-4">
    <h1 v-if="language == 'en_GB'" class="d-inline fs-4">Messages</h1>
    <h1 v-else class="d-inline fs-4">Postfach</h1>
  </div>

  <LoadingSpinner v-if="loading" />

  <!-- <div id="postfachAccordion" class="accordion shadow"> -->
  <div id="postfachAccordion">
    <PostfachNachricht v-for="nachricht in posteingang" :key="nachricht.id" :nachricht="nachricht" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useStore } from '@/store'
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import PostfachNachricht from "@/components/Postfach/PostfachNachricht.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue"


export default defineComponent({
  name: "Postfach",
  components: {
    PostfachNachricht,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const loading = computed(() => store.getters.status.nachrichtenLoading)
    const language = computed(() => store.getters.language)

    const posteingang = computed(() => store.getters.postfachPosteingang)

    const postfachLastUpdate = computed(() => store.getters.postfachLastUpdate)
    const postfachLastUpdateInterval = computed(() => store.getters.postfachUpdateInterval)

    return {
      store,
      loading,
      language,
      posteingang,
      postfachLastUpdate,
      postfachLastUpdateInterval,
    }
  },
  
  mounted () {
    const now = new Date();

    if (now.getTime() - new Date(this.postfachLastUpdate).getTime() > this.postfachLastUpdateInterval) {
      this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
    }
  }
});
</script>

<style scoped>
.unread {
  background: #f9d5201c;
  font-weight: bold;
}

#postfachAccordion {
  border-radius: 5px;
}
.accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
}
</style>