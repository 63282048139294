<template>
  <nav class="d-flex flex-column flex-shrink-0 p-3 bg-white shadow" :class="sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'">
    <span class="d-flex justify-content-between align-items-center mb-3">
      <router-link v-if="!sidebarCollapsed" to="/" class="d-flex align-items-center mb-md-0 me-md-auto text-dark text-decoration-none">
        <!-- <font-awesome-icon icon="users-line" class="me-3 fa-2x" /> -->
        <img src="@/assets/icons/personalakte.png" class="me-3" width="40">
        <span class="fs-4">Personal Portal</span>
      </router-link>

      <!-- <button class="btn text-start px-0 mb-3" @click="toggleSidebarRechts()"><font-awesome-icon icon="bars" /></button> -->
      <button class="btn text-start px-0 my-auto" @click="toggleSidebar()"><font-awesome-icon icon="bars" /></button>
    </span>

    <hr v-if="!sidebarCollapsed">

    <select v-if="!sidebarCollapsed" v-model="language" name="language-select" class="form-select mb-3" aria-label="Sprache wählen" @change="changeLanguage()">
      <option value="de_DE">Deutsch</option>
      <option value="en_GB">Englisch</option>
    </select>

    <ul v-if="language == 'en_GB' && !sidebarCollapsed" class="nav nav-pills flex-column mb-auto" :class="{ 'fs-4' : terminalversion}">
      <li class="nav-item mb-3">
        <router-link to="/" class="nav-link text-dark" active-class="active">
          <font-awesome-icon icon="user" class="me-3 fa-lg" />Personnel data
        </router-link>
      </li>

      <li class="nav-item mb-3" v-for="(group, name) in foldergroups">
        <button
        class="btn btn-toggle w-100 d-flex justify-content-between"
        :class="{ 'fs-4' : terminalversion}"
        data-bs-toggle="collapse"
        :data-bs-target="'#' + name +'-collapse'"
        aria-expanded="true"
        >
          <span v-if="name == '' || name == null || name == 'null'">
            <font-awesome-icon icon="folder-tree" class="me-3 fa-lg" />Personel Files
          </span>
          <span v-else>
            <font-awesome-icon icon="folder-tree" class="me-3 fa-lg" />{{ name }}
          </span>
        </button>

        <div class="collapse show" :id="name + '-collapse'">
          <LoadingSpinner v-if="folderLoading" />
          <div v-else-if="folder.length == 0" class="ms-4">
            <p>- keine Ordner -</p>
          </div>
          <ul v-else class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item" v-for="ord of group" :key="ord.id">
              <router-link
                :to="{name: 'Ordner', params: {folderid: ord.id}}"
                class="nav-link text-dark"
                active-class="active"
              >
                <!-- :to="'/ordner/' +  encodeURIComponent(ord.foldertitle)" -->
                <font-awesome-icon icon="folder-open" class="me-3" />
                <span v-if="ord.foldertitleEn != null">
                  <span v-if="ord.foldertitleEn != ''">
                    <span v-if="ord.foldertitleEn[2] == '.'">
                      {{ ord.foldertitleEn.substring(4) }}
                    </span>
                    <span v-else>
                      {{ord.foldertitleEn}}
                    </span>
                  </span>
                </span>

                <span v-else-if="ord.foldertitleDe != null">
                  <span v-if="ord.foldertitleDe != ''">
                    <span v-if="ord.foldertitleDe[2] == '.'">
                      {{ ord.foldertitleDe.substring(4) }}
                    </span>
                    <span v-else>
                      {{ord.foldertitleDe}}
                    </span>
                  </span>
                </span>
                
                <span v-else>
                  <span v-if="ord.foldertitle[2] == '.'">
                    {{ ord.foldertitle.substring(4) }}
                  </span>
                  <span v-else>
                    {{ord.foldertitle}}
                  </span>
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item mb-3">
        <router-link
          to="/vorgaenge"
          class="nav-link text-dark"
          active-class="active"
        >
          <font-awesome-icon icon="clipboard-list" class="me-3 fa-lg" />Workflows
        </router-link>
      </li>

      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex justify-content-between"
          :class="{ 'fs-4' : terminalversion}"
          data-bs-toggle="collapse"
          data-bs-target="#messages-collapse"
          aria-expanded="true"
        >
          <span><font-awesome-icon icon="envelope" class="me-3 fa-lg" />Messages</span>
        </button>
        <div class="collapse show" id="messages-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item">
              <router-link
                to="/postfach"
                class="nav-link text-dark"
                active-class="active"
              >
                <font-awesome-icon icon="envelope" class="me-3 fa-lg" />
                <span :class="{'newMessages' : newMessagesCount > 0}" :data-newMessages="newMessagesCount">Inbox</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/postfach/gesendet" class="nav-link text-dark" active-class="active">
                <span><font-awesome-icon icon="envelope-circle-check" class="me-3 fa-lg" />Sent Messages</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <ul v-else-if="!sidebarCollapsed" class="nav nav-pills flex-column mb-auto" :class="{ 'fs-4' : terminalversion}">
      <li class="nav-item mb-3">
        <router-link to="/" class="nav-link text-dark" active-class="active">
          <font-awesome-icon icon="user" class="me-3 fa-lg" />
          Personaldaten
        </router-link>
      </li>

      <li class="nav-item mb-3" v-for="(group, name) in foldergroups">
        <button
        class="btn btn-toggle w-100 d-flex justify-content-between"
        :class="{ 'fs-4' : terminalversion}"
        data-bs-toggle="collapse"
        :data-bs-target="'#' + name +'-collapse'"
        aria-expanded="true"
        >
          <span v-if="name == '' || name == null || name == 'null'">
            <font-awesome-icon icon="folder-tree" class="me-3 fa-lg" />Personalakte
          </span>
          <span v-else>
            <font-awesome-icon icon="folder-tree" class="me-3 fa-lg" />{{ name }}
          </span>
        </button>

        <div class="collapse show" :id="name + '-collapse'">
          <LoadingSpinner v-if="folderLoading" />
          <div v-else-if="folder.length == 0" class="ms-4">
            <p>- keine Ordner -</p>
          </div>
          <ul v-else class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item" v-for="ord of group" :key="ord.id">
              <router-link
                :to="{name: 'Ordner', params: {folderid: ord.id}}"
                class="nav-link text-dark"
                active-class="active"
              >
                <font-awesome-icon icon="folder-open" class="me-3" />
                <span v-if="ord.foldertitleDe != null">
                  <span v-if="ord.foldertitleDe != ''">
                    <span v-if="ord.foldertitleDe[2] == '.'">
                      {{ ord.foldertitleDe.substring(4) }}
                    </span>
                    <span v-else>
                      {{ord.foldertitleDe}}
                    </span>
                  </span>
                </span>
                
                <span v-else>
                  <span v-if="ord.foldertitle[2] == '.'">
                    {{ ord.foldertitle.substring(4) }}
                  </span>
                  <span v-else>
                    {{ord.foldertitle}}
                  </span>
                </span>
              </router-link>
            </li>

          </ul>
        </div>
      </li>

      <!-- {{ foldergroups }} -->

      <!-- <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#dokumente-collapse"
          aria-expanded="true"
        >
          <span>
            <font-awesome-icon icon="file" class="me-3 fa-lg" />Dokumente
          </span>
        </button>
        <div class="collapse show" id="dokumente-collapse">
          <LoadingSpinner v-if="dokumentartenLoading" />
          <div v-else-if="dokumentarten.length == 0" class="ms-4">
            <p>- keine Dokumente -</p>
          </div>
          <ul v-else class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item" v-for="doc of dokumentarten" :key="doc.id">
              <router-link
                :to="'/dokumente/' +  encodeURIComponent(doc.titel)"
                class="nav-link text-dark"
                active-class="active"
              >
                <font-awesome-icon icon="folder-open" class="me-3" />
                {{doc.titel}}
              </router-link>
            </li>
          </ul>
        </div>
      </li> -->

      <li class="nav-item mb-3">
        <router-link
          to="/vorgaenge"
          class="nav-link text-dark"
          active-class="active"
        >
          <font-awesome-icon icon="clipboard-list" class="me-3 fa-lg" />
          Vorgänge
        </router-link>
      </li>

      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex justify-content-between align-content-center"
          :class="{ 'fs-4' : terminalversion}"
          data-bs-toggle="collapse"
          data-bs-target="#postfach-collapse"
          aria-expanded="true"
        >
          <span>
            <font-awesome-icon icon="envelope" class="me-3 fa-lg" />Postfach
          </span>
        </button>
        <div class="collapse show" id="postfach-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item">
              <router-link to="/postfach" class="nav-link text-dark" active-class="active">
                <font-awesome-icon icon="envelope" class="me-3 fa-lg" />
                <span :class="{'newMessages' : newMessagesCount > 0}" :data-newMessages="newMessagesCount">Posteingang</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/postfach/gesendet" class="nav-link text-dark" active-class="active">
                <span><font-awesome-icon icon="envelope-circle-check" class="me-3 fa-lg" />Gesendete Nachrichten</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <hr v-if="!sidebarCollapsed">
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";

import { GetProfilbildRequestData, Archivdata } from "@/models/Personal/PersonalModels"

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import { Folder } from "@/models/Archiv/ArchivModels";


export default defineComponent({
  name: "Sidebar",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const language = ref(store.getters.language);
    const newMessagesCount = computed(() => store.getters.postfachPosteingang.filter(p => p.status == 1 || p.status == 0).length);
    const dokumentarten = computed(() => store.getters.dokumentarten);

    const folder = computed(() => store.getters.folder);
    const foldergroups = computed(() => {
      const groups = store.getters.folder.reduce((groups: {[key: string]: Folder[]}, item) => {
        let group = new Array<Folder>();
        
        if (language.value == "de_DE" && item.foldermenuDe != "") {
          group = (groups[item.foldermenuDe] || []);
          group.push(item);
          groups[item.foldermenuDe] = group;
        }
        else if (language.value == "en_GB" && item.foldermenuEn != "") {
          group = (groups[item.foldermenuEn] || []);
          group.push(item);
          groups[item.foldermenuEn] = group;
        }
        else {
          group = (groups[item.foldermenu] || []);
          group.push(item);
          groups[item.foldermenu] = group;
        }

        return groups;
      }, {});

      return groups
    })
    const folderLoading = computed(() => store.getters.status.archivFolderLoading)
    const dokumentartenLoading = computed(() => store.getters.status.archivDokumentartenLoading)
    const personaldaten = computed(() => store.getters.personaldaten)
    const archivdaten = computed(() => store.getters.archivdaten)
    const archivUser = computed(() => store.getters.archivUser)
    const dokumentartenLastUpdate = computed(() => store.getters.dokumentartenLastUpdate)
    const dokumentartenLastUpdateInterval = computed(() => store.getters.dokumentartenUpdateInterval)
    const postfachLastUpdate = computed(() => store.getters.postfachLastUpdate)
    const postfachLastUpdateInterval = computed(() => store.getters.postfachUpdateInterval)
    const folderLastUpdate = computed(() => store.getters.folderLastUpdate)
    const folderLastUpdateInterval = computed(() => store.getters.folderUpdateInterval)
    const terminalversion = computed(() => store.getters.terminalVersion)
    const status = computed(() => store.getters.status)

    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    return {
      store,
      language,
      newMessagesCount,
      dokumentarten,
      folder,
      foldergroups,
      folderLoading,
      dokumentartenLoading,
      personaldaten,
      archivdaten,
      archivUser,
      dokumentartenLastUpdate,
      dokumentartenLastUpdateInterval,
      postfachLastUpdate,
      postfachLastUpdateInterval,
      folderLastUpdate,
      folderLastUpdateInterval,
      terminalversion,
      status,
      sidebarCollapsed
    }
  },

  methods: {
    changeLanguage () {
      this.store.dispatch(CommonActionTypes.SetLanguage, this.language)
    },

    toggleSidebar () {
      this.store.dispatch(PersonalActionTypes.ToggleSidebar, undefined);
    },
  },

  mounted () {
    const now = new Date();

    if (this.archivUser == null || this.archivUser.token == "") {
      this.store.dispatch(ArchivActionTypes.ArchivLogin, undefined)
      .then(() => {

        if (this.personaldaten.guid == "") {
          this.store.dispatch(PersonalActionTypes.GetArchivdaten, undefined)
            .then(() => {
              const archivname = this.archivdaten != null ? this.archivdaten.archivname : ""
              const archivmandant = this.archivdaten != null ? this.archivdaten.archivmandant : ""
            
              const data = new GetProfilbildRequestData(archivname, archivmandant, this.archivUser.token);
              this.store.dispatch(PersonalActionTypes.GetProfilbild, data)
            })
        }
        else {
          const data = new GetProfilbildRequestData(this.personaldaten.archivname, this.personaldaten.archivmandant, this.archivUser.token);
          this.store.dispatch(PersonalActionTypes.GetProfilbild, data)
          // this.store.dispatch(ArchivActionTypes.GetDokumentarten, this.archivUser.token)
        }
      })
    }

    if (now.getTime() - new Date(this.postfachLastUpdate).getTime() > this.postfachLastUpdateInterval) {
      this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
    }
    
    if (now.getTime() - new Date(this.folderLastUpdate).getTime() > this.folderLastUpdateInterval) {
      this.store.dispatch(ArchivActionTypes.GetMitarbeiterFolder, undefined)
    }
  }
});
</script>

<style lang="scss" scoped>
.sidebar-notcollapsed {
  width: 342px;
  transition: width .3s ease-in-out;
}

.sidebar-collapsed {
  width: 40px;
  transition: width .3s ease-in-out;
}

.newMessages {
  position: relative;
}

.newMessages::before {
  content: attr(data-newMessages);
  position: absolute;
  top: -0.75rem;
  right: -1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #dc3545;
  color: black;
}

.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.btn-toggle::after {
  // width: 1.25em;
  // margin: auto 0 auto 5em;
  // float: right !important;
  line-height: 1.3;
  transition: transform .35s ease;
  content: url(../assets/icons/chevron-down.svg)
}

a:hover, .btn-toggle:hover {
  color: #F1A82C !important;
  background: #fff !important;
}

.active {
  color: #F1A82C !important;
  background: #fff !important;
}
</style>
  <!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->