<template>
  <nav class="navbar navbar-expand-md navbar-light pt-3 px-3">
    <div class="container-fluid">
      <ul class="navbar-nav me-auto mb-2 mb-md-0">
        <li v-if="language == 'en_GB' && config != null ? config.showZeiterfassungsButtons : false" class="nav-item my-auto">
          <button v-if="terminalVersion" class="btn btn-outline-primary" @click="closeApplication()">
            <font-awesome-icon icon="stopwatch" class="me-2 fa-lg" />
            Back to Staff Center
          </button>
          
          <button v-if="false" class="btn btn-outline-secondary me-5 " @click="openZeiterfassung()">
            <font-awesome-icon icon="stopwatch" class="me-2 fa-lg" />
            Staff Center
          </button>
        </li>

        <li v-else-if="config != null ? config.showZeiterfassungsButtons : false" class="nav-item my-auto">
          <button v-if="terminalVersion" class="btn btn-outline-primary" @click="closeApplication()">
            <font-awesome-icon icon="stopwatch" class="me-2 fa-lg" />
            Zurück zur Zeiterfassung
          </button>
          
          <button v-if="false" class="btn btn-outline-secondary me-5 " @click="openZeiterfassung()">
            <font-awesome-icon icon="stopwatch" class="me-2 fa-lg" />
            Zeiterfassung
          </button>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto mb-2 mb-md-0">
        <li v-if="!terminalVersion && language == 'en_GB'" class="nav-item dropdown my-auto ms-5">
          <img v-if="profilbild != null && profilbild.filecontentAsBase64 != ''" :src="'data:image/bmp;base64, ' + profilbild.filecontentAsBase64" alt="Profilbild" class="avatar me-2" />
          <img v-else src="@/assets/img/profilbild.png" alt="Profil Bild Rainer Zufall" class="avatar me-2" />

          <a
            class="nav-link dropdown-toggle d-inline pe-0"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><span v-if="windowSize">{{user.benutzer}}</span></a
          >
          <ul class="dropdown-menu m-0">
            <li>
              <button class="dropdown-item" @click="logout()">
                <font-awesome-icon
                  icon="arrow-right-from-bracket"
                  class="me-3"
                />
                Logout
              </button>
            </li>
            <li v-if="azureLogin">
              <button class="dropdown-item" @click="logoutMicrosoft()">
                <font-awesome-icon
                  :icon="['fab', 'windows']"
                  class="me-3"
                />
                Microsoft Logout
              </button>
            </li>
          </ul>
        </li>

        <li v-else-if="!terminalVersion" class="nav-item dropdown my-auto ms-5">
          <img v-if="profilbild != null && profilbild.filecontentAsBase64 != ''" :src="'data:image/bmp;base64, ' + profilbild.filecontentAsBase64" alt="Profilbild" class="avatar me-2" />
          <img v-else src="@/assets/img/profilbild.png" alt="Profil Bild" class="avatar me-2" />
  
          <a
            class="nav-link dropdown-toggle d-inline pe-0"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><span v-if="windowSize">{{user.benutzer}}</span></a
          >
          <ul class="dropdown-menu m-0">
            <li>
              <button class="dropdown-item" @click="logout">
                <font-awesome-icon
                  icon="arrow-right-from-bracket"
                  class="me-3"
                />
                Abmelden
              </button>
            </li>
            <li v-if="azureLogin">
              <button class="dropdown-item" @click="logoutMicrosoft()">
                <font-awesome-icon
                  :icon="['fab', 'windows']"
                  class="me-3"
                />
                Von Microsoft Abmelden
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
// import appsettings from '../../public/appsettings.json'
import { computed, defineComponent, ref } from "vue";

import { useStore } from '@/store'
import { useRouter } from 'vue-router';
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';



export default defineComponent({
  name: "Navbar",

  setup () {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.user);
    const profilbild = computed(() => store.getters.profilbild);
    const language = computed(() => store.getters.language);
    
    // const prevUrl = store.getters.prevUrl;
    const terminalVersion = computed(() => store.getters.terminalVersion);
    const azureLogin = computed(() => store.getters.status.azureLogin);
    const azureUsername = computed(() => store.getters.azureUsername);
    const msalConfig = computed(() => store.getters.msalConfig);
    const msalInstance = msalConfig.value != null ? new PublicClientApplication(msalConfig.value) : null

    const config = ref<any>()

    const windowSize = computed(() => window.screen.availWidth > 500)

    return {
      store,
      router,
      config,
      user,
      profilbild,
      language,
      terminalVersion,
      azureLogin,
      azureUsername,
      msalConfig,
      msalInstance,
      windowSize
    }
  },

  methods: {
    async logout() {
      // if (this.azureLogin) {
      //   this.signOut()
      //   .then(() => {
      //     this.store.dispatch(PersonalActionTypes.Logout, undefined);
      //     location.reload();
      //   })
      // }
      // else {
        await this.store.dispatch(PersonalActionTypes.Logout, undefined)
        this.router.replace({name: "Logout"})
        // location.reload();
      // }
    },

    logoutMicrosoft() {
      this.signOut()
        .then(() => {
          this.logout()
        })
    },

    closeApplication () {
      window.close();
      // window.open("https://ases.phoenixgroup.eu:8043/SES/applications/sc", "_self")
      // window.open("https://ases.phoenixgroup.eu:8043/SES/html?ClientNo=92", "_self")
      // https://ases.phoenixgroup.eu:8043/SES/applications/sc

      // if (this.azureLogin) {
      //   this.signOut()
      //   .then(() => {
      //     this.store.dispatch(PersonalActionTypes.Logout, undefined);
      //     window.close();

      //   })
      // }
      // else {
        // this.store.dispatch(PersonalActionTypes.Logout, undefined)
        // .then(() => {
        //   window.close();
        // })
      // }
    },

    openZeiterfassung () {
      window.open("https://ases.phoenixgroup.eu:8043/SES/html?security.sso=true", "_blank");
    },

    signOut(): Promise<void> {

      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */

      if (this.msalConfig != null && this.msalInstance != null) {

        const logoutRequest = {
          account: this.msalInstance.getAccountByUsername(this.azureUsername),
          // postLogoutRedirectUri: this.msalConfig.auth.redirectUri,
          // mainWindowRedirectUri: this.msalConfig.auth.redirectUri
        };
        
        return this.msalInstance.logoutPopup(logoutRequest);
      }
      else {
        return new Promise<void>((res, rej) => {
          res();
        })
      }
    },

    async loadAppsettings () {
      const appsettings = await fetch('/appsettings.json')
      this.config = await appsettings.json()
    },
  },

  mounted () {
    this.loadAppsettings()
  },

  beforeMount () {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      window.location.href = window.location.href;
      // location.reload();
    }
  }
})
</script>

<style lang="scss" scoped>
img.avatar {
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #f1a82c;
  height: 45px;
  width: 45px;
}
</style>