<template>
  <!-- Name und Adresse ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>

  <BankverbindungEditModalEN ref="bankverbindungModalENRef" />

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BankverbindungEditModalEN from "../Modals/BankverbindungEditModal_EN.vue";

export default defineComponent ({
  name: "BankverbindungEditProfil",
  components: {
    BankverbindungEditModalEN
  },

  setup () {
    const bankverbindungModalENRef = ref();

    function showModal() {
      bankverbindungModalENRef.value?.showModal()
    }

    return {
      bankverbindungModalENRef,
      showModal
    }
  },
})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>