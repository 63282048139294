import { Status } from "@/models/Personal/PersonalModels"
import { PostfachNachricht, PostfachNachrichtUpdateStatus } from "@/models/Nachrichten/NachrichtenModels"
import { MutationTree } from "vuex"
import { State } from "./state"

export enum MutationTypes {
  ClearNachrichtenState = "CLEARNACHRICHTENSTATE",
  PostfachPosteingangNachrichtSuccess = "POSTFACHPOSTEINGGANGNACHRICHTSUCCESS",
  PostfachGesendetNachrichtSuccess = "POSTFACHPOSTAUSGGANGNACHRICHTSUCCESS",
  SetPostfachNachrichtStatus = "SETPOSTFACHNACHRICHTSTATUS",
  SetAccessToken = "SETACCESSTOKEN"
}

export type Mutations = {
  [MutationTypes.ClearNachrichtenState](state: State): void
  [MutationTypes.PostfachPosteingangNachrichtSuccess](state: State, postfachnachrichten: Array<PostfachNachricht>): void
  [MutationTypes.PostfachGesendetNachrichtSuccess](state: State, postfachnachrichten: Array<PostfachNachricht>): void
  [MutationTypes.SetPostfachNachrichtStatus](state: State, data: PostfachNachrichtUpdateStatus): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ClearNachrichtenState](state) {
    state.postfachPosteingang = new Array<PostfachNachricht>();
    state.postfachGesendet = new Array<PostfachNachricht>();
    state.postfachLastUpdate = new Date(1);
  },

  [MutationTypes.PostfachPosteingangNachrichtSuccess](state, postfachnachrichten) {
    state.postfachLastUpdate = new Date();
    state.postfachPosteingang = postfachnachrichten.reverse();
  },

  [MutationTypes.PostfachGesendetNachrichtSuccess](state, postfachnachrichten) {
    state.postfachGesendet = postfachnachrichten.reverse();
  },

  [MutationTypes.SetPostfachNachrichtStatus](state, data) {
    const index = state.postfachPosteingang.findIndex(p => p.id == data.id);
    if (index != null) {
      state.postfachPosteingang[index].status = data.status
    }
  },
}