export class Status {
  errorMsg: string;
  
  // personalLoggedIn: boolean;
  azureLogin: boolean;
  azureLoginAvailable: boolean;
  personalErrorMsg: string;
  getPersonaldatenLoading: boolean;
  personalLoading: boolean;
  personalProfilbildLoading: boolean;
  personalLoginLoading: boolean;
  personalLogoutLoading: boolean;
  personalEinstellungLoading: boolean;
  personalEinstellungErrorMsg: string;

  archivLoggedIn: boolean;
  archivErrorMsg: string
  archivLoading: boolean;
  archivDokumentartenLoading: boolean;
  archivDokumentartenDocsLoading: boolean;
  archivCompLoading: boolean;
  archivFolderLoading: boolean;
  archivFolderDocsLoading: boolean;

  commonLoading: boolean;
  commonErrorMsg: string;

  nachrichtenLoading: boolean;
  nachrichtenErrorMsg: string;

  vorgangLoading: boolean;
  vorgangErrorMsg: string;

  sendEmailLoading: boolean;
  sendEmailErrorMsg: string;

  getDocsLoading: boolean;
  getDocsErrorMsg: string;

  sidebarCollapsed: boolean;
  sidebarRechtsCollapsed: boolean;

  constructor () {
    this.errorMsg = "";

    // this.personalLoggedIn = false;
    this.azureLogin = false;
    this.azureLoginAvailable = false;
    this.personalErrorMsg = "";
    this.getPersonaldatenLoading = false;
    this.personalLoading = false;
    this.personalProfilbildLoading = false;
    this.personalLoginLoading = false;
    this.personalLogoutLoading = false;
    this.personalEinstellungLoading = false;
    this.personalEinstellungErrorMsg = "";

    this.archivLoggedIn = false;
    this.archivErrorMsg = "";
    this.archivLoading = false;
    this.archivDokumentartenLoading = false;
    this.archivDokumentartenDocsLoading = false;
    this.archivCompLoading = false;
    this.archivFolderLoading = false;
    this.archivFolderDocsLoading = false;

    this.commonLoading = false;
    this.commonErrorMsg = "";

    this.nachrichtenLoading = false;
    this.nachrichtenErrorMsg = "";

    this.vorgangLoading = false;
    this.vorgangErrorMsg = "";

    this.sendEmailLoading = false;
    this.sendEmailErrorMsg = "";

    this.getDocsLoading = false;
    this.getDocsErrorMsg = "";

    this.sidebarCollapsed = false;
    this.sidebarRechtsCollapsed = false;
  }
}

export interface LoginRequest {
  username: string,
  userpassword: string
}

export interface LoginGuidRequest {
  guid: string
}

export interface LoginExtPersnrRequest {
  externepersnr: string
}

export interface AzureConfigEinstellung {
  clientId: string
  authority: string
  redirectUri: string
}

export class LoginAzureRequest {
  idToken: string
  username: string

  constructor (idToken: string, username: string) {
    this.idToken = idToken
    this.username = username
  }
}

export class LogoutRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}

export class RefreshLoginRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}

export interface KontaktinformationenEditDataRequest {
  email: string;
  cryptuserpassword: string;
  notfallname: string;
  tel: string;
  mobil: string;
  speakapemail: string;
  responseEmailAdresse: string;
}

export interface NameUndAdresseEditDataRequest {
  titel: string;
  vorname: string;
  name: string;
  strasse: string;
  // hausnummer: string;
  plz: string;
  ort: string;
  land: string;
  message: string;
  responseEmailAdresse: string;
  postfachartid: number;
  docFile: File | null;
  vorgang: string;
}

export interface BankverbindungEditDataRequest {
  bankname: string;
  iban: string;
  bic: string;
  responseEmailAdresse: string;
  postfachartid: number;
}

export interface KrankenkasseEditDataRequest {
  krankenkasse: string;
  ort: string;
  datum: Date;
  message: string;
  responseEmailAdresse: string;
  postfachartid: number;
}

export class User {
  guid: string
  benutzer: string
  token: string
  refreshToken: string

  constructor() {
    this.guid = "";
    this.benutzer = "";
    this.token = "";
    this.refreshToken = "";
  }
}

export interface Archivdata {
  archivname: string;
  archivmandant: string;
}

export class Personaldaten {
  guid: string;
  name: string;
  vorname: string;
  strasse: string;
  plz: string;
  ort: string;
  land: string;
  tel: string;
  fax: string;
  mobil: string;
  email: string;
  telg: string;
  faxg: string;
  mobilg: string;
  emailg: string;
  mandant: string;
  abrkreis: string;
  persnr: string;
  kostenst: string;
  statusupdate: number;
  statusdelte: number;
  vertnr: number;
  gebdat: Date;
  archivname: string;
  archivmandant: string;
  krankenkasse: string;
  svnummer: string;
  domainusername: string;
  cryptuserpassword: string;
  id: number;
  geschlecht: string;
  information: string;
  text: string;
  idfnummer: string;
  vznummer: string;
  sprache: string;
  landid: string;
  orgeinheit: string;
  username: string;
  titel: string;
  administrator: string;
  krankenkassebetriebnr: string;
  externepersnr: string;
  wochenarbeitsstunden: number;
  unbezahlteueberstunden: number;
  jahresurlaub: number;
  pausezeit: number;
  projektid: number;
  iban: string;
  bic: string;
  bankname: string;
  plz2: string;
  strasse2: string;
  ort2: string;
  rentenbeginn: Date;
  krankenkasseprivat: string;
  adate: Date;
  mdate: Date;
  vdate: Date;
  edate: Date;
  notfallname: string;
  notfalltelefon: string;
  chatadresse: string;
  jobticket: string;

  constructor () {
    this.guid = ""
    this.name = ""
    this.vorname = ""
    this.strasse = ""
    this.plz = ""
    this.ort = ""
    this.land = ""
    this.tel = ""
    this.fax = ""
    this.mobil = ""
    this.email = ""
    this.telg = ""
    this.faxg = ""
    this.mobilg = ""
    this.emailg = ""
    this.mandant = ""
    this.abrkreis = ""
    this.persnr = ""
    this.kostenst = ""
    this.statusupdate = 0
    this.statusdelte = 0
    this.vertnr = 0
    this.gebdat = new Date()
    this.archivname = ""
    this.archivmandant = ""
    this.krankenkasse = ""
    this.svnummer = ""
    this.domainusername = ""
    this.cryptuserpassword = ""
    this.id = 0
    this.geschlecht = ""
    this.information = ""
    this.text = ""
    this.idfnummer = ""
    this.vznummer = ""
    this.sprache = ""
    this.landid = ""
    this.orgeinheit = ""
    this.username = ""
    this.titel = ""
    this.administrator = ""
    this.krankenkassebetriebnr = ""
    this.externepersnr = ""
    this.wochenarbeitsstunden = 0
    this.unbezahlteueberstunden = 0
    this.jahresurlaub = 0
    this.pausezeit = 0
    this.projektid = 0
    this.iban = ""
    this.bic = ""
    this.bankname = ""
    this.plz2 = ""
    this.strasse2 = ""
    this.ort2 = ""
    this.rentenbeginn = new Date()
    this.krankenkasseprivat = ""
    this.adate = new Date()
    this.mdate = new Date()
    this.edate = new Date()
    this.vdate = new Date()
    this.notfallname = ""
    this.notfalltelefon = ""
    this.chatadresse = ""
    this.jobticket = ""
  }
}

export class GetProfilbildRequestData {
  archivname: string;
  archivmandant: string;
  archivtoken: string;

  constructor (archivname: string, archivmandant: string, archivtoken: string) {
    this.archivname = archivname,
    this.archivmandant = archivmandant,
    this.archivtoken = archivtoken
  }
}

export class SendDocAsMailRequest {
  archivname: string;
  archivmandant: string;
  docguid: string;
  compguid: string;
  filename: string;
  archivtoken: string;
  responseEmailAdresse: string;

  constructor (archivmandant: string, archivname: string, docguid: string, compguid: string, filename: string, archivtoken: string, responseEmailAdresse: string) {
    this.archivname = archivname,
    this.archivmandant = archivmandant,
    this.docguid = docguid,
    this.compguid = compguid,
    this.filename = filename,
    this.archivtoken = archivtoken,
    this.responseEmailAdresse = responseEmailAdresse
  }
}

//#region Send multiple docs as Mail
export class ArchivDocRequest {
  filename: string;
  compguid: string;
  docguid: string;
  archivmandant: string;
  archivname: string;

  constructor (filename: string, compguid: string, docguid: string, archivmandant: string, archivname: string) {
    this.filename = filename;
    this.compguid = compguid;
    this.docguid = docguid;
    this.archivmandant = archivmandant;
    this.archivname = archivname;
  }
}

export class MultipleDocsRequest {
  docs: Array<ArchivDocRequest>
  archivtoken: string;
  responseEmailAdresse: string;

  constructor (docs: Array<ArchivDocRequest>, archivtoken: string, responseEmailAdresse: string) {
    this.docs = docs;
    this.archivtoken = archivtoken;
    this.responseEmailAdresse = responseEmailAdresse
  }
}
//#endregion