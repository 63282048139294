<template>
  <!-- <div class="row ordnerDokument" @click="getComponents()" data-bs-toggle="modal" :data-bs-target="'#ordnerDokumentPDFModal' + dokument.id"> -->
  <div class="row ordnerDokument" @click="getComponents()">
    <div class="col-9 my-auto">
      <span>{{dokument.titel}}</span>
    </div>
    <div class="col-2 my-auto">
      <span>{{ddateConverter}}</span>
    </div>
    <div class="col-1 my-auto">
      <button class="btn btn-outline-primary">
        <font-awesome-icon icon="magnifying-glass-plus"/>
      </button>
    </div>
  </div>

  <!-- PDF Preview Modal -->
  <div
    class="modal fade p-0"
    :id="'ordnerDokumentPDFModal' + dokument.id"
    tabindex="-1"
    :aria-labelledby="'ordnerDokumentPDFModal' + dokument.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="ordnerDokumentPDFModalRef"
    >
    <div class="modal-dialog modal-dialog-centerd modal-xl my-0 h-100">
      <div class="modal-content h-100">
        <div class="modal-header py-1">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 class="modal-title my-auto" :id="'ordnerDokumentPDFModalLabel' + dokument.id">
              {{dokument.titel}}
            </h5>
            
            <!-- <button class="btn btn-outline-dark my-auto" @click="showSendEmailModal()">
              <font-awesome-icon icon="envelope" class="me-3"/>
              An private E-Mail senden
            </button> -->
          </div>
          <button
          type="button"
          class="btn-close"
          @click="closePdfModal()"
          ></button>
        </div>

        <div class="modal-body p-0" :id="'ordnerDokumentPDFModalBody' + dokument.id">
          <!-- <div v-if="docloaded"> -->
            <!-- :pdfUrl="pdfUrl" -->
            <!-- :pdfName="dokument.componente.filename"
            :personaldaten="personaldaten" -->
            <PDFViewer 
              ref="pdfViewerRef"
              :docId="dokument.id.toString()"
              :options="pdfViewerOptions"
              @sendDocAsEmail="showSendEmailModal()" />
          <!-- </div> -->
          <div v-if="loading">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Send PDF Email Modal -->
  <div
    class="modal fade p-0"
    :id="'sendPDFEmailModal' + dokument.id"
    tabindex="-1"
    :aria-labelledby="'sendPDFEmailModal' + dokument.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="sendPDFEmailModalRef"
    >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 v-if="language == 'en_GB'" class="modal-title my-auto" :id="'sendPDFEmailModalLabel' + dokument.id">
              Send to private email
            </h5>

            <h5 v-else class="modal-title my-auto" :id="'sendPDFEmailModalLabel' + dokument.id">
              An private E-Mail Adresse senden
            </h5>
          </div>
          <button
          type="button"
          class="btn-close"
          @click="closeSendEmailModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'sendPDFEmailModalBody' + dokument.id">
          <div v-if="language == 'en_GB'">
            <div v-if="emailErrorMsg != null && emailErrorMsg != '' && showError" class="alert alert-danger">
              {{ emailErrorMsg }}
              <br>
              Please try again later.
            </div>
            
            <div v-if="emailloading">
              <LoadingSpinner />
            </div>
            
            <div v-else>
              <p><strong>Attention:</strong> The document will be sent <span class="text-danger">unencrypted</span> to the following email address:</p>
              <div class="my-3">
                <label for="privateEmailInput" class="form-label">Private email address</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" v-model="privateEmail">
              </div>
            </div>
          </div>

          <div v-else>
            <div v-if="emailErrorMsg != null && emailErrorMsg != '' && showError" class="alert alert-danger">
              {{ emailErrorMsg }}
              <br>
              Bitte probieren Sie es später noch einmal.
            </div>
            
            <div v-if="emailloading">
              <LoadingSpinner />
            </div>
            
            <div v-else>
              <p><strong>Achtung:</strong> Das Dokument wird <span class="text-danger">unverschlüsselt</span> an folgende E-Mail Adresse versendet:</p>
              <div class="my-3">
                <label :for="'privateEmailInput-' + dokument.id" class="form-label">Private E-Mail Adresse</label>
                <input type="email" class="form-control" :id="'privateEmailInput-' + dokument.id" v-model="privateEmail">
              </div>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <div v-if="language == 'en_GB'">
            <button type="button" class="btn btn-secondary" @click="closeSendEmailModal()">Abbrechen</button>
            <button type="button" class="btn btn-primary ms-3" @click="sendmail()">E-Mail senden</button>
          </div>

          <div v-else>
            <button type="button" class="btn btn-secondary" @click="closeSendEmailModal()">Abbrechen</button>
            <button type="button" class="btn btn-primary ms-3" @click="sendmail()">E-Mail senden</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, reactive, toRefs, } from "vue";
import { Modal } from 'bootstrap';

import { useStore } from '@/store';

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PDFViewer from "@/components/PDFViewer/PDFViewer.vue";

import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses'
import { SendDocAsMailRequest } from "@/models/Personal/PersonalModels";
import { ArchivDokument, ArchivDonwloadCompData } from "@/models/Archiv/ArchivModels";
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PDFVIEWERDEFAULTSCALE = config.pdfViewerDefaultScale;

export default defineComponent({
  name: "OrdnerDokument",
  components: {
    LoadingSpinner,
    PDFViewer
  },

  props: {
    dokument: {
      type: Object as PropType<ArchivDokument>,
      required: true
    },
    printOption: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const store = useStore();
    const language = computed(() => store.getters.language)
    const terminal = computed(() => store.getters.terminalVersion)

    const loading = computed(() => store.getters.status.archivCompLoading)
    const emailloading = computed(() => store.getters.status.sendEmailLoading)
    const emailErrorMsg = computed(() => store.getters.status.sendEmailErrorMsg)

    const ddateConverter = computed(() => {
      const ddate = new Date(props.dokument.ddate);
      // return ("0" + ddate.getDate()).slice(-2) + "." + ("0" + (ddate.getMonth() + 1)).slice(-2) + "." + ddate.getFullYear() + " " + ("0" + (ddate.getHours() + 1)).slice(-2) + ":" + ("0" + (ddate.getMinutes() + 1)).slice(-2);
      return ("0" + ddate.getDate()).slice(-2) + "." + ("0" + (ddate.getMonth() + 1)).slice(-2) + "." + ddate.getFullYear();
    })
    
    const personaldaten = computed(() => store.getters.personaldaten);

    const state = reactive({
      privateEmail: personaldaten.value.email,
      showError: false
    })

    const archivUser = computed(() => store.getters.archivUser);
    const componente = computed(() => store.getters.componente);

    const pdfViewerOptions = computed(() => new PDFViewerOptions());

    const pdfViewerRef = ref();

    const ordnerDokumentPDFModalRef = ref();
    const closeModal = () => {
      pdfViewerRef.value?.clearViewer()
      Modal.getInstance(ordnerDokumentPDFModalRef.value)?.hide();
    }
    const showModal = async () => {
      if (ordnerDokumentPDFModalRef.value) {
        new Modal(ordnerDokumentPDFModalRef.value).show();
      }
    }

    const sendPDFEmailModalRef = ref();
    const closeEmailModal = () => Modal.getInstance(sendPDFEmailModalRef.value)?.hide();
    const showEmailModal = () => {
      if (sendPDFEmailModalRef.value) {
        new Modal(sendPDFEmailModalRef.value).show();
      }
    }

    return {
      store,
      language,
      loading,
      terminal,
      personaldaten,
      ...toRefs(state),
      archivUser,
      componente,
      ddateConverter,

      ordnerDokumentPDFModalRef,

      closeModal,
      showModal,

      sendPDFEmailModalRef,
      closeEmailModal,
      showEmailModal,
      emailloading,
      emailErrorMsg,

      pdfViewerRef,

      pdfViewerOptions
    }
  },

  // data () {
  //   return {
  //     docloaded: false,
  //     pdfUrl: "",
  //     pdfViewerOptions: new PDFViewerOptions()
  //   }
  // },

  methods: {
    getComponents () {
      this.showModal()

      const data: ArchivDonwloadCompData = {
        archivmandant: this.personaldaten.archivmandant,
        archivname: this.personaldaten.archivname,
        componente: this.dokument.componente
      };
      
      this.store.dispatch(ArchivActionTypes.ArchivDownloadComponente, data)
        .then(() => {
          const pdfUrl = this.base64PDFToBlobUrl(this.componente.file.filecontentAsBase64)
          // 50ms delay to ensure the PDFViewer is mounted
          setTimeout(() => {
            this.pdfViewerRef?.showPDF(pdfUrl, this.dokument.componente.filename, this.personaldaten);
          }, 50);
        });
    },

    base64PDFToBlobUrl(base64: string) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      const blob =  new Blob( [ arr ], { type: 'application/pdf' } );
      const url = URL.createObjectURL( blob );
      return url;
    },

    closePdfModal () {
      this.closeModal();

      this.store.dispatch(ArchivActionTypes.ArchivClearComponentenCache, undefined);
    },

    showSendEmailModal() {
      this.showError = false
      this.showEmailModal()
    },

    closeSendEmailModal() {
      this.privateEmail = this.personaldaten.email
      this.closeEmailModal()
    },

    sendmail () {
      const data: SendDocAsMailRequest = {
        archivmandant: this.personaldaten.archivmandant,
        archivname: this.personaldaten.archivname,
        docguid: this.dokument.componente.docid.trim(),
        compguid: this.dokument.componente.compid.trim(),
        filename: this.dokument.componente.filename.trim(),
        archivtoken: this.archivUser.token,
        responseEmailAdresse: this.privateEmail
      };

      this.store.dispatch(PersonalActionTypes.SendDocAsMail, data)
        .then(() => {
          if (this.emailErrorMsg == null || this.emailErrorMsg == "") {
            this.closeEmailModal()
          }
          else {
            this.showError = true
          }
        });
    }
  },

  mounted () {
    this.pdfViewerOptions.defaultScale = PDFVIEWERDEFAULTSCALE
    this.pdfViewerOptions.showPrintOption = this.printOption
    this.pdfViewerOptions.showSignaturOption = false

    if (this.terminal) {
      this.pdfViewerOptions.showDownloadOption = false
    }
  }
})
</script>

<style scoped>
.ordnerDokument:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.modal {
  height: 100% !important;
  /* overflow: hidden; */
}

/* .modal-body {
  background: darkgray;
} */
</style>