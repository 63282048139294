<template>
    <!-- Kontaktdaten ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Kontaktdaten ändern</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="floppy-disk" />
      </button>
    </div>
  </div>

  <KontaktinformationenEditModal ref="kontaktinformationenModalRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KontaktinformationenEditModal from "../Modals/KontaktinformationenEditModal.vue";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    KontaktinformationenEditModal
  },

  setup () {
    const kontaktinformationenModalRef = ref();
    function showModal() {
      kontaktinformationenModalRef.value?.showModal()
    }

    return {
      kontaktinformationenModalRef,
      showModal
    }
  },
})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>