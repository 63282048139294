export class PDFViewerOptions {
  showPrintOption: boolean
  showDownloadOption: boolean
  showEmailSendenOption: boolean
  showSignaturOption: boolean
  showScalingOption: boolean
  showPagesOption: boolean
  defaultScale: number

  constructor () {
    this.showPrintOption = true
    this.showDownloadOption = true
    this.showEmailSendenOption = true
    this.showSignaturOption = true
    this.showScalingOption = true
    this.showPagesOption = true
    this.defaultScale = 0
  }
}