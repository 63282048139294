import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import { useStore } from '@/store'
import router from "@/router"
import { ActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions";
import { User } from "@/models/Personal/PersonalModels";
import { ErrorResponseData } from "@/models/Common/CommonModels";

let axiosInst: AxiosInstance;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);

  if (config.headers === undefined) {
    config.headers = {};
  }

  const sessionStorageUser = sessionStorage.getItem("user");
  let user: User;

  if (sessionStorageUser) {
    user = JSON.parse(sessionStorageUser);

    if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
  }
  
  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
}

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];
// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const onResponseError = async (error: AxiosError): Promise<any> => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  const originalRequest: AxiosRequestConfig = error.config;
  const store = useStore();

  if (error.response && error.response.status == 401) {
    
    if (!isRefreshing) {
      isRefreshing = true;
      
      // Archivtoken ungültig
      const errorRespData = error.response.data as ErrorResponseData || null;
      if (errorRespData != null && errorRespData.title == "invalid_archiv_token") {
        try {
          await store.dispatch(ArchivActionTypes.ArchivRefreshLogin, undefined)
          const authUser = store.getters.archivUser
          
          if (authUser  != null) {
            const newAccessToken = authUser.token;
            
            if (error.config.headers) {
              error.config.headers['ArchivToken'] = newAccessToken
            }
            
            return axiosInst(originalRequest)
          }
        }
        catch (error) {
          store.dispatch(ActionTypes.Logout, undefined)
          .then(() => {
            router.replace({ name: "Logout" })
            return true
          })
        }
        finally {
          isRefreshing = false;
        }
      }

      // Zelostoken ungültig
      else {
        try {
          await store.dispatch(ActionTypes.RefreshLogin, undefined)
          const newAccessToken = store.getters.user.token;
  
          // Update the request headers with the new access token
          if (error.config.headers) {
            error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
          }
  
          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInst
            .request(config)
            .then((response: AxiosResponse) => resolve(response))
            .catch((err: AxiosError) => reject(err));
          });
  
          // Clear the queue
          refreshAndRetryQueue.length = 0;
  
          // Retry the original request
          return axiosInst(originalRequest)
  
        } catch (refreshError) {
          // Handle token refresh error
          // You can clear all storage and redirect the user to the login page
          store.dispatch(ActionTypes.Logout, undefined)
          .then(() => {
            router.replace({ name: "Logout" })
            return true
          })
        } finally {
          isRefreshing = false;
        }
      }
    }

    // Add the original request to the queue
    return new Promise<any>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }
  
  else if (error.response?.status == 403) {
    const errorRespData = error.response.data as ErrorResponseData || null;
    if (errorRespData != null && errorRespData.detail == "Die Anmeldung ist abgelaufen") {
      store.dispatch(ActionTypes.Logout, undefined)
      .then(() => {
        router.replace({ name: "Logout" })
        return true;
      })
    }
  }
  // if (error.response?.status == 401){
  //   useStore().dispatch(ActionTypes.Logout, undefined);
  //   location.reload();
  // }

  return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
