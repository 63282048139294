<template>
  <!-- Kontaktdaten ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>

  <KontaktinformationenEditModal ref="kontaktinformationenModalRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KontaktinformationenEditModal from "../Modals/KontaktinformationenEditModal.vue";

export default defineComponent ({
  name: "KontakinformationenEdit",
  components: {
    KontaktinformationenEditModal
  },

  setup () {
    const kontaktinformationenModalRef = ref();
    function showModal() {
      kontaktinformationenModalRef.value?.showModal()
    }

    return {
      kontaktinformationenModalRef,
      showModal
    }
  },
})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>