<template>
  <div class="home">
    <div class="mb-4 d-flex justify-content-end">
      <!-- <h1 class="d-inline fs-4">Personalakte</h1> -->

      <button class="btn btn-outline-white btn-sm" @click="updateData()">
        <font-awesome-icon icon="rotate" class="fa-lg"/>
      </button>
    </div>

    <LoadingSpinner v-if="loading" :text="'... Profil wird geladen ...'"/>

    <div id="profil-content" v-else>
      <!-- Person -->
      <div class="p-3 mb-2 bg-white shadow">
        <div class="d-flex justify-content-end">
          <!-- <router-link to="/vorgaenge?edit=name-adresse" class="text-dark"><font-awesome-icon icon="pen-to-square" class="fa-lg"/> </router-link> -->
          <NameUndAdresseEditProfil :index="1" />
        </div>
        
        <div class="row">
          <div class="col-12 col-md-4 mb-3 d-flex align-items-center justify-content-center" id="profilbild-container">
            <div v-if="profilbildLoading" style="width: 188px;">
              <LoadingSpinner />
            </div>

            <img v-else-if="profilbild != null && profilbild.filecontentAsBase64 != ''" :src="'data:image/bmp;base64, ' + profilbild.filecontentAsBase64" alt="Profilbild" class="profilbild me-4"/>
            <img v-else src="@/assets/img/profilbild.png" alt="Profilbild Rainer Zufall" class="profilbild me-4">
          </div>

          <div class="col-12 col-md-8">
            <h2>{{nameTitleconverter}}</h2>

            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <div class="row">
                  <div class="col-6">
                    <p>Mandant</p>
                  </div>
                  <div class="col-6">
                    <p><strong>{{personaldaten.mandant}}</strong></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <p>Abrechnungskreis</p>
                  </div>
                  <div class="col-6">
                    <p><strong>{{personaldaten.abrkreis}}</strong></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <p>Personalnummer</p>
                  </div>
                  <div class="col-6">
                    <p><strong>{{personaldaten.persnr}}</strong></p>
                  </div>
                </div>

              </div>

              <div class="col-12 col-md-6 mb-2">
                <div class="row">
                  <div class="col-6">
                    <p>Geburtsdatum</p>
                  </div>
                  <div class="col-6">
                    <p><strong>{{geburtsdatumConverter}}</strong></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <p>Geschlecht</p>
                  </div>
                  <div class="col-6">
                    <p><strong>{{geschlechtConverter}}</strong></p>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-6">
                    <p></p>
                  </div>
                  <div class="col-6">
                    <p><strong></strong></p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p class="small text-muted mb-0"><strong>{{personaldaten.orgeinheit}}</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Adresse -->
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="p-3 mb-2 bg-white shadow">
            <div class="border-bottom mb-3 d-flex justify-content-between">
              <h3 class="d-inline fs-4">Adresse</h3>
              <!-- <router-link to="/vorgaenge?edit=name-adresse" class="text-dark"><font-awesome-icon icon="pen-to-square" class="fa-lg"/> </router-link> -->
              <NameUndAdresseEditProfil :index="2"/>
            </div>

            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-4">
                    <p>Straße</p>
                  </div>
                  <div class="col">
                    <p><strong>{{personaldaten.strasse}}</strong></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <p>PLZ</p>
                  </div>
                  <div class="col">
                    <p><strong>{{personaldaten.plz}}</strong></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <p>Wohnort</p>
                  </div>
                  <div class="col">
                    <p><strong>{{personaldaten.ort}}</strong></p>
                  </div>
                </div>

              </div>

              <div class="col" v-if="(personaldaten.strasse2 == null || personaldaten.strasse2 == '') && (personaldaten.plz2 == null || personaldaten.plz2 == '') && (personaldaten.ort2 == null || personaldaten.ort2 == '')">
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6" v-if="(personaldaten.strasse2 && personaldaten.strasse2 != '') || (personaldaten.plz2 && personaldaten.plz2 != '') || (personaldaten.ort2 && personaldaten.ort2 != '')">
          <div class="p-3 mb-2 bg-white rounded-1 shadow">
            <div class="row border-bottom mb-3">
              <div class="col">
                <h3 class="fs-4">Zweitwohnsitz</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>Straße</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.strasse2}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>Postleitzahl</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.plz2}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>Wohnort</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.ort2}}</strong></p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Kontaktdaten und Bank -->
      <div class="row">
        <div class="col-12 col-md-6 mb-2">
          <div class="p-3 bg-white shadow">
            <div class="row border-bottom mb-3 ">
              <div class="d-flex justify-content-between">
                <h3 class="d-inline fs-4">Kontaktdaten</h3>
                <!-- <router-link to="/vorgaenge?edit=kontaktdaten" class="text-dark"><font-awesome-icon icon="pen-to-square" class="fa-lg"/> </router-link> -->
                <KontaktinformationenEditProfil />
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>E-Mail</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.email}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>E-Mail Passwort</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.cryptuserpassword}}</strong></p>
              </div>
            </div>            

            <div class="row">
              <div class="col-4">
                <p>Mobil</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.mobil}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>Telefon</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.tel}}</strong></p>
              </div>
            </div>
            
            <div class="row">
              <div class="col-4">
                <p>Speakap E-Mail</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.chatadresse}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>Notfallkontakt</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.notfallname}}</strong></p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 mb-2">
          <div class="p-3 bg-white shadow h-100">
            <div class="row border-bottom mb-3 ">
              <div class="d-flex justify-content-between">
                <h3 class="d-inline fs-4">Bankdaten</h3>
                <!-- <router-link to="/vorgaenge?edit=bankverbindung" class="text-dark"><font-awesome-icon icon="pen-to-square" class="fa-lg"/></router-link> -->
                <BankverbindungEditProfil />
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>Bank</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.bankname}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>IBAN</p>
              </div>
              <div class="col">
                <!-- <p><strong>{{personaldaten.iban}}</strong></p> -->
                <p><strong>{{ibanConverter}}</strong></p>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <p>BIC</p>
              </div>
              <div class="col">
                <p><strong>{{personaldaten.bic}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Vertrag -->
      <div class="shadow-top-bottom">
        <div class="mb-2 p-3 bg-white">
          <div class="border-bottom mb-3">
            <div class="d-flex justify-content-between">
              <h3 class="d-inline fs-4">Stammdaten</h3>
              <!-- <router-link to="/vorgaenge?edit=krankenkasse" class="text-dark"><font-awesome-icon icon="pen-to-square" class="fa-lg"/></router-link> -->
              <KrankenkasseEditProfil />
            </div>
          </div>
            
          <div class="row gx-5">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-4">
                  <p>Konzern Eintritt</p>
                </div>
                <div class="col">
                  <p><strong>{{adateConverter}}</strong></p>
                </div>
              </div>
              
              <div class="row">
                <div class="col-4">
                  <p>Krankenkasse</p>
                </div>
                <div class="col">
                  <p>
                    <strong v-if="personaldaten.krankenkasse != null && personaldaten.krankenkasse != ''">{{personaldaten.krankenkasse}}</strong>
                    <br v-if="personaldaten.krankenkasse != null && personaldaten.krankenkasse != '' && personaldaten.krankenkasseprivat != null && personaldaten.krankenkasseprivat != ''">
                    <strong v-if="personaldaten.krankenkasseprivat != null && personaldaten.krankenkasseprivat != ''">{{personaldaten.krankenkasseprivat}}</strong>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <p>SV Nummer</p>
                </div>
                <div class="col">
                  <p><strong>{{personaldaten.svnummer}}</strong></p>
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-4">
                  <p>Jobticket ID</p>
                </div>
                <div class="col">
                  <p><strong>{{personaldaten.jobticket}}</strong></p>
                </div>
              </div> -->
            </div>

            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-4">
                  <p>Vertrag Ende</p>
                </div>
                <div class="col">
                  <p><strong>{{vdateConverter}}</strong></p>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <p>Gesetzlicher Rentenbeginn</p>
                </div>
                <div class="col">
                  <p><strong>{{rentenbeginnConverter}}</strong></p>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <p>Zeit bis zur Rente</p>
                </div>
                <div class="col">
                  <p><strong>{{rentenbeginnTimerConverter}}</strong></p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div id="context-menu" class="shadow">
    <div class="item" @click="reloadImage"><font-awesome-icon icon="rotate" class="me-3" />Bild neu laden</div>
  </div>

</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { GetProfilbildRequestData } from "@/models/Personal/PersonalModels"
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import NameUndAdresseEditProfil from "@/components/Vorgaenge/Profil/NameUndAdresseEditProfil.vue";
import KontaktinformationenEditProfil from "@/components/Vorgaenge/Profil/KontaktinformationenEditProfil.vue";
import BankverbindungEditProfil from "@/components/Vorgaenge/Profil/BankverbindungEditProfil.vue";
import KrankenkasseEditProfil from "@/components/Vorgaenge/Profil/KrankenkasseEditProfil.vue";

export default defineComponent({
  name: "Profil_DE",
  components: {
    LoadingSpinner,
    NameUndAdresseEditProfil,
    KontaktinformationenEditProfil,
    BankverbindungEditProfil,
    KrankenkasseEditProfil,
  },

  setup () {
    const store = useStore();

    // Computed
    const personaldaten = computed(() => store.getters.personaldaten);
    const archivUser = computed(() => store.getters.archivUser)
    const profilbild = computed(() => store.getters.profilbild);
    const loading = computed(() => store.getters.status.getPersonaldatenLoading);
    const profilbildLoading = computed(() => store.getters.status.personalProfilbildLoading);

    // Converter
    const geburtsdatumConverter = computed(() => {
      if (personaldaten.value.gebdat == null) {
        return ""
      }

      const today: Date = new Date();
      const gebdate: Date = new Date(personaldaten.value.gebdat.toString());

      if (gebdate.getDate() == today.getDate() && gebdate.getMonth() == today.getMonth() && gebdate.getFullYear() == today.getFullYear()) {
        return "";
      }
      

      return ("0" + gebdate.getDate()).slice(-2) +
      "." +
      ("0" + (gebdate.getMonth() + 1)).slice(-2) +
      "." +
      gebdate.getFullYear();
    })
    const adateConverter = computed(() => {
      const today: Date = new Date();
      const adate: Date = new Date(personaldaten.value.adate.toString())

      if (adate.getDate() == today.getDate() && adate.getMonth() == today.getMonth() && adate.getFullYear() == today.getFullYear()) {
        return "";
      }
      

      return ("0" + adate.getDate()).slice(-2) +
      "." +
      ("0" + (adate.getMonth() + 1)).slice(-2) +
      "." +
      adate.getFullYear();
    })

    const vdateConverter = computed(() => {
      if (personaldaten.value.vdate == null) {
        return ""
      }
      
      const vdate: Date = new Date(personaldaten.value.vdate.toString())

      if (vdate.getFullYear() == 1) {
        return "Unbefristet";
      }
      

      return ("0" + vdate.getDate()).slice(-2) +
      "." +
      ("0" + (vdate.getMonth() + 1)).slice(-2) +
      "." +
      vdate.getFullYear();
    })

    const rentenbeginnConverter = computed(() => {
      if (personaldaten.value.rentenbeginn == null) {
        return "";
      }

      const today: Date = new Date();
      const rentenbeginndate: Date = new Date(personaldaten.value.rentenbeginn.toString())

      if (rentenbeginndate.getDate() == today.getDate() && rentenbeginndate.getMonth() == today.getMonth() && rentenbeginndate.getFullYear() == today.getFullYear()) {
        return "";
      }
      

      return ("0" + rentenbeginndate.getDate()).slice(-2) +
      "." +
      ("0" + (rentenbeginndate.getMonth() + 1)).slice(-2) +
      "." +
      rentenbeginndate.getFullYear();
    })
    
    const rentenbeginnTimerConverter = computed(() => {
      if (personaldaten.value.rentenbeginn == null) {
        return "";
      }

      const today: Date = new Date();
      const rentenbeginndate: Date = new Date(personaldaten.value.rentenbeginn.toString())

      if (rentenbeginndate.getDate() == today.getDate() && rentenbeginndate.getMonth() == today.getMonth() && rentenbeginndate.getFullYear() == today.getFullYear()) {
        return "";
      }

      const oneDay = 1000 * 60 * 60 * 24;
      const oneYear = 365
      let diff = Math.floor(rentenbeginndate.getTime() - today.getTime());
      const years = Math.floor(diff / (oneDay * oneYear));
      diff -= years * (oneDay * oneYear);
      const days = Math.floor(diff / oneDay);

      return years + " Jahre & " + days + " Tage";
    })

    const nameTitleconverter = computed(() => {
      if (personaldaten.value.titel == null || personaldaten.value.titel == "") {
        return `${personaldaten.value.vorname} ${personaldaten.value.name}`
      }
      else {
        return `${personaldaten.value.titel} ${personaldaten.value.vorname} ${personaldaten.value.name}`
      }
    })

    const geschlechtConverter = computed(() => {
      const value = personaldaten.value.geschlecht;

      let geschlecht = ""

      switch(value) {
        case "M":
        case "m":
          geschlecht = "Männlich";
          break;
        case "W":
        case "w":
          geschlecht = "Weiblich";
          break;
        default:
          geschlecht = "Unbestimmt"
      }

      return geschlecht;
    })

    const ibanConverter = computed(() => {
      let iban = personaldaten.value.iban
      iban = iban.replace(/\s/g, '');
      return iban.match(/.{1,4}/g)?.join(' ');
    })

    return {
      store,
      personaldaten,
      archivUser,
      profilbild,
      loading,
      profilbildLoading,
      geburtsdatumConverter,
      adateConverter,
      vdateConverter,
      rentenbeginnConverter,
      rentenbeginnTimerConverter,
      nameTitleconverter,
      geschlechtConverter,
      ibanConverter
    }
  },

  methods: {
    updateData () {
      this.store.dispatch(PersonalActionTypes.GetPersonaldaten, undefined)
    },

    reloadImage () {
      const data = new GetProfilbildRequestData(this.personaldaten.archivname, this.personaldaten.archivmandant, this.archivUser.token);
      this.store.dispatch(PersonalActionTypes.GetProfilbild, data)
    },
  },

  mounted () {
    const contextMenu = document.getElementById("context-menu");
    const scope = document.querySelector("body");
    const imgScope = document.getElementById("profilbild-container");
    
    
    imgScope?.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      const { clientX: mouseX, clientY: mouseY } = event;
      
      if (contextMenu != null) {
        contextMenu.style.top = `${mouseY}px`;
        contextMenu.style.left = `${mouseX}px`;
        
        contextMenu.classList.remove("visible");
        
        setTimeout(() => {
          contextMenu.classList.add("visible");
        })
      }
    })
    
    scope?.addEventListener("click", () => {
      if (contextMenu != null) {
        contextMenu.classList.remove("visible");
      }
    });
  }
})
</script>

<style lang="scss" scoped>
img.profilbild {
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #f1a82c;
  height: 164px;
  width: 164px;
}

.shadow-top-bottom {
  box-shadow: 0 .5rem 1rem rgba(black, .15), 0 -1rem 1.5rem rgba(black, .1);
}

#context-menu {
  position: fixed;
  z-index: 10000;
  width: 160px;
  background: #fff;
  border: 1px solid #9da0a4;
  border-radius: 5px;
  transform: scale(0);
  transform-origin: top left;
}

#context-menu.visible {
  transform: scale(1);
  transition: transform 200ms ease-in-out;
}

#context-menu .item {
  padding: 8px 10px;
  font-size: 15px;
  cursor: pointer;
  border-radius: inherit;
}

#context-menu .item:hover {
  color: #f1a82c;
}
</style>
