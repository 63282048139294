<template>
  <!-- Krankenkasse Modal -->
  <div
    class="modal fade"
    id="krankenkasseModal"
    tabindex="-1"
    aria-labelledby="krankenkasseModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="krankenkasseModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="krankenkasseModalLabel">
            Krankenkasse ändern
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" v-if="loading">
          <LoadingSpinner />
        </div>

        <div class="modal-body" v-if="!loading">
          <div class="mb-2">
            <label class="form-label">Krankenkasse</label>
            <input
              type="text"
              class="form-control"
              v-model="krankenkasse"
              />
          </div>
          
          <div class="mb-2">
            <label class="form-label">Ort (optional)</label>
            <input
              type="text"
              class="form-control"
              v-model="ort"
              />
          </div>

          <div class="mb-2">
            <label class="form-label">Neue Krankenkasse gültig ab</label>
            <input
              type="Date"
              class="form-control"
              v-model="datum"
              />
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">Nachricht an die Personalabteilung</label>
            <textarea class="form-control" id="message" rows="4" v-model="message"></textarea>
          </div>

          <hr>

          <p>
            <strong>E-Mail Adresse für die Bestätigung der Änderungen und ggf. Rückfragen der Personalabteilung.</strong>
          </p>

          <div class="mb-3">
            <label class="form-label">E-Mail Adresse für Bestätigung</label>
            <input
              type="text"
              class="form-control"
              v-model="responseEmailAdresse"
            />
          </div>

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            <pre>{{errorMessage}}</pre>
          </div>

        </div>  

        <div class="modal-footer" v-if="!loading">

          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal()"
            >
            Abbrechen
          </button>
          
          <button class="btn btn-primary" @click="saveChanges()">Senden</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { KrankenkasseEditDataRequest } from "@/models/Personal/PersonalModels";

export default defineComponent ({
  name: "KrankenkasseEditModal",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.personalLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => store.getters.personaldaten);

    const state = reactive({
      krankenkasse: personaldaten.value.krankenkasse,
      ort: "",
      datum: (new Date()).toISOString().split('T')[0],
      message: "",
      responseEmailAdresse: personaldaten.value.email,
      postfachartid: 1
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.krankenkasse = personaldaten.value.krankenkasse,
      state.ort = "",
      state.datum = (new Date()).toISOString().split('T')[0],
      state.message = "",
      state.responseEmailAdresse = personaldaten.value.email,
      state.postfachartid = 1
      errorMessage.value = "";
    }

    const krankenkasseModalRef = ref();
    const closeModal = () => {
      resetForm();
      Modal.getInstance(krankenkasseModalRef.value)?.hide();
    }
    const showModal = () => {
    if (krankenkasseModalRef.value) {
      new Modal(krankenkasseModalRef.value).show();
    }
  }

    return {
      store,
      loading,
      status,
      personaldaten,
      ...toRefs(state),
      errorMessage,
      krankenkasseModalRef,
      closeModal,
      showModal
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      const data: KrankenkasseEditDataRequest = {
        krankenkasse: this.krankenkasse,
        ort: this.ort,
        datum: new Date(this.datum),
        message: this.message,
        responseEmailAdresse: this.responseEmailAdresse,
        postfachartid: 1
      }


      this.store.dispatch(PersonalActionTypes.UpdateKrankenkasse, data)
        .then(() => {
          if (this.status.personalErrorMsg != "") {
            this.errorMessage = this.status.personalErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    }
  }

})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>