<template>
  <!-- Krankenkasse ändern -->
  <font-awesome-icon @click="showModal()" icon="pen-to-square" class="fa-lg pointer"/>

  <KrankenkasseEditModal ref="krankenkasseModalENRef" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import KrankenkasseEditModal from "../Modals/KrankenkasseEditModal.vue";


export default defineComponent ({
  name: "KrankenkasseEditProfil",
  components: {
    KrankenkasseEditModal
  },

  setup () {
    const krankenkasseModalENRef = ref();

    function showModal() {
      krankenkasseModalENRef.value?.showModal()
    }

    return {
      krankenkasseModalENRef,
      showModal
    }
  },

})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
</style>