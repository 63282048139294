import {
  KontaktinformationenEditDataRequest,
  LoginExtPersnrRequest,
  LoginGuidRequest,
  LoginRequest,
  NameUndAdresseEditDataRequest,
  BankverbindungEditDataRequest,
  KrankenkasseEditDataRequest,
  SendDocAsMailRequest,
  RefreshLoginRequest,
  LogoutRequest,
  MultipleDocsRequest,
} from '@/models/Personal/PersonalModels';
import { GetFolderDocsRequest } from '@/models/Archiv/ArchivModels';
import axios, { AxiosResponse } from 'axios';
import { setupInterceptorsTo } from './Interceptors';
import { SendFile, ScanAnHR, DocAnHR, NachrichtAnHR, GetFormularRequest } from '@/models/Vorgange/VorgangeModels';

/* DEV */
// import config from '../../appsettings.dev.json';

/* PROD */
const appsettings = await fetch('/appsettings.json');
const config = await appsettings.json();

const BASE_URL = config.personalapi.apiUrl;
const API_KEY = config.personalapi.apiKey;

const apiClient = setupInterceptorsTo(
  axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ApiKey: API_KEY,
    },
  })
);

export default {
  //#region Auth
  login(loginRequest: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post('/login', JSON.stringify(loginRequest));
  },

  loginAzure(idToken: String): Promise<AxiosResponse> {
    const config = {
      headers: {
        idtoken: idToken.toString(),
      },
    };

    return apiClient.get('/login/azure', config);
  },

  loginGuid(loginGuidRequest: LoginGuidRequest): Promise<AxiosResponse> {
    return apiClient.post('/login/guid', JSON.stringify(loginGuidRequest));
  },

  loginExtPersnr(loginExtPersnrRequest: LoginExtPersnrRequest): Promise<AxiosResponse> {
    return apiClient.post('/login/extpersnr', JSON.stringify(loginExtPersnrRequest));
  },

  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post('/login/refresh', data);
  },

  archivLogin(): Promise<AxiosResponse> {
    return apiClient.get('/login/archiv');
  },

  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },
  //#endregion

  //#region Personaldaten
  getMitarbeiterFolder(): Promise<AxiosResponse> {
    return apiClient.get('/folder');
  },

  getNewsEinstellung(): Promise<AxiosResponse> {
    return apiClient.get('/einstellung/news');
  },

  getPersonaldatenByGuid(): Promise<AxiosResponse> {
    return apiClient.get('/personaldaten/guid');
  },

  getArchivdatenByGuid(): Promise<AxiosResponse> {
    return apiClient.get('/personaldaten/archivdata');
  },

  putPersonaldatenKontaktinformationen(data: KontaktinformationenEditDataRequest): Promise<AxiosResponse> {
    return apiClient.put('personaldaten/kontaktinformationen', JSON.stringify(data));
  },

  putPersonaldatenNameUndAdresse(data: NameUndAdresseEditDataRequest): Promise<AxiosResponse> {
    return apiClient.put('personaldaten/name-adresse', data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },

  putPersonaldatenNameUndAdresseTerminal(data: NameUndAdresseEditDataRequest): Promise<AxiosResponse> {
    return apiClient.put('personaldaten/name-adresse/terminal', data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },

  putBankverbindung(data: BankverbindungEditDataRequest): Promise<AxiosResponse> {
    return apiClient.put('personaldaten/bankverbindung', JSON.stringify(data));
  },

  putKrankenkasse(data: KrankenkasseEditDataRequest): Promise<AxiosResponse> {
    return apiClient.put('personaldaten/krankenkasse', JSON.stringify(data));
  },

  sendNachrichtAnHR(data: NachrichtAnHR): Promise<AxiosResponse> {
    return apiClient.post('vorgang/nachricht', JSON.stringify(data));
  },

  sendNachrichtAnHRNoEmail(data: NachrichtAnHR): Promise<AxiosResponse> {
    return apiClient.post('vorgang/nachricht/noemail', JSON.stringify(data));
  },

  getDokumentartenUndDokumente(archivtoken: string): Promise<AxiosResponse> {
    return apiClient.get('/dokumente', {
      headers: {
        ArchivToken: archivtoken,
      },
    });
  },

  getProfilbildDokument(archivtoken: string): Promise<AxiosResponse> {
    return apiClient.get('/dokument/profilbild', {
      headers: {
        ArchivToken: archivtoken,
      },
    });
  },
  //#endregion

  getFolderDocs(data: GetFolderDocsRequest): Promise<AxiosResponse> {
    return apiClient.get(
      `/folder/dokumente?folderid=${data.folderid}&page=${data.page != null ? data.page : ''}&pageSize=${data.pageSize != null ? data.pageSize : ''}&search=${encodeURIComponent(data.searchString)}`,
      {
        headers: {
          ArchivToken: data.archivtoken,
        },
      }
    );
  },

  getAzureLoginConfig(): Promise<AxiosResponse> {
    return apiClient.get('/einstellung/azureconfig');
  },

  getFormular(data: GetFormularRequest): Promise<AxiosResponse> {
    return apiClient.get(`/vorgang/formular?barcode=${data.barcode}`, {
      headers: {
        ArchivToken: data.archivtoken,
      },
    });
  },

  getMultipleDocsAsZip(data: MultipleDocsRequest): Promise<AxiosResponse> {
    return apiClient.post('folder/docs/zip', JSON.stringify(data), { responseType: 'blob' });
  },

  sendDocAsEmail(data: SendDocAsMailRequest): Promise<AxiosResponse> {
    return apiClient.post('personaldaten/doc/email', JSON.stringify(data));
  },

  sendMultipleDocsAsEmail(data: MultipleDocsRequest): Promise<AxiosResponse> {
    return apiClient.post('folder/docs/email', JSON.stringify(data));
  },

  sendDocAnHR(data: DocAnHR): Promise<AxiosResponse> {
    return apiClient.post('/vorgang/docanhr', data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },

  sendScanAnHRCreate(data: ScanAnHR): Promise<AxiosResponse> {
    return apiClient.post('/vorgang/scananhr/create', data);
  },

  sendScanAnHRFinal(data: ScanAnHR): Promise<AxiosResponse> {
    return apiClient.put('/vorgang/scananhr/final', data);
  },

  sendScanAnHRCancel(vorgang: string): Promise<AxiosResponse> {
    return apiClient.delete(`/vorgang/scananhr/cancel/${vorgang}`);
  },

  sendFormular(data: SendFile): Promise<AxiosResponse> {
    return apiClient.post('/vorgang/formular', data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    });
  },
};
