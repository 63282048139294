export class Einstellung {
  einstellung1: string
  wert: string
  // daten: string

  constructor() {
    this.einstellung1 = ""
    this.wert = ""
    // this.daten = ""
  }
}

export interface ErrorResponseData {
  detail: string
  status: number
  title: string
  type: string
}