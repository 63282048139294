<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 bg-white shadow" :class="sidebarRechtsCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'">
    <!-- <h3>Newsfeed</h3>

    <hr> -->
    <!-- <button class="btn" @click="toggleSidebar()"><font-awesome-icon icon="bars" /></button> -->
    <button class="btn text-start px-0 mb-3" @click="toggleSidebarRechts()"><font-awesome-icon icon="bars" /></button>

    <button
      v-if="!sidebarRechtsCollapsed"
      class="btn btn-toggle d-inline-flex justify-content-between"
      data-bs-toggle="collapse"
      data-bs-target="#nachrichten-collapse"
      aria-expanded="true"
    >
      <span>
        <font-awesome-icon icon="circle-info" class="me-3 fa-lg" />
        <span v-if="language == 'en_GB'">Messages</span>
        <span v-else>Nachrichten</span>
      </span>
    </button>

    <div v-if="!sidebarRechtsCollapsed" class="collapse show px-3" id="nachrichten-collapse">
      <router-link to="/postfach" class="btn btn-outline-primary mb-3" v-if="posteingangUngelesen.length > 5">...zum postfach</router-link>

      <LoadingSpinner v-if="loading" />
      <div v-else>
        <SidebarNachricht v-for="nachricht in posteingangUngelesen" :key="nachricht.id" :nachricht="nachricht" />

        <router-link to="/postfach" class="btn btn-outline-primary" v-if="posteingangUngelesen.length > 0">...zum postfach</router-link>
        <p v-else>
          <span v-if="language == 'en_GB'">- no messages -</span>
          <span v-else>- keine neuen Nachrichten -</span>
        </p>
      </div>
    </div>

    <hr v-if="!sidebarRechtsCollapsed" class="mt-5 mb-3" />

    <button
      v-if="!sidebarRechtsCollapsed"
      class="btn btn-toggle d-inline-flex justify-content-between"
      data-bs-toggle="collapse"
      data-bs-target="#news-collapse"
      aria-expanded="true"
    >
      <span v-if="language == 'en_GB'"><font-awesome-icon icon="newspaper" class="me-3 fa-lg" />Notifications</span>
      <span v-else><font-awesome-icon icon="newspaper" class="me-3 fa-lg" />Mitteilungen</span>
    </button>

    <div v-if="!sidebarRechtsCollapsed" class="collapse show px-3" id="news-collapse">
      <LoadingSpinner v-if="einstellungLoading" />

      <div v-else-if="newsEinstellungErrorMsg != ''" class="alert alert-secondary my-3" role="alert">
        {{ newsEinstellungErrorMsg }}
      </div>

      <iframe v-else :src="newsEinstellung.wert" title="Newsfeed" width="100%" height="700" class="mt-2"></iframe>

      <!-- <iframe src="http://news.1a-archiv.de/" title="Newsfeed" width="100%" height="650" loading="lazy" class="mt-2"></iframe> -->

      <hr />
      <small class="text-dark text-opacity-25">Version: 1.1.244</small>
    </div>
  </div>
</template>

<script lang="ts">
  import SidebarNachricht from './Sidebar/SidebarNachricht.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { ActionTypes as CommonActionTypes } from '@/store/modules/Common/actions';
  import { ActionTypes as PersonalActionTypes } from '@/store/modules/Personal/actions';

  import { computed, defineComponent } from 'vue';
  import { useStore } from '@/store';

  export default defineComponent({
    components: {
      SidebarNachricht,
      LoadingSpinner,
    },

    setup() {
      const store = useStore();

      const language = computed(() => store.getters.language);
      const posteingangUngelesen = computed(() => store.getters.postfachPosteingang.filter((p) => p.status == 1 || p.status == 0));
      const loading = computed(() => store.getters.status.nachrichtenLoading);
      const einstellungLoading = computed(() => store.getters.status.personalEinstellungLoading);
      const newsEinstellung = computed(() => store.getters.newsEinstellung);
      const newsEinstellungErrorMsg = computed(() => store.getters.status.personalEinstellungErrorMsg);

      const sidebarRechtsCollapsed = computed(() => store.getters.status.sidebarRechtsCollapsed);

      return {
        store,
        sidebarRechtsCollapsed,
        language,
        posteingangUngelesen,
        loading,
        einstellungLoading,
        newsEinstellung,
        newsEinstellungErrorMsg,
      };
    },

    methods: {
      toggleSidebarRechts() {
        this.store.dispatch(PersonalActionTypes.ToggleSidebarRechts, undefined);
      },
    },

    mounted() {
      this.store.dispatch(CommonActionTypes.GetNewsEinstellung, undefined);
    },
  });
</script>

<style lang="scss" scoped>
  .sidebar-notcollapsed {
    width: 280px;
    transition: width 0.3s ease-in-out;
  }
  .sidebar-collapsed {
    width: 40px;
    transition: width 0.3s ease-in-out;
  }

  .btn-toggle[aria-expanded='true']::after {
    transform: rotate(-180deg);
  }

  .btn-toggle::after {
    width: 1.25em;
    // margin: auto 0 auto 5em;
    line-height: 0;
    transition: transform 0.35s ease;
    content: url(../assets/icons/chevron-down.svg);
  }

  .btn-toggle:hover {
    color: #f1a82c !important;
  }
</style>
<!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->
