<template>
    <!-- Name und Adresse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Namens- und Adressänderung mitteilen</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <NameUndAdresseEditModal ref="nameUndAdresseModalRef" :index="index" />

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import NameUndAdresseEditModal from "../Modals/NameUndAdresseEditModal.vue";

export default defineComponent ({
  name: "NameUndAdresseEditVorgang",
  components: {
    NameUndAdresseEditModal
  },

  props: {
    index: {
      type: Number,
      default: 2
    }
  },

  setup () {
    const nameUndAdresseModalRef = ref();

    function showModal() {
      nameUndAdresseModalRef.value?.showModal()
    }

    return {
      nameUndAdresseModalRef,
      showModal
    }
  },
})
</script>

<style scoped lang="scss">
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>