import { DocFile } from "@/models/Vorgange/VorgangeModels"


export type State = {
  antragForm: DocFile | null,

}

export const state: State = {
  antragForm: null,
}
