<template>
  <div class="mb-4">
    <h1 class="d-inline fs-4">Terminal Test</h1>
  </div>

  <div>
    <p><strong>Terminal Version:</strong> {{ terminalVersion }}</p>
    <p><strong>Site Kiosk:</strong> {{ siteKiosk }}</p>
    <p><strong>Vorherige Seite:</strong> {{ prevUrl }}</p>
  </div>

  <hr>

  <div class="form-check form-switch">
    <label class="form-check-label" for="flexSwitchCheckDefault">Terminal Version</label>
    <input v-model="setTerminalVersionSwitch" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" @change="setTerminalVersion()">
  </div>

  <div v-if="terminalVersion" class="my-4">
    <div class="mb-2">Dokument scannen test 1: <button class="btn btn-primary" @click="startPersonalScan()">Scannen starten</button></div>
    <div>Dokument scannen test 2: <button class="btn btn-primary" @click="startTestPersonalScan()">Scannen starten</button></div>
  </div>


</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import {v4 as uuidv4 } from 'uuid'

import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions";

export default defineComponent({
  name: "Terminal Test",

  setup () {
    const store = useStore();

    const terminalVersion = computed(() => store.getters.terminalVersion)
    const prevUrl = computed(() => store.getters.prevUrl != "" ? store.getters.prevUrl : "unbekannt")
    const personaldaten = computed(() => store.getters.personaldaten)
    const siteKiosk = window.hasOwnProperty("siteKiosk")

    const token = computed(() => store.getters.user.token)

    return {
      store,
      terminalVersion,
      prevUrl,
      personaldaten,
      siteKiosk,
      token
    }
  },

  data () {
    return {
      setTerminalVersionSwitch: false,
      guid: ""
    }
  },

  methods: {
    setTerminalVersion() {
      this.store.dispatch(CommonActionTypes.SetTerminalVersion, this.setTerminalVersionSwitch);
    },

    startPersonalScan() {
      console.log(`${this.guid} ${this.personaldaten.guid}`);
      if (window.hasOwnProperty("siteKiosk")) {
        (window as any).siteKiosk?.eventHub?.trigger('startPersonalscan', `${this.guid} ${this.personaldaten.guid}`)
      }
      else {
        console.log("SiteKiosk defined: " + window.hasOwnProperty("siteKiosk"))
      }
    },

    startTestPersonalScan() {
      console.log("param1 param2");
      if (window.hasOwnProperty("siteKiosk")) {
        (window as any).siteKiosk?.eventHub?.trigger('startPersonalscan', "param1 param2")
      }
      else {
        console.log("SiteKiosk defined: " + window.hasOwnProperty("siteKiosk"))
      }
    },
  }, 

  mounted () {
    this.setTerminalVersionSwitch = this.terminalVersion
    this.guid = uuidv4()
  }
});
</script>

<style scoped>
.unread {
  background: #f9d5201c;
  font-weight: bold;
}

#postfachAccordion {
  border-radius: 5px;
}
.accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
}
</style>