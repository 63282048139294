import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels"
import { Configuration } from "@azure/msal-browser"

export type State = {
  postfachPosteingang: Array<PostfachNachricht>,
  postfachGesendet: Array<PostfachNachricht>,
  postfachLastUpdate: Date,
  postfachUpdateInterval: number,

}

export const state: State = {
  postfachPosteingang: sessionStorage.getItem("postfachposteingang") != null ? JSON.parse(sessionStorage.postfachposteingang) : new Array<PostfachNachricht>(),
  postfachGesendet: sessionStorage.getItem("postfachgesendet") != null  ? JSON.parse(sessionStorage.postfachgesendet) : new Array<PostfachNachricht>(),
  postfachLastUpdate: new Date(1),
  postfachUpdateInterval: 3 * 60 * 1000,
}
