import { Personaldaten, Archivdata, User, Status, AzureConfigEinstellung } from "@/models/Personal/PersonalModels"
import { ArchivComponenteFile } from "@/models/Archiv/ArchivModels"
import { Configuration } from "@azure/msal-browser"

import { MutationTree } from "vuex"
import { State } from "./state"

export enum MutationTypes {
  SetStatus = "SETSTATUS",

  loginSuccess = "LOGINSUCCESS",
  RefreshLoginSuccess = "REFRESHLOGINSUCCESS",
  SetAzureUsername = "SETAZUREUSERNAME",
  SetIdentityToken = "SETAZUREIDENTITYTOKEN",
  logout = "LOGOUT",
  PersonaldatenSuccess = "PERSONALDATENSUCCESS",
  ArchivdatenSuccess = "ARCHIVDATENSUCCESS",
  GetProfilbildSuccess = "GETPROFILBILDSUCCESS",
  SetLoading = "SETLOADING",
  RequestFailure = "REQUESTFAILURE",
  ClearState = "CLEARSTATE",
  SetProfilbildLoading = "SETPROFILBILDLOADING",
  GetAzureLoginConfigSuccess = "GETAZURELOGINCONFIGSUCCESS",
  SetZipDownload = "SETZIPDOWNLOAD"
}

export type Mutations = {
  [MutationTypes.SetStatus](state: State, status: Status): void
  [MutationTypes.loginSuccess](state: State, user: User): void
  [MutationTypes.RefreshLoginSuccess](state: State, user: User): void
  [MutationTypes.SetAzureUsername](state: State, username: string): void
  [MutationTypes.SetIdentityToken](state: State, token: string): void
  [MutationTypes.logout](state: State): void
  [MutationTypes.PersonaldatenSuccess](state: State, personaldaten: Personaldaten): void
  [MutationTypes.ArchivdatenSuccess](state: State, archivdaten: Archivdata): void
  [MutationTypes.GetProfilbildSuccess](state: State, profilbild: ArchivComponenteFile): void
  [MutationTypes.RequestFailure](state: State, error: string): void
  [MutationTypes.ClearState](state: State): void
  [MutationTypes.GetAzureLoginConfigSuccess](state: State, config: AzureConfigEinstellung): void
  [MutationTypes.SetZipDownload](state: State, data: Blob): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetStatus](state, status) {
    state.status = status;
    sessionStorage.setItem("status", JSON.stringify(status))
  },

  [MutationTypes.loginSuccess](state, user) {
    state.user = user;
    // state.status.personalLoggedIn = true;

    // state.personaldatenLastUpdate = new Date(1)
    // state.postfachLastUpdate = new Date(1)
    // state.dokumentartenLastUpdate = new Date(1)
    // state.folderLastUpdate = new Date(1)
  },
  
  [MutationTypes.RefreshLoginSuccess](state, user) {
    state.user = user;
  },

  [MutationTypes.SetAzureUsername](state, username) {
    state.azureUsername = username;
  },

  [MutationTypes.logout](state) {
    state.status.errorMsg = "";
    // state.status.personalLoggedIn = false;
    state.status.personalLogoutLoading = false;
  },

  [MutationTypes.PersonaldatenSuccess](state, personaldaten) {
    state.personaldatenLastUpdate = new Date();
    state.personaldaten = personaldaten;
  },

  [MutationTypes.ArchivdatenSuccess](state, archivdaten) {
    state.archivdaten = archivdaten;
  },

  [MutationTypes.GetProfilbildSuccess](state, profilbild) {
    state.profilbild = profilbild;
  },

  [MutationTypes.RequestFailure](state, error) {
    state.status.errorMsg = error;
  },

  [MutationTypes.ClearState](state) {
    state.user = new User();
    state.personaldaten = new Personaldaten();
    state.profilbild = new ArchivComponenteFile();
    state.status = new Status();
    state.personaldatenLastUpdate = new Date(1);
  },

  [MutationTypes.SetIdentityToken](state, token) {
    state.identityToken = token;
  },

  [MutationTypes.GetAzureLoginConfigSuccess](state, config) {
    state.status.azureLoginAvailable = true
    const msalconfig: Configuration = {
        auth: {
          clientId: config.clientId,
          authority: config.authority,
          redirectUri: config.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      };

    sessionStorage.setItem("msalconfig", JSON.stringify(msalconfig))
    state.msalConfig = msalconfig;
  },

  [MutationTypes.SetZipDownload](state, data) {
    state.zipDownload = data;
  },
}