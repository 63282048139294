import { RootState } from '@/store';

import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';

import { Actions as PersonalActions, ActionTypes as PersonalActionTypes } from '../Personal/actions';

import { State } from './state';
import ApiService from '@/services/ApiService';
import ArchivApiService from '@/services/ArchivApiService';

import { DocFile, SendFile, GetAntragResponse, NachrichtAnHR, DocAnHR, ScanAnHR, GetFormularRequest } from '@/models/Vorgange/VorgangeModels';
import { ArchivDownloadCompRequest } from '@/models/Archiv/ArchivModels';

const appsettings = await fetch('/appsettings.json');
const config = await appsettings.json();
const ADOBESIGNVORGANG: boolean = config.adobeSignVorgang;

export enum ActionTypes {
  GetFormularDoc = 'GETFORMULARDOC',
  SendFormularDoc = 'SENDFORMULARDOC',
  RemoveAntragForm = 'REMOVEANTRAGFORM',
  SendNachrichtAnHR = 'SENDNACHRICHTANHR',
  SendNachrichtAnHRNoEmail = 'SENDNACHRICHTANHRNOEMAIL',
  SendDocAnHR = 'SENDDOCANHR',
  SendScanAnHRCreate = 'SENDSCANANHRCREATE',
  SendScanAnHRFinal = 'SENDSCANANHRFINAL',
  SendScanAnHRCancel = 'SENDSCANANHRCANCEL',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.GetFormularDoc](context: ActionArguments, data: GetFormularRequest): Promise<void>;
  [ActionTypes.SendFormularDoc](context: ActionArguments, data: SendFile): Promise<void>;
  [ActionTypes.RemoveAntragForm](context: ActionArguments): Promise<void>;
  [ActionTypes.SendNachrichtAnHR](context: ActionArguments, data: NachrichtAnHR): Promise<void>;
  [ActionTypes.SendNachrichtAnHRNoEmail](context: ActionArguments, data: NachrichtAnHR): Promise<void>;
  [ActionTypes.SendDocAnHR](context: ActionArguments, data: DocAnHR): Promise<void>;
  [ActionTypes.SendScanAnHRCreate](context: ActionArguments, data: ScanAnHR): Promise<void>;
  [ActionTypes.SendScanAnHRFinal](context: ActionArguments, data: ScanAnHR): Promise<void>;
  [ActionTypes.SendScanAnHRCancel](context: ActionArguments, data: string): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetFormularDoc]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);
    commit(MutationTypes.SetAntragForm, null);

    return ApiService.getFormular(data)
      .then(async (res) => {
        const antragDokument: GetAntragResponse = res.data;

        if (ADOBESIGNVORGANG && !rootGetters.terminalVersion && antragDokument.titel_1 != '') {
          status.vorgangLoading = false;
          status.vorgangErrorMsg = '';

          const antrag: DocFile = {
            filecontentAsBase64: '',
            filesize: 0,
            titel_1: antragDokument.titel_1,
            personaldocid: antragDokument.dokumentid,
          };

          commit(MutationTypes.SetAntragForm, antrag);
        } else {
          const downloadAntragRequest = new ArchivDownloadCompRequest(
            antragDokument.archivmandant,
            antragDokument.archivname,
            antragDokument.dokumente[0].docid,
            antragDokument.dokumente[0].componente.compid
          );
          return ArchivApiService.getCompFile(downloadAntragRequest)
            .then(async (resp) => {
              status.vorgangLoading = false;
              status.vorgangErrorMsg = '';
              dispatch(PersonalActionTypes.SetStatus, status);

              const base64String = await blobToBase64(resp.data);

              const antrag: DocFile = {
                filecontentAsBase64: base64String,
                filesize: resp.data.size,
                titel_1: antragDokument.titel_1,
                personaldocid: antragDokument.dokumentid,
              };
              commit(MutationTypes.SetAntragForm, antrag);
            })
            .catch((error) => {
              status.vorgangErrorMsg = error.response.data.message;
              status.vorgangLoading = false;
              dispatch(PersonalActionTypes.SetStatus, status);
            });
        }
      })
      .catch((error) => {
        status.vorgangErrorMsg = error.response.data.message;
        status.vorgangLoading = false;
        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.SendFormularDoc]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendFormular(data)
      .then(() => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
        commit(MutationTypes.RemoveAntragForm, undefined);
      })
      .catch((error) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = error.response.data.message;
        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.RemoveAntragForm]({ commit }) {
    commit(MutationTypes.RemoveAntragForm, undefined);
  },

  async [ActionTypes.SendNachrichtAnHR]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendNachrichtAnHR(data)
      .then((res) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
      })
      .catch((error) => {
        status.vorgangLoading = false;

        if (error.code == 'ERR_NETWORK') {
          status.vorgangErrorMsg = error.code + '\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.';
        } else if (error.response.status == '400') {
          let msg = error.response.data.detail + '\r\n';

          for (const err in error.response.data.errors) {
            msg += '\r\n' + err + ': ' + error.response.data.errors[err];
          }

          status.vorgangErrorMsg = msg;
        } else {
          status.vorgangErrorMsg = error.response.data.message;
          // status.vorgangErrorMsg += "\r\nBitte probieren Sie es später erneut.\r\nEs ist ein unerwarteter Fehler aufgetreten";
        }

        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.SendNachrichtAnHRNoEmail]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendNachrichtAnHRNoEmail(data)
      .then((res) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
      })
      .catch((error) => {
        status.vorgangLoading = false;

        if (error.code == 'ERR_NETWORK') {
          status.vorgangErrorMsg = error.code + '\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.';
        } else if (error.response.status == '400') {
          let msg = error.response.data.detail + '\r\n';

          for (const err in error.response.data.errors) {
            msg += '\r\n' + err + ': ' + error.response.data.errors[err];
          }

          status.vorgangErrorMsg = msg;
        } else {
          status.vorgangErrorMsg = error.response.data.message;
          // status.vorgangErrorMsg += "\r\nBitte probieren Sie es später erneut.\r\nEs ist ein unerwarteter Fehler aufgetreten";
        }

        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.SendDocAnHR]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendDocAnHR(data)
      .then((res) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
      })
      .catch((error) => {
        console.log(error);
        status.vorgangLoading = false;

        if (error.code == 'ERR_NETWORK') {
          status.vorgangErrorMsg = error.code + '\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.';
        } else if (error.response.status == '400') {
          let msg = error.response.data.detail + '\r\n';

          for (const err in error.response.data.errors) {
            msg += '\r\n' + err + ': ' + error.response.data.errors[err];
          }

          status.vorgangErrorMsg = msg;
        } else if (error.response.status == '404') {
          status.vorgangErrorMsg = error.response.data.detail;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        } else {
          status.vorgangErrorMsg = error.response.data.message;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        }

        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.SendScanAnHRCreate]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendScanAnHRCreate(data)
      .then((res) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
      })
      .catch((error) => {
        status.vorgangLoading = false;

        if (error.code == 'ERR_NETWORK') {
          status.vorgangErrorMsg = error.code + '\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.';
        } else if (error.response.status == '400') {
          let msg = error.response.data.detail + '\r\n';

          for (const err in error.response.data.errors) {
            msg += '\r\n' + err + ': ' + error.response.data.errors[err];
          }

          status.vorgangErrorMsg = msg;
        } else if (error.response.status == '404') {
          status.vorgangErrorMsg = error.response.data.detail;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        } else {
          status.vorgangErrorMsg = error.response.data.message;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        }

        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.SendScanAnHRFinal]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendScanAnHRFinal(data)
      .then((res) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
      })
      .catch((error) => {
        console.log(error);
        status.vorgangLoading = false;

        if (error.code == 'ERR_NETWORK') {
          status.vorgangErrorMsg = error.code + '\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.';
        } else if (error.response.status == '400') {
          let msg = error.response.data.detail + '\r\n';

          for (const err in error.response.data.errors) {
            msg += '\r\n' + err + ': ' + error.response.data.errors[err];
          }

          status.vorgangErrorMsg = msg;
        } else if (error.response.status == '404') {
          status.vorgangErrorMsg = error.response.data.detail;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        } else {
          status.vorgangErrorMsg = error.response.data.message;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        }

        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.SendScanAnHRCancel]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.vorgangErrorMsg = '';
    status.vorgangLoading = true;
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.sendScanAnHRCancel(data)
      .then((res) => {
        status.vorgangLoading = false;
        status.vorgangErrorMsg = '';
        dispatch(PersonalActionTypes.SetStatus, status);
      })
      .catch((error) => {
        console.log(error);
        status.vorgangLoading = false;

        if (error.code == 'ERR_NETWORK') {
          status.vorgangErrorMsg = error.code + '\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.';
        } else if (error.response.status == '400') {
          let msg = error.response.data.detail + '\r\n';

          for (const err in error.response.data.errors) {
            msg += '\r\n' + err + ': ' + error.response.data.errors[err];
          }

          status.vorgangErrorMsg = msg;
        } else if (error.response.status == '404') {
          status.vorgangErrorMsg = error.response.data.detail;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        } else {
          status.vorgangErrorMsg = error.response.data.message;
          // status.vorgangErrorMsg += "\r\nEs ist ein unerwarteter Fehler aufgetreten,\r\nbitte probieren Sie es später erneut.";
        }

        dispatch(PersonalActionTypes.SetStatus, status);
      });
  },
};

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      if (typeof result === 'string') {
        resolve(result.split(',')[1]); // Strip out the data URL part
      } else {
        reject('Failed to convert blob to base64 string');
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
