<template>
  <!-- Name und Adresse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" @click="showModal()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Bescheinigung beantragen</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <!-- Bescheinigung beantragen Modal -->
  <div
    class="modal fade"
    id="bescheinigungModal"
    tabindex="-1"
    aria-labelledby="bescheinigungModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="BescheinigungModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bescheinigungModalLabel">Bescheinigung beantragen</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeMsgModal()"></button>
        </div>

        <div class="modal-body" v-if="loading">
          <LoadingSpinner />
        </div>

        <div class="modal-body" v-if="!loading">
          <div class="mb-3">
            <label class="form-label">Bescheinigung auswählen</label>
            <select class="form-select" v-model="selectedBescheinigung">
              <option v-for="bescheinigung in bescheinigungen" :key="bescheinigung.titel" :value="bescheinigung">{{ bescheinigung.titel }}</option>
            </select>
          </div>

          <div v-if="selectedBescheinigung && selectedBescheinigung.upload">
            <div class="mb-3" v-if="!terminalVersion">
              <label for="fileupload" class="form-label">Bescheiningsvorlage auswählen</label>
              <input type="file" class="form-control" id="fileupload" accept=".pdf,.PDF" @change="handleFileUpload" />
            </div>

            <div class="mb-3" v-if="terminalVersion">
              <label class="form-label">Bescheiningsvorlage scannen</label><br />
              <button class="btn btn-primary" @click="createScanMessage()">Dokument scannen</button>
            </div>
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">Nachricht an die Personalabteilung (optional)</label>
            <textarea class="form-control" id="message" rows="4" v-model="message"></textarea>
          </div>

          <hr />

          <p>
            <strong>E-Mail Adresse für die Bestätigung der Änderungen und ggf. Rückfragen der Personalabteilung.</strong>
          </p>

          <div class="mb-3">
            <label class="form-label">E-Mail Adresse für Bestätigung</label>
            <input type="text" class="form-control" v-model="responseEmailAdresse" />
          </div>

          <div class="alert alert-danger" role="alert" v-if="errorMessage != '' && showError">
            <pre>{{ errorMessage }}</pre>
          </div>
        </div>

        <div class="modal-footer" v-if="!loading">
          <button type="button" class="btn btn-secondary" @click="closeMsgModal()">Abbrechen</button>
          <button class="btn btn-primary" @click="sendAntrag()" :disabled="!enableSendButton">Senden</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, computed, ref } from 'vue';
  import { Modal } from 'bootstrap';
  import { v4 as uuidv4 } from 'uuid';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { useStore } from '@/store';
  import { ActionTypes as VorgangActionTypes } from '@/store/modules/Vorgang/actions';
  import { ActionTypes as NachrichtenActionTypes } from '@/store/modules/Nachrichten/actions';
  import { NachrichtAnHR, DocAnHR, ScanAnHR } from '@/models/Vorgange/VorgangeModels';

  export default defineComponent({
    name: 'Bescheinigung',
    components: {
      LoadingSpinner,
    },

    setup() {
      const MAX_FILE_SIZE = 10 * 1024 * 1024;

      const store = useStore();
      const loading = computed(() => store.getters.status.vorgangLoading);
      const status = computed(() => store.getters.status);
      const terminalVersion = store.getters.terminalVersion;
      const token = computed(() => store.getters.user.token);
      const personaldaten = computed(() => store.getters.personaldaten);

      const selectedBescheinigung = ref();
      const file = ref();

      const enableSendButton = computed(() => {
        let enable = false;
        if (selectedBescheinigung.value && !selectedBescheinigung.value.upload) {
          enable = true;
        } else if (selectedBescheinigung.value && selectedBescheinigung.value.upload && (file.value || state.scanMsgCreated)) {
          enable = true;
        }

        return enable;
      });

      const window = Window;

      const state = reactive({
        betreff: '',
        message: '',
        responseEmailAdresse: personaldaten.value.email,
        postfachartid: 1,
        file: null,
        showError: false,
        guid: '',
        scanMsgCreated: false,
      });

      let errorMessage = ref('');

      const BescheinigungModalRef = ref();
      const closeModal = () => Modal.getInstance(BescheinigungModalRef.value)?.hide();
      const showModal = () => {
        if (BescheinigungModalRef.value) {
          state.guid = uuidv4();
          new Modal(BescheinigungModalRef.value).show();
        }
      };

      return {
        store,
        loading,
        status,
        terminalVersion,
        personaldaten,
        ...toRefs(state),
        errorMessage,
        BescheinigungModalRef,
        closeModal,
        showModal,
        selectedBescheinigung,
        file,
        token,
        MAX_FILE_SIZE,
        window,
        enableSendButton,
      };
    },

    data() {
      return {
        bescheinigungen: [
          { titel: 'Arbeitsbescheinigung', upload: false },
          { titel: 'Arbeitszeitbescheinigung', upload: false },
          { titel: 'Arbeitslosengeld', upload: false },
          { titel: 'Ausbildungsbescheinigung', upload: true },
          { titel: 'Einkommensbescheinigung', upload: true },
          { titel: 'Kindergeld', upload: true },
          { titel: 'Wohngeld', upload: true },
          { titel: 'Zeugengeld', upload: true },
          { titel: 'Bescheinigung über Nebeneinkommen', upload: true },
        ],
      };
    },

    methods: {
      handleFileUpload(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const file = inputElement.files![0];

        if (file) {
          this.file = file;
        }
      },

      resetFields() {
        this.selectedBescheinigung = null;
        this.file = null;
        this.betreff = '';
        this.message = '';
        this.responseEmailAdresse = this.personaldaten.email;
        this.showError = false;
        this.guid = '';
        this.scanMsgCreated = false;
        this.errorMessage = '';
      },

      closeMsgModal() {
        if (this.terminalVersion && this.scanMsgCreated) {
          this.store.dispatch(VorgangActionTypes.SendScanAnHRCancel, this.guid).then(() => {
            this.resetFields();
            this.closeModal();
          });
        } else {
          this.resetFields();
          this.closeModal();
        }
      },

      async createScanMessage() {
        this.errorMessage = '';

        const data: ScanAnHR = {
          betreff: this.betreff,
          message: this.message,
          responseEmailAdresse: this.responseEmailAdresse,
          vorgang: this.guid,
        };

        this.store.dispatch(VorgangActionTypes.SendScanAnHRCreate, data).then(() => {
          if (this.status.vorgangErrorMsg != '') {
            this.showError = true;
            this.errorMessage = this.status.vorgangErrorMsg;
          } else {
            this.scanMsgCreated = true;
            this.startPersonalScan();
          }
        });
      },

      sendAntrag() {
        if (!this.selectedBescheinigung) {
          this.errorMessage = 'Bitte wählen Sie eine Bescheinigung aus.';
          this.showError = true;
        }

        this.betreff = 'Antrag auf ' + this.selectedBescheinigung.titel + ' Bescheinigung';

        if (this.selectedBescheinigung.upload) {
          this.sendAntragBescheinigungMitAnhang();
        } else {
          this.sendAntragBescheinigung();
        }
      },

      async sendAntragBescheinigung() {
        this.errorMessage = '';
        this.showError = false;

        const data: NachrichtAnHR = {
          betreff: this.betreff,
          message: this.message,
          responseEmailAdresse: this.responseEmailAdresse,
          postfachartid: 1,
        };

        this.store.dispatch(VorgangActionTypes.SendNachrichtAnHR, data).then(() => {
          if (this.status.vorgangErrorMsg != '') {
            this.errorMessage = this.status.vorgangErrorMsg;
            this.showError = true;
            this.scanMsgCreated = false;
          } else {
            this.closeMsgModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined);
          }
        });
      },

      async sendAntragBescheinigungMitAnhang() {
        this.errorMessage = '';

        if (this.terminalVersion) {
          if (!this.scanMsgCreated) {
            this.errorMessage = 'Bitte scannen Sie zunächst die Bescheinigungsvorlage.';
            this.showError = true;
          } else {
            const data: ScanAnHR = {
              betreff: this.betreff,
              message: this.message,
              responseEmailAdresse: this.responseEmailAdresse,
              vorgang: this.guid,
            };

            this.store.dispatch(VorgangActionTypes.SendScanAnHRFinal, data).then(() => {
              if (this.status.vorgangErrorMsg != '') {
                this.showError = true;
                this.scanMsgCreated = false;
                this.errorMessage = this.status.vorgangErrorMsg;
              } else {
                this.resetFields();
                this.closeModal();
                this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined);
              }
            });
          }
        } else {
          if (this.file == null || this.file == '') {
            this.errorMessage = 'Bitte wählen Sie eine Bescheinigungsvorlage aus.';
            this.showError = true;
          } else if (this.file.size > this.MAX_FILE_SIZE) {
            this.errorMessage = 'Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 10MB.';
            this.showError = true;
          } else {
            const data: DocAnHR = {
              betreff: this.betreff,
              message: this.message,
              responseEmailAdresse: this.responseEmailAdresse,
              docFile: this.file,
              vorgang: this.guid,
            };

            this.store.dispatch(VorgangActionTypes.SendDocAnHR, data).then(() => {
              if (this.status.vorgangErrorMsg != '') {
                this.showError = true;
                this.errorMessage = this.status.vorgangErrorMsg;
              } else {
                this.resetFields();
                this.closeModal();
                this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined);
              }
            });
          }
        }
      },

      startPersonalScan() {
        if (window.hasOwnProperty('siteKiosk')) {
          (window as any).siteKiosk?.eventHub?.trigger('startPersonalscan', `${this.guid}`);
          this.scanMsgCreated = true;
        } else {
          console.log('SiteKiosk defined: ' + window.hasOwnProperty('siteKiosk'));
        }
      },
    },
  });
</script>

<style scoped lang="scss">
  .vorgang:hover {
    color: #d99728 !important;
    cursor: pointer;
  }
</style>
