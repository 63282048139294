<template>
    <div class="row mb-3 mx-1 py-1 shadow-sm postfachMessage" data-bs-toggle="modal" :data-bs-target="'#PostfachNachrichtModal-' + nachricht.id">
    <div class="col-1 my-auto">
      <font-awesome-icon icon="envelope-circle-check" class="text-start fa-lg" />
    </div>
    <div class="col-6 my-auto">
      <p class="p-0 m-0 mb-1">{{nachricht.titel}}</p>
      <p class="p-0 m-0 small text-muted fw-normal">{{nachricht.absender}}</p>
    </div>
    <div class="col-2 my-auto">
      <span v-if="nachricht.status == 0" class="text-muted  d-flex">
        <font-awesome-icon icon="envelope" class="me-2" /> <span v-if="language == 'en_GB'">open</span><span v-else>offen</span>
      </span>
      <span v-if="nachricht.status == 1" class="text-muted  d-flex">
        <font-awesome-icon icon="stopwatch" class="me-2" /> <span v-if="language == 'en_GB'">in progress</span><span v-else>in Bearbeitung</span>
      </span>
      <span v-if="nachricht.status == 2" class="text-muted  d-flex">
        <font-awesome-icon icon="check-double" class="me-2" /> <span v-if="language == 'en_GB'">done</span><span v-else>erledigt</span>
      </span>
    </div>
    <div class="col-3 my-auto">
      <p class="p-0 m-0">{{adateConverter}}</p>
    </div>
  </div>

      <!-- Nachricht Modal -->
  <div
    class="modal fade"
    :id="'PostfachNachrichtModal-' + nachricht.id"
    tabindex="-1"
    :aria-labelledby="'PostfachNachrichtModal-' + nachricht.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'PostfachNachrichtModalLabel-' + nachricht.id">
            {{nachricht.titel}}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <pre>{{nachricht.text}}</pre>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            <span v-if="language == 'en_GB'">close</span>
            <span v-else>schließen</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";

import { useStore } from '@/store'

import { PostfachNachricht } from "@/models/Nachrichten/NachrichtenModels";

export default defineComponent({
  name: "PostfachNachricht",
  
  props: {
    nachricht: {
      type: Object as PropType<PostfachNachricht>,
      default: new PostfachNachricht()
    }
  },

  setup(props) {
    const store = useStore();

    const language = computed(() => store.getters.language)

    const adateConverter = computed(() => {
      const adate = new Date(props.nachricht.adate);
      return ("0" + adate.getDate()).slice(-2) + "." + ("0" + (adate.getMonth() + 1)).slice(-2) + "." + adate.getFullYear() + " " + ("0" + (adate.getHours())).slice(-2) + ":" + ("0" + (adate.getMinutes())).slice(-2);
    })

    return {
      language,
      adateConverter
    }
  }
})
</script>

<style scoped>
.postfachMessage:hover {
  color: #d99728;
  cursor: pointer;
}

.unread {
  background: #f9d5201c;
  font-weight: bold;
}
</style>