import { createStore, createLogger } from 'vuex';
// import createPersistedState from 'vuex-persistedstate';

import { store as personal, PersonalStore, State as PersonalState} from '@/store/modules/Personal';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as archiv, ArchivStore, State as ArchivState} from '@/store/modules/Archiv';
import { store as nachrichten, NachrichtenStore, State as NachrichtenState} from '@/store/modules/Nachrichten';
import { store as vorgang, VorgangStore, State as VorgangState} from '@/store/modules/Vorgang';

export type RootState = {
  personal: PersonalState;
  common: CommonState;
  archiv: ArchivState;
  nachrichten: NachrichtenState;
  vorgang: VorgangState;
};

export type Store = PersonalStore<Pick<RootState, 'personal'>>
  & CommonStore<Pick<RootState, "common">>
  & ArchivStore<Pick<RootState, "archiv">>
  & NachrichtenStore<Pick<RootState, "nachrichten">>
  & VorgangStore<Pick<RootState, "vorgang">>;

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore({
  plugins,
  modules: {
    personal,
    common,
    archiv,
    nachrichten,
    vorgang
  },
});

export function useStore(): Store {
  return store as Store;
}