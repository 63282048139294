<template>
  <!-- Name und Adresse ändern -->
  <div class="row mb-3 mx-1 py-1 shadow-sm vorgang" data-bs-toggle="modal" data-bs-target="#teilnahmeerklaerungAltersvorsorgeEditModal" @click="getAntrag()">
    <div class="col-6 my-auto">
      <font-awesome-icon icon="id-card" class="me-3 fa-lg" />
      <span>Teilnahmeerklärung betriebliche Altersvorsorge (HPK)</span>
    </div>

    <div class="offset-4 col-2 my-auto">
      <button class="btn btn-primary">
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
  </div>

  <!-- Teilnahmeerklärung betriebliche Altersvorsorge Edit Modal -->
  <div
    class="modal fade p-0"
    id="teilnahmeerklaerungAltersvorsorgeEditModal"
    tabindex="-1"
    aria-labelledby="teilnahmeerklaerungAltersvorsorgeEditModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="teilnahmeerklaerungAltersvorsorgeEditModalRef"
  >
    <div class="modal-dialog modal-dialog-centerd modal-xl my-0 h-100">
      <div class="modal-content h-100">
        <div class="modal-header py-1">
          <h5 class="modal-title" id="teilnahmeerklaerungAltersvorsorgeEditModalLabel">Teilnahmeerklärung betriebliche Altersvorsorge (HPK)</h5>
          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>

        <div class="modal-body p-0" id="teilnahmeerklaerungAltersvorsorgeModalBody">
          <div v-if="loading">
            <LoadingSpinner />
          </div>

          <div v-if="!loading">
            <div v-if="adobeSign && adobeSignLink != ''" class="p-3">
              <p>
                Durch das klicken auf "Senden" werden Sie automatisch zu Adobe Sign weitergeleitet und die Personalabteilung benachritigt.<br />
                Bitte stellen Sie sicher, dass Sie alle erforderlichen Informationen bereit haben, bevor Sie fortfahren.<br />
                Vielen Dank!
              </p>
            </div>

            <div v-else>
              <PDFViewer
                ref="teilnahmeerklaerungAltersvorsorgePdfViewerRef"
                :docId="docId"
                :options="pdfViewerOptions"
                :persdocid="antrag?.personaldocid"
                :personaldaten="personaldaten"
              />
            </div>
          </div>
        </div>

        <div v-if="!loading" class="bg-white container border-top pt-3 pb-1">
          <div>
            <label class="form-label"><strong>E-Mail Adresse für die Bestätigung der Änderungen und ggf. Rückfragen der Personalabteilung.</strong></label>
            <input type="text" class="form-control" v-model="responseEmailAdresse" />
          </div>

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            <pre>{{ errorMessage }}</pre>
          </div>
        </div>

        <div class="modal-footer py-1 bg-white" v-if="!loading">
          <button type="button" class="btn btn-secondary" @click="closeModal()">Abbrechen</button>

          <button class="btn btn-primary" @click="saveChanges()">Senden</button>
        </div>
      </div>
    </div>
  </div>

  <ConfirmNotification ref="confirmNotificationRef" />
</template>

<script lang="ts">
  import { defineComponent, computed, ref, reactive, toRefs } from 'vue';
  import { Modal } from 'bootstrap';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import PDFViewer from '@/components/PDFViewer/PDFViewer.vue';
  import ConfirmNotification from '../Notifications/ConfirmNotification.vue';

  import { useStore } from '@/store';
  import { ActionTypes as VorgangActionTypes } from '@/store/modules/Vorgang/actions';
  import { ActionTypes as NachrichtenActionTypes } from '@/store/modules/Nachrichten/actions';

  import { SendFile, GetFormularRequest } from '@/models/Vorgange/VorgangeModels';
  import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses';

  const appsettings = await fetch('/appsettings.json');
  const config = await appsettings.json();
  const ADOBESIGNVORGANG: boolean = config.adobeSignVorgang;
  const PDFVIEWERDEFAULTSCALE = config.pdfViewerDefaultScale;

  export default defineComponent({
    name: 'TeilnahmeerklaerungAltersvorsorge',
    components: {
      LoadingSpinner,
      PDFViewer,
      ConfirmNotification,
    },

    setup() {
      const store = useStore();
      const loading = computed(() => store.getters.status.vorgangLoading);
      const status = computed(() => store.getters.status);
      const personaldaten = computed(() => store.getters.personaldaten);
      const archivUser = computed(() => store.getters.archivUser);
      const terminalVersion = computed(() => store.getters.terminalVersion);

      const gebdateConverter = computed(() => {
        if (personaldaten.value.gebdat == null) {
          return '';
        }

        const today: Date = new Date();
        const gebdate: Date = new Date(personaldaten.value.gebdat.toString());

        if (gebdate.getDate() == today.getDate() && gebdate.getMonth() == today.getMonth() && gebdate.getFullYear() == today.getFullYear()) {
          return '';
        }

        return ('0' + gebdate.getDate()).slice(-2) + '.' + ('0' + (gebdate.getMonth() + 1)).slice(-2) + '.' + gebdate.getFullYear();
      });

      const state = reactive({
        // betreff: "",
        // message: "",
        responseEmailAdresse: personaldaten.value.email,
        // postfachartid: 1,
        docloaded: false,
        adobeSign: false,
        adobeSignLink: '',
        barcode: 'MAPTeilnahmeerklaerungHPKDE',
      });

      let errorMessage = ref('');
      let antrag = computed(() => store.getters.antragForm);

      const teilnahmeerklaerungAltersvorsorgePdfViewerRef = ref();

      const teilnahmeerklaerungAltersvorsorgeEditModalRef = ref();
      const closeModal = () => {
        state.responseEmailAdresse = personaldaten.value.email;
        errorMessage.value = '';
        state.adobeSign = false;
        state.adobeSignLink = '';
        state.docloaded = false;
        teilnahmeerklaerungAltersvorsorgePdfViewerRef.value?.clearViewer();
        Modal.getInstance(teilnahmeerklaerungAltersvorsorgeEditModalRef.value)?.hide();
      };
      const showModal = () => {
        if (teilnahmeerklaerungAltersvorsorgeEditModalRef.value) {
          new Modal(teilnahmeerklaerungAltersvorsorgeEditModalRef.value).show();
        }
      };

      //#region Notification
      const confirmNotificationRef = ref();
      //#endregion

      return {
        store,
        loading,
        status,
        personaldaten,
        archivUser,
        ...toRefs(state),
        antrag,
        errorMessage,
        teilnahmeerklaerungAltersvorsorgeEditModalRef,
        closeModal,
        showModal,
        teilnahmeerklaerungAltersvorsorgePdfViewerRef,
        gebdateConverter,
        terminalVersion,

        confirmNotificationRef,
      };
    },

    data() {
      return {
        // docloaded: false,
        docId: 'teilnahmeerklaerung-hpk',
        pdfName: 'Teilnahmeerklaerung_HPK.pdf',
        pdfUrl: '',
        pdfViewerOptions: new PDFViewerOptions(),
      };
    },

    methods: {
      async fetchPdfAndCreateFileObject(pdfUrl: string, fileName: string): Promise<File | null> {
        try {
          const response = await fetch(pdfUrl);
          if (!response.ok) {
            return null;
          }
          const pdfBlob = await response.blob();
          const file = new File([pdfBlob], fileName, { type: 'application/pdf' });
          return file;
        } catch {
          return null;
        }
      },

      async saveChanges() {
        this.errorMessage = '';

        if (this.adobeSign) {
          this.startAdobeSign();
        } else {
          if (this.teilnahmeerklaerungAltersvorsorgePdfViewerRef) {
            const notSetRequiredInputs = await this.teilnahmeerklaerungAltersvorsorgePdfViewerRef?.checkRequiredAnnotationsSet();
            if (notSetRequiredInputs.length > 0) {
              let message = '';
              for (let el of notSetRequiredInputs) {
                message += el + '\r\n';
              }

              this.confirmNotificationRef?.showNotification(message, 'Bitte füllen Sie alle Pflichtfelder aus.', 'bg-danger text-white');
              return;
            }

            const blobUrl = await this.teilnahmeerklaerungAltersvorsorgePdfViewerRef?.saveOrDownloadPDF();

            const file = await this.fetchPdfAndCreateFileObject(blobUrl, this.pdfName);
            if (file != null) {
              const sendFileData: SendFile = {
                betreff: 'Teilnahmeerklärung HPK',
                message: 'Teilnahmeerklärung HPK',
                responseEmailAdresse: this.responseEmailAdresse,
                pdfFile: file,
                postfachartid: 1,
              };

              this.store
                .dispatch(VorgangActionTypes.SendFormularDoc, sendFileData)
                .then(() => {
                  if (this.status.vorgangErrorMsg != '') {
                    // this.errorMessage = this.status.commonErrorMsg;
                    this.errorMessage = 'Es ist ein Fehler beim Versenden des Antrags aufgetreten. Bitte probieren Sie es später erneut.';
                  } else {
                    this.closeModal();
                    this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined);
                  }
                })
                .catch(() => {});
            }
          } else {
            this.errorMessage = 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.';
          }
        }
      },

      async getAntrag() {
        this.errorMessage = '';
        this.docloaded = false;

        const requestData: GetFormularRequest = {
          archivtoken: this.archivUser.token,
          barcode: this.barcode,
        };

        this.store.dispatch(VorgangActionTypes.GetFormularDoc, requestData).then(() => {
          if (this.status.vorgangErrorMsg != '') {
            // this.errorMessage = this.status.vorgangErrorMsg;
            this.errorMessage = 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.';
          }

          if (this.antrag != null) {
            this.adobeSign = ADOBESIGNVORGANG && !this.terminalVersion && this.antrag.titel_1 != '';
            if (this.adobeSign) {
              this.adobeSignLink = this.replaceStringValues(this.antrag.titel_1);
            } else {
              const pdfUrl = this.base64PDFToBlobUrl(this.antrag.filecontentAsBase64);
              this.pdfName = this.personaldaten.guid + '_Teilnahmeerklaerung_HPK.pdf';

              // 50ms delay to ensure the PDFViewer is mounted
              setTimeout(() => {
                this.teilnahmeerklaerungAltersvorsorgePdfViewerRef?.showPDF(pdfUrl, this.pdfName, this.personaldaten);
              }, 50);
            }
          }
        });
      },

      base64PDFToBlobUrl(base64: string) {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        const blob = new Blob([arr], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        return url;
      },

      startAdobeSign() {
        if (this.adobeSign && this.adobeSignLink != '') {
          window.open(this.adobeSignLink, '_blank');
          this.closeModal();
        }
      },

      replaceStringValues(link: string): string {
        link = link.replace('<GUID>', this.personaldaten.guid);
        link = link.replace('<NAME>', this.personaldaten.name);
        link = link.replace('<VORNAME>', this.personaldaten.vorname);
        link = link.replace('<STRASSE>', this.personaldaten.strasse);
        link = link.replace('<PLZ>', this.personaldaten.plz);
        link = link.replace('<ORT>', this.personaldaten.ort);
        link = link.replace('<LAND>', this.personaldaten.land);
        link = link.replace('<MAN>', this.personaldaten.mandant);
        link = link.replace('<MANDANT>', this.personaldaten.mandant);
        link = link.replace('<AK>', this.personaldaten.abrkreis);
        link = link.replace('<ABRKREIS>', this.personaldaten.abrkreis);
        link = link.replace('<PNR>', this.personaldaten.persnr);
        link = link.replace('<PERSNR>', this.personaldaten.persnr);
        link = link.replace('<VERTNR>', this.personaldaten.vertnr.toString());
        link = link.replace('<GEBDATE>', this.gebdateConverter);
        link = link.replace(
          '<BARCODE>',
          `###${this.personaldaten.mandant}/${this.personaldaten.abrkreis}/${this.personaldaten.persnr}/${this.antrag?.personaldocid}`
        );
        link = link.replace('<IBAN>', this.personaldaten.iban);

        return link;
      },
    },

    mounted() {
      this.errorMessage = '';
      this.pdfViewerOptions.showDownloadOption = false;
      this.pdfViewerOptions.showPrintOption = false;
      this.pdfViewerOptions.showEmailSendenOption = false;
      this.pdfViewerOptions.showScalingOption = true;
      this.pdfViewerOptions.showPagesOption = true;
      this.pdfViewerOptions.defaultScale = PDFVIEWERDEFAULTSCALE;
    },
  });
</script>

<style scoped lang="scss">
  .vorgang:hover {
    color: #d99728 !important;
    cursor: pointer;
  }

  .modal {
    height: 100% !important;
    // overflow: hidden;
  }
</style>
