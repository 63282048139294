import { Einstellung } from "@/models/Common/CommonModels"

export type State = {
  prevUrl: string,
  terminalVersion: boolean,
  language: string,
  newsEinstellung: Einstellung
}

export const state: State = {
  prevUrl: sessionStorage.getItem("prevUrl") != null && sessionStorage.getItem("prevUrl") !== undefined ? JSON.parse(sessionStorage.prevUrl) : "",
  terminalVersion: sessionStorage.getItem("terminalVersion") != null ? JSON.parse(sessionStorage.terminalVersion) : false,
  language: localStorage.getItem("language") != null ? JSON.parse(localStorage.language) : "de_DE",
  newsEinstellung: sessionStorage.getItem("newsEinstellung") != null ? JSON.parse(sessionStorage.newsEinstellung) : new Einstellung(),
}
