import { Status } from "@/models/Personal/PersonalModels"
import { MutationTree } from "vuex"
import { State } from "./state"
import { Einstellung } from "@/models/Common/CommonModels"
import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons"

export enum MutationTypes {
  SetPrevUrl = "SETPREVURL",
  SetLanguage = "SETLANGUAGE",
  SetTerminalVersion = "SETTERMINALVERSION",
  NewsEinstellung = "NEWSEINSTELLUNG"
}

export type Mutations = {
  [MutationTypes.SetPrevUrl](state: State, prevUrl: string): void
  [MutationTypes.SetLanguage](state: State, language: string): void
  [MutationTypes.SetTerminalVersion](state: State, terminalVersion: boolean): void
  [MutationTypes.NewsEinstellung](state: State, newsEinstellung: Einstellung): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetPrevUrl](state, prevUrl) {
    state.prevUrl = prevUrl;
  },
  
  [MutationTypes.SetLanguage](state, language) {
    state.language = language;
  },

  [MutationTypes.SetTerminalVersion](state, terminalVersion) {
    state.terminalVersion = terminalVersion;
  },

  [MutationTypes.NewsEinstellung](state, newsEinstellung) {
    state.newsEinstellung = newsEinstellung;
  }
}